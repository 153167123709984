import { Formik, Form as FormikForm } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import BusyIndicator from "../../../Core/BusyIndicator";
import PageHeader from "../../../Core/PageHeader";
import AuditLogEntitySummary from "../../AuditLogs/AuditLogEntitySummary";
import { faAddressBook } from "@fortawesome/pro-regular-svg-icons";
import swal from "sweetalert"
import { ContactableEntityTypes, getNewObject, getValidation } from "../Common/ContactableEntity/ContactableEntity";
import CompanyDetails from "../Common/ContactableEntity/CompanyDetails";
import { ContactDetails as ContactableEntityDetails } from "../Common/ContactableEntity/ContactDetails";
import BankingDetails from "../Common/ContactableEntity/BankingDetails";
import OtherInformation from "../Common/ContactableEntity/OtherInformation";
import Categories from "../Common/ContactableEntity/Categories";
import AdditionalDetails from "../Common/ContactableEntity/AdditionalDetails";
import { useSessionStore } from "../../../../Stores/SessionStore";
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";

export default function ContactDetails(props) {
	const navigate = useNavigate();
	const params = useParams();
	const store = useSessionStore();

	const [contact, setContact] = useState(null);
	const [saving, setSaving] = useState(false);
	const [companyName, setCompayName] = useState('');

	UseDocumentTitle('Editing \'' + companyName + '\'')

	async function getContact() {
		let result = await store.ContactsService.GetContact(params.id)
		
		setCompayName(result.Data.CompanyName);

		if(result && result.Success) {
			setContact(result.Data);
		}
	}
	
	useEffect(() => {
		if(props.add) {
			setContact(getNewObject(ContactableEntityTypes.Contact));
		} else {
			getContact();
		}
	}, []);

	async function handleSubmitClicked(values) {
		let result = null;
		setSaving(true);

		values.ContactCategory1Id = values.ContactCategory1Id || null;
		values.ContactCategory2Id = values.ContactCategory2Id || null;
		values.ContactCategory3Id = values.ContactCategory3Id || null;
		values.ContactCategory4Id = values.ContactCategory4Id || null;

		if(props.add) {
			result = await store.ContactsService.CreateContact(values);
		} else {
			result = await store.ContactsService.UpdateContact(values);
		}
		
		setSaving(false);

		if(result && result.Success) {
			swal({ icon: "success", title: "Success", text: `Contact ${ props.add ? "created" : "updated" } successfully`, closeOnClickOutside: false});
			navigate(-1);
		} else {
			swal({ icon: "error", title: "Error", text: `An error ocurred ${ props.add ? "creating" : "updating" } the Contact. ${result.Message}`, closeOnClickOutside: false});
		}
	}
		
	const validationSchema = getValidation(ContactableEntityTypes.Contact);
	

	return 	<React.Fragment>
				<PageHeader icon={ faAddressBook } title={ "Contacts" }
							helpItemDescriptor={ ( props.add ? `Admin/Contacts/Add` : `Admin/Contacts/Edit` ) }
							crumbs={[{ Title: "Admin" }, { Title: "Contacts" }, { Active: true, Title: ( props.add ? `Adding new Contact` : `Editing Contact` )}]}>
				</PageHeader>
				{
					contact && contact.Id &&
					<AuditLogEntitySummary hideLog table="Contacts" entityid={ contact.Id } auditsummary={contact.AuditSummary}></AuditLogEntitySummary>
				}
				{
					contact &&
					<Formik initialValues={contact} enableReinitialize validateOnMount validationSchema={ validationSchema } onSubmit={ handleSubmitClicked }>
						{({ values }) => (
						<FormikForm>
							<CompanyDetails view={props.view} type={ ContactableEntityTypes.Contact } countryId={ contact.CountryId }></CompanyDetails>
							<ContactableEntityDetails title="Contact Details" field="" view={props.view} required></ContactableEntityDetails>
							<ContactableEntityDetails title="Accounts Contact Details" field="Account" view={props.view} required></ContactableEntityDetails>
							<BankingDetails view={props.view}></BankingDetails>
							<OtherInformation view={props.view}></OtherInformation>
							<Categories view={props.view} contact={contact}></Categories>
							<AdditionalDetails view={props.view}></AdditionalDetails>
							<Row>
								<Col className="d-flex justify-content-end">
									{
										!props.view &&
										<Button disabled={ saving } type="submit" className="me-1">{ `${(saving ? "Saving..." : "Save")}`}</Button>
									}
									<Button disabled={ saving } onClick={ e => navigate(`/Admin/Contacts/`) } >Cancel</Button>
								</Col>
							</Row>
						</FormikForm>)
						}
					</Formik>
				}
				<BusyIndicator show={ !contact }></BusyIndicator>
			</React.Fragment>
}