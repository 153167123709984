import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FastField, Field, useField } from "formik";
import TextValue from "../TextValue";

export default function InputField({ value, name, label, prefix, suffix, type, autocomplete, fast, required, disabled, view, labelCol, col, className, invalid }) {
	const field = useField(name);

	function getClass() {
		return `form-control${(((name && field[1].error) || invalid) ? " is-invalid" : "")}`;
	}

	function handleKeyPress(e) {
		var key = e.charCode || e.keyCode || 0; 

		if (key === 13) {
			e.preventDefault();
		}
	}

	return 	<Form.Group as={Row} className={`align-items-center ${className || ""}`}>
				{
					label && 
					<Form.Label column>{ label }</Form.Label>
				}
				{
					view &&
					<Col xs={ (!label ? 12 : labelCol ? 10 : (col || 8))}>
						<TextValue value={ name ? field[0].value : value }></TextValue>
					</Col>
				}
				{
					!view &&
					<Col xs={ (!label ? 12 : labelCol ? 10 : (col || 8))}>
						<InputGroup>
							{
								prefix &&
								<InputGroup.Text>{prefix}</InputGroup.Text>
							}
							{
								name && !fast &&
								<Field autoComplete={ autocomplete } onKeyPress={ handleKeyPress } required={ required } disabled={disabled} type={type || 'text'} className={ getClass() } name={ name }></Field>
							}
							{
								name && fast &&
								<FastField autoComplete={ autocomplete } onKeyPress={ handleKeyPress }  required={ required } disabled={disabled} type={type || 'text'} className={ getClass() } name={ name }></FastField>
							}
							{
								!name &&
								<input autoComplete={ autocomplete } onKeyPress={ handleKeyPress } value={ value } required={ required } disabled={disabled} type={type || 'text'} className={ getClass() }></input>
							}
							{
								suffix &&
								<InputGroup.Text>{suffix}</InputGroup.Text>
							}
						</InputGroup>
					</Col>
				}
			</Form.Group>
}