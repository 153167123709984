import { useEffect } from "react";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from 'react-select'
import { useSessionStore } from "../../../../../Stores/SessionStore";
import BusyIndicator from "../../../../Core/BusyIndicator";

export default function UserSelect(props) {
	const store = useSessionStore();

	const [users, setUsers] = useState(null);
	const [selectedUsers, setSelectedUsers] = useState(null);

	function handleSelectedUsersChanged(data) {
		if(data) {
			let userIds = data.map(u => u.value).join(",");

			props.updateValue(userIds);
		}
	}

	function applySelectedUsers(availableUsers) {
		//set selected users
		let selectedUsers = [];

		if(props.value) {
			let userIds = props.value.split(",");

			for(let i = 0; i < userIds.length; i++) {
				let user = availableUsers.find(u => u.value === userIds[i]);

				if(user) {
					selectedUsers.push(user);
				}
			}
		}

		setSelectedUsers(selectedUsers);
	}

	async function getUsers() {
		let result = await store.UsersService.GetUsers("", 0, 0);

		if(result && result.Success) {
			let availableUsers = result.Data.filter(u => u.Inactive === false).map(u => { return { value: u.Id, label: u.Name } });
			setUsers(availableUsers);
			applySelectedUsers(availableUsers);
		}
	}

	useEffect(() => {
		getUsers();
	}, [])

	return 	<Form.Group>
				<Row>
					<Col xs={3}>
						<Form.Label>Users</Form.Label>
					</Col>
				{
					users && selectedUsers &&
					<Col>
						<Select onChange={ handleSelectedUsersChanged } defaultValue={ selectedUsers } isMulti options={users} className="ms-4" />
					</Col>
				}
				</Row>
				<BusyIndicator show={ !users }></BusyIndicator>
			</Form.Group>
}