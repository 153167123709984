import React from "react";
import { Table } from "react-bootstrap";
import NumberValue from "../../../../../Core/NumberValue";
import { getCurrentProviderTotal, getProviderPercentage } from "../../../../../../Utilities/FinancialInstruments";

export default function LinkedInvestors({ investors, currency }) {
	return 	<React.Fragment>
				<h6>Linked Investors</h6>
				<Table bordered className="align-middle" size="sm" responsive>
					<thead>
						<tr>
							<th style={{ width: "25%" }}>Company Name</th>
							<th>Contact Type</th>
							<th style={{ width: "12%" }}>%</th>
							<th className="text-end" style={{ width: "24%" }}>Contribution</th>
							<th className="text-center" style={{ width: "15%" }}>Status</th>
						</tr>
					</thead>
					<tbody>
						{
							investors.map((value) =>
							<tr key={value.ContactId}>
								<td>{value.Name}</td>
								<td>Investor</td>
								<td><NumberValue value={getProviderPercentage(value, investors)}></NumberValue>%</td>
								<td className="text-end">
									<NumberValue currency={ currency } value={ getCurrentProviderTotal(value) }></NumberValue>
								</td>
								<td className="text-center">{ value.Active ? "Active" : "Not Active" }</td>
							</tr>)
						}
					</tbody>
				</Table>
			</React.Fragment>
}