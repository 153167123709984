import Select, { components } from "react-select";

export default function MultiSelect({ options, value, name, onChange, className, placeholder, disabled }) {
	const CustomDropdownIndicator = (props) => {
		return (
			<components.DropdownIndicator {...props}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="15"  // Adjust the width here
					height="15" // Adjust the height here
					viewBox="0 0 20 20"
				>
					<path
						d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
						fill="black"
						stroke="white"
					/>
				</svg>
			</components.DropdownIndicator>
		);
	};

	return <Select 
				isMulti 
				options={options} 
				value={value} 
				name={ name } 
				onChange={onChange} 
				className={ className } 
				placeholder={placeholder}
				components={{ DropdownIndicator: CustomDropdownIndicator }} 
				isDisabled={ disabled }
				styles={{ 
					control: (baseStyles, state) => ({ ...baseStyles, boxShadow: "none", "&:focus": { borderColor: "white", borderWidth: "1px" }  }), 
					dropdownIndicator: (baseIndicator) => ({ ...baseIndicator, paddingRight: 14 }),
	 				placeholder: (basePlaceholder) => ({ ...basePlaceholder, color: "black" }),
					menu: (base) => ({ ...base, zIndex: "100" })
				}}>
	</Select>
}