import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, useFormikContext } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import Panel from "../../../../Core/Panel";
import BankAccount from "./BankAccount";
import { isFieldEnabled } from "../../../../../Utilities/FinancialInstruments";
import { FinancialInstrumentStatuses, UserRoles } from "../../../../../Utilities/Enums";
import { Controls } from "../../../../../Utilities/Controls";
import { useSessionStore } from "../../../../../Stores/SessionStore";

export default function BankingDetails({ view, fundContacts }) {
	const formikContext = useFormikContext();
	const selectedFundManagerId = formikContext.values.FundManagerId;
	const store = useSessionStore();

	const adminWhileActive = !isFieldEnabled([UserRoles.Admin], 
												[FinancialInstrumentStatuses.Active, FinancialInstrumentStatuses.Pending], 
												formikContext.values.FundStatusId, Controls.FundOverride, store.Session);

	let bankAccounts = [];

	if(selectedFundManagerId) {
		let fundManager = fundContacts.find(c => c.Id === parseInt(selectedFundManagerId));

		if(fundManager) {
			bankAccounts = fundManager.BankAccounts;
		}
	}

	function handleDeleteClicked(index) {
		let bankAccounts = formikContext.values.BankAccounts;

		if(bankAccounts.length > 0) {
			let mustReassignDefault = bankAccounts[index].IsDefault;

			bankAccounts.splice(index, 1);

			if(mustReassignDefault && bankAccounts[0]) {
				bankAccounts[0].IsDefault = true;
			}

			formikContext.setFieldValue("BankAccounts", bankAccounts);
		}
	}

	function handleIsDefaultChecked(index, checked) {
		//make sure this is the only bank account selected as default
		let bankAccounts = formikContext.values.BankAccounts;

		if(checked === true) {
			for(let i = 0; i < bankAccounts.length; i++) {
				if(i === index) {
					bankAccounts[i].IsDefault = true;
				} else {
					bankAccounts[i].IsDefault = false;
				}
			}
		}

		formikContext.setFieldValue("BankAccounts", bankAccounts);
	}

	function handleAddBankAccountClicked(push) {
		push({ 
			AccountHolder: "", 
			Institution: "", 
			AccountNumber: "", 
			AccountType: "", 
			BranchName: "", 
			BranchCode: "", 
			IBANNumber: "", 
			SwiftCode: "", 
			IsDefault: false
		});

		setTimeout(() => {
			formikContext.validateForm();
		}, 75);
	}

	useEffect(() => {
		if(selectedFundManagerId && formikContext.values.BankAccounts.length === 0) {
			formikContext.setFieldValue("BankAccounts", [{ 
					AccountHolder: "", 
					Institution: "", 
					AccountNumber: "", 
					AccountType: "", 
					BranchName: "", 
					BranchCode: "", 
					IBANNumber: "", 
					SwiftCode: "", 
					IsDefault: false
				}
			]);
		}
	}, [selectedFundManagerId])

	return 	<Panel title="Banking Details">				
				<FieldArray name="BankAccounts" validateOnChange={false}>
					{({ push, remove }) => (
						<React.Fragment>
							<Row>
								<Col>
								{
									formikContext.values.BankAccounts &&
									formikContext.values.BankAccounts.map((bankAccount, index) => 
									<div className="border-bottom" key={ index }>
										<BankAccount
													disabled={adminWhileActive} 
													view={view} 
													onDeleteClicked={ () => handleDeleteClicked(index) } 
													bankAccount={bankAccount}
													index={ index }
													onIsDefaultChanged={ e => handleIsDefaultChecked(index, e) }
													prePopulatedBanks={ bankAccounts }>
										</BankAccount>
									</div>
									)
								}
								</Col>
							</Row>
							<Row>
								<Col className="d-flex justify-content-end mt-1 fw-bold">
									{
										!view &&
										<Button disabled={ !selectedFundManagerId || adminWhileActive } onClick={ e => handleAddBankAccountClicked(push)}>
											<FontAwesomeIcon icon={ faPlus }></FontAwesomeIcon>
										</Button>
									}
								</Col>
							</Row>
						</React.Fragment>
						)}
				</FieldArray>				
			</Panel>
}