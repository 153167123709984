import { Formik, Form as FormikForm } from "formik";
import React, { useState } from "react";
import BusyIndicator from "../../../Core/BusyIndicator";
import { useEffect } from "react";
import { useSessionStore } from "../../../../Stores/SessionStore";
import Dates from "../../Common/FinancialInstruments/Dates";
import FinancialCovenants from "../../Common/FinancialInstruments/FinancialCovenants";
import SecuritiesAndRequirements from "../../Common/FinancialInstruments/SecuritiesAndRequirements";
import Status from "../../Common/FinancialInstruments/Status";
import { checkMarginsForZeroes, getCurrentRate, isFieldEnabled, validateBaseInterestRateChanges } from "../../../../Utilities/FinancialInstruments";
import { Button, Col, Row } from "react-bootstrap";
import { FinancialInstrumentStatuses, UserRoles } from "../../../../Utilities/Enums";
import { useNavigate, useParams } from "react-router-dom";
import { faSackDollar } from "@fortawesome/pro-regular-svg-icons";
import PageHeader from "../../../Core/PageHeader";
import AuditLogEntitySummary from "../../AuditLogs/AuditLogEntitySummary";
import Documents from "../../../Core/Documents/Documents";
import { Roles, getPermissions, Permissions } from "../../../../Utilities/Permissions";
import swal from "sweetalert";
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";
import { Controls } from "../../../../Utilities/Controls";
import FundDetails from "./Components/FundDetails";
import { FundValidationSchema, getNewFund } from "../../../../Utilities/Funds";
import ExclusionCriteria from "./Components/ExclusionCriteria";
import Balances from "./Components/Balances/Balances";
import FeesAndCosts from "../../Common/FinancialInstruments/FeesAndCosts";
import SpecialInstructions from "../../Finance/Details/SpecialInstructions";
import BankingDetails from "./Components/BankingDetails";

export default function Fund({ view, add, edit }) {
	const [fundName, setFundName] = useState('');

	if(add) {
		UseDocumentTitle('Adding New Fund');
	} else {
		UseDocumentTitle('Editing \'' + fundName + '\'');
	}

	const store = useSessionStore();
	const params = useParams();
	const navigate = useNavigate();

	const [saving, setSaving] = useState(false);
	const [fund, setFund] = useState(null);
	const [fundDetails, setFundDetails] = useState(null);	
	const [currencies, setCurrencies] = useState(null);
	const [financialTerms, setFinancialTerms] = useState(null);
	const [fundContacts, setFundContacts] = useState(null);
	
	function cancelClicked() {
		return navigate(`/Funds/`);
	}

	useEffect(() => {
		async function getFund() {
			var result = await store.FundsService.GetFund(params.fundId);			

			if(result && result.Success) {
				var fund = result.Data.Fund;
				fund.View = view;

				if(fund){
					setFundName(fund.Name);
				}

				//make sure null values are reset
				fund.Factor = fund.Factor ? fund.Factor : "";				
				fund.FixedBaseRateExpiryDate = fund.FixedBaseRateExpiryDate ? fund.FixedBaseRateExpiryDate : "";
				
				setFund(fund);
				setFundDetails(result.Data);
			}
		}

		async function getCurrencies() {
			var result = await store.CurrenciesService.GetAvailableCurrencies("", 0, 0);

			if(result && result.Success) {
				setCurrencies(result.Data);
			}
		}
		
		async function getFinancialTerms() {
			var result = await store.FinancialTermsService.GetCurrentFinancialTerms();

			if(result && result.Success) {
				setFinancialTerms(result.Data);
			}
		}

		async function getFundContacts() {
			var result = await store.FundsService.GetFundContacts();

			if(result && result.Success) {
				setFundContacts(result.Data);
			}
		}

		if(add) {
			setFund(getNewFund(store.Session.PreferredCurrencyId));
			setFundDetails({});
		} else {
			getFund();
		}

		getCurrencies();
		getFinancialTerms();
		getFundContacts();
	}, []);

	function getCurrencySymbol(fund) {
		var currency = currencies.find(c => c.Id === parseInt(fund.CurrencyID));

		if(currency) {
			return currency.Symbol;
		}

		return "";
	}
	
	async function saveFund(values) {
		//set blank strings for nullable fields to null
		values.Comment = values.Comment || "";
		
		values.CostFinancePercentage = values.UseFixedBaseRate ? getCurrentRate(values.BaseInterestRates ): 0;
		values.UnderutilisationFee = values.UseFixedBaseRate ? getCurrentRate(values.BaseInterestRates) : 0;

		values.FundStatusId = values.FundStatusId === 0 ? FinancialInstrumentStatuses.Pending : values.FundStatusId;
		values.Factor = values.Factor ? values.Factor : null;
		values.FixedBaseRateExpiryDate = values.FixedBaseRateExpiryDate ? values.FixedBaseRateExpiryDate : null;
		values.CurrencySymbol = getCurrencySymbol(values);

		for(let i = 0; i < values.Securities.length; i++) {
			values.Securities[i].Field = values.Securities[i].Field || "";
			values.Securities[i].Value = values.Securities[i].Value || "";
		}

		for(let i = 0; i < values.Covenants.length; i++) {
			values.Covenants[i].Field = values.Covenants[i].Field || "";
			values.Covenants[i].Value = values.Covenants[i].Value || "";
		}

		setSaving(true);

		var result = {};
		if(values.Id) {
			result = await store.FundsService.UpdateFund(values);
		} else {
			result = await store.FundsService.CreateFund(values);
		}

		setSaving(false);

		if(result && result.Success) {
			swal({ title: "Saved", text: "You have saved your fund successfully.", icon: "success", closeOnClickOutside: false });
			navigate(`/Funds/`);
		} else {
			if(result) {
				swal({ title: "Error Saving Fund", text: result.Message, icon: "error", closeOnClickOutside: false });
			} else {
				swal({ title: "Error", text: "An error ocurred attempting to save the Fund", icon: "error", closeOnClickOutside: false });
			}
		}
	}

	async function handleFormSubmit(values) {
		if(validateBaseInterestRateChanges(fund, values) === false) {
			swal({ title: "An error occurred saving the fund", text: "If Fixed Base Rate has changed then the End Date also needs to be updated.", icon: "error", closeOnClickOutside: false })
			return;
		}

		//first make sure there is at least one investor with an active limit
		var investorsAvailable = (!values.LinkedProviders ||
									values.LinkedProviders.find(funder => funder.Active && funder.LimitHistory.find(history => history.Active)) !== undefined);

		if(!investorsAvailable) {
			swal({ title: "No Active Investors", text: "Cannot save a fund without any active investors.", icon: "error", closeOnClickOutside: false })
			return;
		}

		//make sure each investor has at least one bank account assign
		let investorsHaveBankAccounts = (values.LinkedProviders.find(p => p.Active && p.BankAccounts.find(b => b.Active)) !== undefined);

		if(!investorsHaveBankAccounts) {
			swal({ title: "Bank Accounts Required", text: "All active investors must have at least one active bank account assigned.", icon: "error", closeOnClickOutside: false })
			return;
		}

		//now check to make sure the fixed base rate is set if needed
		if(values.UseFixedBaseRate && !values.FixedBaseRateExpiryDate) {
			swal({ title: "No Fixed Base Rate Expiry", text: "If fixed base rate is used, a fixed base rate expiry date must be provided", icon: "error", closeOnClickOutside: false });
			return;
		}

		//make sure the fund has at least one bank account
		let hasBankAccount = (values.BankAccounts.find(p => p.BankAccountId && p.AccountHolder) !== undefined);

		if(!hasBankAccount) {
			swal({ title: "Bank Account Required", text: "A fund must have at least one bank account assigned.", icon: "error", closeOnClickOutside: false })
			return;
		}

		//now warn if there are any margins that equal 0, we only check margins on new facilities
		if (!values.Id) {
			var result = await checkMarginsForZeroes(values);

			if(result) {
				saveFund(values);
			}
		} else {
			saveFund(values);
		}
	}
	const permissions = getPermissions(Permissions.Sections.AllFunds, Permissions.Areas.FundManagement, Permissions.SubAreas.Funds, store.Session);
	const allowDocChanges = 
			fund && 
			(isFieldEnabled([], [FinancialInstrumentStatuses.Active, FinancialInstrumentStatuses.Pending], fund.FundStatusId, Controls.FundOverride, store.Session) || 
			isFieldEnabled([Roles.Admin], [FinancialInstrumentStatuses.Expired, FinancialInstrumentStatuses.Cancelled], fund.FundStatusId, Controls.FundOverride, store.Session));
	
	const forbidSpecialInstructionChanges = fund && (!isFieldEnabled([], [FinancialInstrumentStatuses.Active, FinancialInstrumentStatuses.Pending], fund.FundStatusId, Controls.FundOverride, store.Session) &&
											!isFieldEnabled([UserRoles.Admin],
												[FinancialInstrumentStatuses.Active, FinancialInstrumentStatuses.Expired, FinancialInstrumentStatuses.Cancelled],
												fund.FundStatusId, Controls.FundOverride, store.Session))
	
	let allowAgreements = false;

	if(!view) {
		if(add) {
			allowAgreements = isFieldEnabled([Roles.Admin, UserRoles.GroupAdmin, UserRoles.UserWithControl], 
											[FinancialInstrumentStatuses.Active], 0, Controls.FundOverride, store.Session);
		} else {								
			allowAgreements = (fund && (isFieldEnabled([Roles.Admin, UserRoles.GroupAdmin, UserRoles.UserWithControl], 
								[FinancialInstrumentStatuses.Active, FinancialInstrumentStatuses.Pending], 
								fund.FundStatusId, Controls.FundOverride, store.Session))) 
		}
	}
	
	return 	<React.Fragment>
				{
					currencies && financialTerms && fundDetails && fundContacts &&
					<React.Fragment>
						<PageHeader icon={ faSackDollar } helpItemDescriptor={ `Fund/${ fund.Id ? "Edit" : "Add"}` } title="Funds" crumbs={[{ Title: "Fund Management" }, { Title: "Funds" }, { Active: true, Title: `Editing ${fund.Name}` }]}></PageHeader>
						{
							fund.Id &&
							<AuditLogEntitySummary table="Funds" entityid={ fund.Id } auditsummary={fund.AuditSummary}></AuditLogEntitySummary>
						}

						<Formik initialValues={ fund } validateOnChange={false} enableReinitialize validateOnMount validationSchema={ FundValidationSchema } onSubmit={ handleFormSubmit }>
							{({ values }) => (
								<FormikForm>
									<FundDetails fundContacts={ fundContacts } currency={ getCurrencySymbol(values) } financialTerms={financialTerms}	currencies={ currencies }></FundDetails>
									<Dates type="Fund" override={ Controls.FacilityOverride } statusId={ fund.FundStatusId }></Dates>									
									<FeesAndCosts permissions={permissions} statusId={ fund.FundStatusId } override={ Controls.FundOverride } currency={ getCurrencySymbol(values) } values={ values } financialTerms={ financialTerms }></FeesAndCosts>
									{
										values.FundManagerId &&
										<BankingDetails fundContacts={ fundContacts } view={view}></BankingDetails>
									}
									<FinancialCovenants name="Covenants" agreements={ values.Covenants } view={!allowAgreements}></FinancialCovenants>
									<SecuritiesAndRequirements name="Securities" agreements={ values.Securities } view={!allowAgreements}></SecuritiesAndRequirements>
									<ExclusionCriteria view={ view }></ExclusionCriteria>
									<Status statusField="FundStatusId" override={ Controls.FundOverride } statusId={ fund.FundStatusId }></Status>
									{
										values.Id &&
										<Balances fundDetails={ fundDetails } currency={ getCurrencySymbol(values) }></Balances>
									}
									<SpecialInstructions disabled={forbidSpecialInstructionChanges} view={view}></SpecialInstructions>
									<Documents updateEntity={ setFund }  viewOnly={ view } allowDocChanges={ allowDocChanges } entity={ fund }></Documents>
									<Row>
										<Col className="d-flex justify-content-end">
											{
												!view &&
												<Button disabled={ saving } type="submit" className="me-1">{ `${(saving ? "Saving..." : "Save")}`}</Button>
											}
											<Button disabled={ saving } onClick={ () =>  cancelClicked()} >Cancel</Button>
										</Col>
									</Row>
								</FormikForm>)
							}
						</Formik>
					</React.Fragment>
				}
				<BusyIndicator size="2x" show={!currencies || !financialTerms || !fundDetails || !fundContacts}></BusyIndicator>
			</React.Fragment>
}