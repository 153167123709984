import {View, Text, Font} from "@react-pdf/renderer";
import { styles } from "../../StyleSheet";
import { formatCurrencyValue } from "../../../../Utilities/Currencies";
import { DateTime } from "luxon";
import { DateFormats } from "../../../../Utilities/Enums";

export default function FacilityMovementTransactions(props){

    return(
        <View style={{paddingBottom:20}}>
            <View style={[styles.tableRowWrapping, {paddingTop:1, paddingBottom:3}]} fixed>
                <Text style={{textAlign:'left', width:'25%', fontSize:7, ...styles.textEllipsis}}>Facility: <Text style={styles.boldFont}>{props.header.Name}</Text></Text>
                <Text style={{textAlign:'left', width:'25%', fontSize:7}}>Facility Limit: <Text style={styles.boldFont}>{formatCurrencyValue(props.header.Currency, props.header.Limit)}</Text></Text>
                <Text style={{textAlign:'left', width:'25%', fontSize:7}}>Advance Date: <Text style={styles.boldFont}>{DateTime.fromISO(props.header.AdvanceDate).toFormat(DateFormats.LongDate)}</Text></Text>
                <Text style={{textAlign:'left', width:'25%', fontSize:7}}>Termination Date: <Text style={styles.boldFont}>{DateTime.fromISO(props.header.TerminationDate).toFormat(DateFormats.LongDate)}</Text></Text>
            </View>
            <View style={[styles.tableRowWrapping, {paddingTop:1, paddingBottom:1}]} fixed>
                <Text style={[styles.textHeaders,{textAlign:'left', width:( props.detailed ? '8%' : '12%' ), borderTop:0.5, borderTopColor:'grey'}]}>Date</Text>
                <Text style={[styles.textHeaders,{textAlign:'left', width:'12%', borderTop:0.5, borderTopColor:'grey'}]}>Facility No.</Text>
                <Text style={[styles.textHeaders,{textAlign:'left', width:'15%', borderTop:0.5, borderTopColor:'grey'}]}>Batch Reference</Text>
                <Text style={[styles.textHeaders,{textAlign:'left', width:( props.detailed ? '6%' : '' ), paddingLeft:5, borderTop:0.5, borderTopColor:'grey'}]}>{props.detailed ? "Type" : ""}</Text>
                
                <Text style={[styles.textHeaders,{textAlign:'left', width:'12%', paddingLeft:5, borderTop:0.5, borderTopColor:'grey'}]}>{props.detailed ? "Document No." : ""} </Text>
                <Text style={[styles.textHeaders,{textAlign:'left', width:( props.detailed ? '11%' : '13%' ), borderTop:0.5, borderTopColor:'grey'}]}>{props.detailed ? "Contact Name" : ""}</Text>

                <Text style={[styles.textHeaders,{textAlign:'right', width:'12%', borderTop:0.5, borderTopColor:'grey'}]}>Debit</Text>
                <Text style={[styles.textHeaders,{textAlign:'right', width:'12%', borderTop:0.5, borderTopColor:'grey'}]}>Credit</Text>
                <Text style={[styles.textHeaders,{textAlign:'right', width:'12%', borderTop:0.5, borderTopColor:'grey'}]}>Balance</Text>
            </View>
            <View style={styles.tableRowWrapping}>
                <Text style={[styles.textData,{textAlign:'left', width:( props.detailed ? '8%' : '12%' )}]}>{DateTime.fromISO(props.header.StartDate).toFormat(DateFormats.ShortDate)}</Text>
                <Text style={[styles.textData,{textAlign:'left', width:'12%', ...styles.textEllipsis}]}></Text>
                <Text style={[styles.textData,{textAlign:'left', width:'15%', ...styles.textEllipsis}]}>Opening Balance</Text>
                <Text style={[styles.textData,{textAlign:'left', width:( props.detailed ? '6%' : '' ), paddingLeft:5}]}></Text>
                <Text style={[styles.textData,{textAlign:'left', width:'12%', ...styles.textEllipsis}]}></Text>
                <Text style={[styles.textData,{textAlign:'left', width:( props.detailed ? '11%' : '13%' ), ...styles.textEllipsis}]}></Text>
                <Text style={[styles.textData,{textAlign:'right', width:'12%'}]}></Text>
                <Text style={[styles.textData,{textAlign:'right', width:'12%'}]}></Text>
                <Text style={[styles.textData,{textAlign:'right', width:'12%'}]}>{formatCurrencyValue(props.header.Currency,props.header.OpeningBalance)}</Text>
            </View>
            {
                props.transactions.map(value =>
                    <View style={styles.tableRowWrapping} wrap={false}>
                        <Text style={[styles.textData,{textAlign:'left', width:( props.detailed ? '8%' : '12%' )}]}>{DateTime.fromISO(value.DateTime).toFormat(DateFormats.ShortDate)}</Text>
                        <Text style={[styles.textData,{textAlign:'left', width:'12%', ...styles.textEllipsis}]}>{value.Number}</Text>
                        <Text style={[styles.textData,{textAlign:'left', width:'15%', ...styles.textEllipsis}]}>{value.BatchReference}</Text>
                        <Text style={[styles.textData,{textAlign:'left', width:( props.detailed ? '6%' : '' ), paddingLeft:5, ...styles.textEllipsis}]}>{props.detailed ? value.Type : ""}</Text>
                        <Text style={[styles.textData,{textAlign:'left', width:'12%', paddingLeft:5, ...styles.textEllipsis}]}>{value.DocumentNumber}</Text>
                        <Text style={[styles.textData,{textAlign:'left', width:( props.detailed ? '11%' : '13%' ), ...styles.textEllipsis}]}>{value.ContactName}</Text>
                        <Text style={[styles.textData,{textAlign:'right', width:'12%'}]}>{ value.Debit === 0 || value.Debit ? formatCurrencyValue(value.Currency, value.Debit) : ""}</Text>
                        <Text style={[styles.textData,{textAlign:'right', width:'12%'}]}>{ value.Credit === 0 || value.Credit ? formatCurrencyValue(value.Currency, value.Credit) : ""}</Text>
                        <Text style={[styles.textData,{textAlign:'right', width:'12%'}]}>{formatCurrencyValue(value.Currency,value.Balance)}</Text>
                    </View>
                )
            }
        </View>
    )
}