import {View, Text} from '@react-pdf/renderer';
import { formatCurrencyValue } from '../../../../Utilities/Currencies';
import {styles} from '../../StyleSheet';

export default function FacilityLimits(props){
    return(
        <View style={{paddingBottom:10}}>
            <Text style={[styles.boldFont, {fontSize:7, padding: 3}]}>Facility Limits</Text>
            <View style={styles.tableRowWrapping}>
                <Text style={[styles.facilityHeaders, {width: '25%', textAlign: 'left', borderRight:1, borderLeft:1, borderColor:'#e3e3e3'}]}>Number</Text>
                <Text style={[styles.facilityHeaders, {width: '19%', textAlign: 'left', borderRight:1, borderColor:'#e3e3e3'}]}>Name</Text>
                <Text style={[styles.facilityHeaders, {width: '14%', textAlign: 'center', borderRight:1, borderColor:'#e3e3e3'}]}>Limit</Text>
                <Text style={[styles.facilityHeaders, {width: '14%', textAlign: 'center', borderRight:1, borderColor:'#e3e3e3'}]}>Running Bal.</Text>
                <Text style={[styles.facilityHeaders, {width: '14%', textAlign: 'center', borderRight:1, borderColor:'#e3e3e3'}]}>Actual Bal.</Text>
                <Text style={[styles.facilityHeaders, {width: '14%', textAlign: 'center', borderRight:1, borderColor:'#e3e3e3'}]}>Available Bal.</Text>
            </View>
            <View style={styles.tableRowWrapping}>
                <Text style={[styles.facilityData, {width: '25%', textAlign: 'left', borderRight:1, borderLeft:1, borderBottom:1, borderColor:'#e3e3e3'}, styles.textEllipsis]}>{ props.data.Facility.FacilityNumber }</Text>
                <Text style={[styles.facilityData, {width: '19%', textAlign: 'left', borderRight:1, borderBottom:1, borderColor:'#e3e3e3'}, styles.textEllipsis]}>{ props.data.Facility.Name }</Text>
                <Text style={[styles.facilityData, {width: '14%', textAlign: 'center', borderRight:1, borderBottom:1, borderColor:'#e3e3e3'}]}>
					{ formatCurrencyValue(props.data.Facility.CurrencySymbol, props.data.FacilityBalances.Limit) }
				</Text>
                <Text style={[styles.facilityData, {width: '14%', textAlign: 'center', borderRight:1, borderBottom:1, borderColor:'#e3e3e3', fontWeight: props.data.FacilityBalances.Balances.RunningTotal > props.data.FacilityBalances.Limit ? "bold" : "normal", color: props.data.FacilityBalances.Balances.RunningTotal > props.data.FacilityBalances.Limit ? "red" : "black" }]}>
					{ formatCurrencyValue(props.data.Facility.CurrencySymbol, props.data.FacilityBalances.Balances.RunningTotal)}
				</Text>
                <Text style={[styles.facilityData, {width: '14%', textAlign: 'center', borderRight:1, borderBottom:1, borderColor:'#e3e3e3'}]}>
					{ formatCurrencyValue(props.data.Facility.CurrencySymbol, props.data.FacilityBalances.Balances.ActualTotal)}
				</Text>
                <Text style={[styles.facilityData, {width: '14%', textAlign: 'center', borderRight:1, borderBottom:1, borderColor:'#e3e3e3'}]}>
					{ formatCurrencyValue(props.data.Facility.CurrencySymbol, props.data.FacilityBalances.AvailableBalance)}
				</Text>
            </View>
        </View>
    )
}