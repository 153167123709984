import React from "react";
import { Route } from "react-router-dom";
import { useSessionStore } from "../../../Stores/SessionStore";
import { Roles } from "../../../Utilities/Permissions";
import ContactCategoriesIndex from "./Contacts/ContactCategories/ContactCategoriesIndex";
import ContactCategoryDetails from "./Contacts/ContactCategories/ContactCategoryDetails";
import ContactDetails from "./Contacts/ContactDetails";
import ContactsIndex from "./Contacts/ContactsIndex";
import ContactTypeDetails from "./Contacts/ContactTypes/ContactTypeDetails";
import ContactTypesIndex from "./Contacts/ContactTypes/ContactTypesIndex";
import CurrenciesIndex from "./Settings/Currencies/CurrenciesIndex";
import FinancialTerms from "./Settings/FinancialTerms/FinancialTerms";
import GroupSettings from "./Settings/GroupSettings";
import MarketPositionSettings from "./Settings/MarketPositionSettings";
import SystemSettings from "./Settings/SystemSettings";
import GroupDetails from "./Structure/GroupDetails";
import GroupsIndex from "./Structure/GroupsIndex";
import MarketPositionDetails from "./Structure/MarketPositionDetails";
import MarketPositionsIndex from "./Structure/MarketPositionsIndex";
import StoreDetails from "./Structure/StoreDetails";
import StoresIndex from "./Structure/StoresIndex";
import UserDetails from "./Users/Users/UserDetails";
import UsersIndex from "./Users/Users/UsersIndex";
import UserTypeDetails from "./Users/UserTypes/UserTypeDetails";
import UserTypesIndex from "./Users/UserTypes/UserTypesIndex";
import CountriesIndex from "./Contacts/Countries/CountriesIndex";
import CountryDetails from "./Contacts/Countries/CountryDetails";

export default function AdminRoutes() {
	const store = useSessionStore();

	return 	<React.Fragment>
				{
					store.Session.Role === Roles.Admin &&
					<React.Fragment>
						<Route path="/Admin/UserTypes" element={ <UserTypesIndex></UserTypesIndex> }></Route>
						<Route path="/Admin/UserTypes/Add" element={ <UserTypeDetails add></UserTypeDetails> }></Route>
						<Route path="/Admin/UserTypes/Edit/:userTypeId" element={ <UserTypeDetails></UserTypeDetails> }></Route>
						<Route path="/Admin/UserTypes/View/:userTypeId" element={ <UserTypeDetails view></UserTypeDetails> }></Route>

						<Route path="/Admin/Users" element={ <UsersIndex></UsersIndex> }></Route>
						<Route path="/Admin/Users/Add" element={ <UserDetails add></UserDetails> }></Route>
						<Route path="/Admin/Users/Edit/:userId" element={ <UserDetails></UserDetails> }></Route>
						<Route path="/Admin/Users/View/:userId" element={ <UserDetails view></UserDetails> }></Route>

						<Route path="/Admin/Groups" element={ <GroupsIndex></GroupsIndex> }></Route>
						<Route path="/Admin/Groups/Add" element={ <GroupDetails add></GroupDetails> }></Route>
						<Route path="/Admin/Groups/Edit/:id" element={ <GroupDetails></GroupDetails> }></Route>
						<Route path="/Admin/Groups/View/:id" element={ <GroupDetails view></GroupDetails> }></Route>

						<Route path="/Admin/Groups/MarketPositions/:parentId" element={ <MarketPositionsIndex></MarketPositionsIndex> }></Route>
						<Route path="/Admin/Groups/MarketPositions/Add/:id/:parentid" element={ <MarketPositionDetails add></MarketPositionDetails> }></Route>
						<Route path="/Admin/Groups/MarketPositions/Edit/:id/:parentid" element={ <MarketPositionDetails></MarketPositionDetails> }></Route>
						<Route path="/Admin/Groups/MarketPositions/View/:id/:parentid" element={ <MarketPositionDetails view></MarketPositionDetails> }></Route>

						<Route path="/Admin/Groups/Stores/:parentId/:grandparentId" element={ <StoresIndex></StoresIndex> }></Route>
						<Route path="/Admin/Groups/Stores/Add/:id/:parentid/:grandparentId"  element={ <StoreDetails add></StoreDetails> }></Route>
						<Route path="/Admin/Groups/Stores/Edit/:id/:parentid/:grandparentId"  element={ <StoreDetails></StoreDetails> }></Route>
						<Route path="/Admin/Groups/Stores/View/:id/:parentid/:grandparentId"  element={ <StoreDetails view></StoreDetails> }></Route>

						<Route path="/Admin/ContactTypes" element={ <ContactTypesIndex></ContactTypesIndex> }></Route>
						<Route path="/Admin/ContactTypes/Add/" element={ <ContactTypeDetails add></ContactTypeDetails> }></Route>
						<Route path="/Admin/ContactTypes/Edit/:id" element={ <ContactTypeDetails></ContactTypeDetails> }></Route>
						<Route path="/Admin/ContactTypes/View/:id" element={ <ContactTypeDetails view></ContactTypeDetails> }></Route>

						<Route path="/Admin/Countries" element={ <CountriesIndex></CountriesIndex> }></Route>
						<Route path="/Admin/Countries/Add/" element={ <CountryDetails add></CountryDetails> }></Route>
						<Route path="/Admin/Countries/Edit/:id" element={ <CountryDetails></CountryDetails> }></Route>
						<Route path="/Admin/Countries/View/:id" element={ <CountryDetails view></CountryDetails> }></Route>

						<Route path="/Admin/ContactCategories" element={ <ContactCategoriesIndex></ContactCategoriesIndex> }></Route>
						<Route path="/Admin/ContactCategories/Add/" element={ <ContactCategoryDetails add></ContactCategoryDetails> }></Route>
						<Route path="/Admin/ContactCategories/Edit/:id" element={ <ContactCategoryDetails></ContactCategoryDetails> }></Route>
						<Route path="/Admin/ContactCategories/View/:id" element={ <ContactCategoryDetails view></ContactCategoryDetails> }></Route>

						<Route path="/Admin/Contacts" element={ <ContactsIndex></ContactsIndex> }></Route>
						<Route path="/Admin/Contacts/Add" element={ <ContactDetails add></ContactDetails> }></Route>
						<Route path="/Admin/Contacts/Edit/:id" element={ <ContactDetails></ContactDetails> }></Route>
						<Route path="/Admin/Contacts/View/:id" element={ <ContactDetails view></ContactDetails> }></Route>

						<Route path="/Admin/Settings/System" element={ <SystemSettings></SystemSettings> }></Route>
						<Route path="/Admin/Settings/Group" element={ <GroupSettings ></GroupSettings> }></Route>
						<Route path="/Admin/Settings/MarketPosition" element={ <MarketPositionSettings add></MarketPositionSettings> }></Route>

						<Route path="/Admin/Settings/System/FinancialTerms" element={ <FinancialTerms></FinancialTerms> }></Route>
						<Route path="/Admin/Settings/System/Currencies" element={ <CurrenciesIndex></CurrenciesIndex> }></Route>
					</React.Fragment>
				}
			</React.Fragment>
}