import { Col, Form, FormGroup, InputGroup, Row } from "react-bootstrap";
import NumberInput from "../../../../Core/Forms/NumberInput";
import NumberValue from "../../../../Core/NumberValue";

export default function RateValue(props) {
	if(props.label) {
		return 	<FormGroup as={Row} className="align-items-center">
					<Form.Label column >{props.label}</Form.Label>
					<Col xs={4}>
						{
							!props.view &&
							<InputGroup>
								<NumberInput style={{ cursor: "not-allowed" }} disabled value={ props.value }></NumberInput>
								<InputGroup.Text>%</InputGroup.Text>
							</InputGroup>
						}
						{
							props.view &&
							<div style={{ color: "#004878", cursor: "not-allowed" }} className="align-items-center">
								<NumberValue value={ props.value }></NumberValue>
								<span>%</span>
							</div>
						}
					</Col>
				</FormGroup>
	} else {
		if(props.view) {
			return 	<div style={{ color: "#004878", cursor: "not-allowed" }} className="align-items-center">
						<NumberValue value={ props.value }></NumberValue>
						<span>%</span>
					</div>
		} else {
			return 	<InputGroup>
					<NumberInput style={{ cursor: "not-allowed" }} disabled value={ props.value }></NumberInput>
					<InputGroup.Text>%</InputGroup.Text>
				</InputGroup>
		}
	}
}