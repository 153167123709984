import * as yup from 'yup';
import { GetRandomKey } from "./React";

export const FundValidationSchema =
	yup.object({
		CurrencyID: yup.mixed().required().not([ 0, "0" ]),
		Number: yup.string().required(),
		Name: yup.string().required(),
		FundTypeId: yup.mixed().required().not([ 0, "0" ]),
		FinancialTermId: yup.mixed().required().not([ 0, "0" ]),
		FinancialInstrumentConditionsID: yup.string().required().not([ 0, "0" ]),
		FinancialInstrumentTermID: yup.mixed().required().not([ 0, "0" ]),
		FinancialInstrumentTermConditionsID: yup.mixed().required().not([ 0, "0" ]),
		InterestCalculation: yup.string().required(),
		ProjectedFundingDays: yup.mixed().required(),
		ExternalAccountReference: yup.string().required(),
		AssetDescriptionId: yup.mixed().required(),
		FundAllocation: yup.mixed().required(),
		AdvanceDate: yup.string().required(),
		TerminationDate: yup.string().required(),
		FacilityFee: yup.mixed().required(),
		RenewalFee: yup.mixed().required(),
		ServiceFee: yup.mixed().required(),
		DocumentFee: yup.mixed().required(),
		StructureFee: yup.mixed().required(),
		OtherFees: yup.mixed().required(),
		TradingFee: yup.mixed().required(),
		PlatformFee: yup.mixed().required(),
		IssuingCost: yup.mixed().required(),
		LegalCost: yup.mixed().required(),
		CollateralManagementCost: yup.mixed().required(),
		InsuranceCost: yup.mixed().required(),
		OtherCosts: yup.mixed().required(),
		TradingDiscountSplitNumerator:  yup.mixed().required(),
		TradingDiscountSplitDenominator: yup.mixed().required(),
		FundManagerId:  yup.mixed().required(), 
		AdministratorId: yup.mixed().required(),
		FixedBaseRateExpiryDate: yup.mixed().test({ test: (value, ctx) => !ctx.parent.UseFixedBaseRate || (ctx.parent.UseFixedBaseRate && value !== undefined) }),
		YearDays: yup.mixed().required(),
		Securities: yup.array().test({ test: (securities) => 	securities.length > 0 &&
																securities[0].Field && securities[0].Field.length > 0 &&
																securities[0].Value && securities[0].Value.length > 0 }),
		Covenants: yup.array().test({ test: (covenants) => 		covenants.length > 0 &&
																covenants[0].Field && covenants[0].Field.length > 0 &&
																covenants[0].Value && covenants[0].Value.length > 0 })
}).required();

export function getNewFund(preferredCurrencyId) {
	return {
		FundStatusId: 0, Comment: "", CurrencyID: preferredCurrencyId, Number: "", Name: "", InterestCalculation: "",
        FinancialTermId: 0, FinancialInstrumentConditionsID: 0, FinancialInstrumentTermID: 0, FinancialInstrumentTermConditionsID: 0,
		ProjectedFundingDays: "", AdvanceDate: "", TerminationDate: "", CostFinancePercentage: "", FacilityFee: "", RenewalFee: "",
		UnderutilisationFee: 0, ServiceFee: "", DocumentFee: "", IssuingCost: "", LegalCost: "", CollateralManagementCost: "",
		InsuranceCost: "", FinancialPercentage: "", StructureFee: "", OtherCosts: "", Factor: "", OtherFees: "", TradingFee: "",
		TradingDiscountSplitNumerator: "", TradingDiscountSplitDenominator: "", PlatformFee: "", LinkedTrading: false, YearDays: "",
		OffTakerContactId: "", UseFixedBaseRate: false, FixedBaseRateExpiryDate: "", AutoFinanceOption: false,
		FundTypeId: 0, FundFeesFrequencyId: "", ExternalAccountReference: "", AssetDescriptionId: "", FundAllocation: "",
		IntercreditorRequired: false, EquityRequired: false, FundManagerId: "", AdministratorId: "", SpecialInstructions: "",
		Securities: [ { Field: "", Value: "", Key: GetRandomKey()}, { Field: "", Value: "", Key: GetRandomKey() } ],
		Covenants: [ { Field: "", Value: "", Key: GetRandomKey() }, { Field: "", Value: "", Key: GetRandomKey() } ],
		FinancialInstrumentFeeFrequencies: {
			FacilityFeeFrequency: 0, StructuringFeeFrequency: 0, RenewalFeeFrequency: 0, InsuranceCostFrequency: 0, ServiceFeeFrequency: 0,
			LegalCostFrequency: 0, PlatformFeeFrequency: 0, IssuingCostFrequency: 0, CollateralManagementCostFrequency: 0, TradingFeeFrequency: 0,
			DocumentFeeFrequency: 0, OtherFeeFrequency: 0, OtherCostsFrequency: 0
		},
		LinkedProviders: [], BaseInterestRates: [], CostofFinanceFundersMargins: [], CostofFinanceTradingMargins: [], UnderutilisationFundersMargins: [],
		BankAccounts: [], 
		UnderutilisationTradingMargins: [], Limit: 0, CurrencySymbol:"",
		ExclusionCriteria: [
			{ Field: "Countries", Value: "" }, 
			{ Field: "Contact Category 1", Value: "" }, 
			{ Field: "Contact Category 2", Value: "" }, 
			{ Field: "Contact Category 3", Value: "" }, 
			{ Field: "Contact Category 4", Value: "" }],
	}
}