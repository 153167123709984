import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FieldArray } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { useSessionStore } from "../../../../../../Stores/SessionStore";
import { getAvailableProviders, getCurrentProviderTotal, getProviderPercentage } from "../../../../../../Utilities/FinancialInstruments";
import { getPermissions, Permissions } from "../../../../../../Utilities/Permissions";
import { GetRandomKey } from "../../../../../../Utilities/React";
import BusyIndicator from "../../../../../Core/BusyIndicator";
import CheckboxField from "../../../../../Core/Forms/CheckboxField";
import SelectField from "../../../../../Core/Forms/SelectField";
import RoundButton from "../../../../../Core/RoundButton";
import NumberValue from "../../../../../Core/NumberValue";
import FinancialInstrumentProviderField from "../../../../Common/FinancialInstruments/FinancialInstrumentProviderField";
import ClickableText from "../../../../../Core/ClickableText";

export default function FundInvestorsModal({ show, linkedInvestors, disabled, currency, onClose, onProviderContributionsClicked, onProviderBankAccountsClicked }) {
	const [investors, setInvestors] = useState(null);
	const [selectedInvestor, setSelectedInvestor] = useState(null);

	const store = useSessionStore();

	useEffect(() => {
		async function loadAvailableInvestors() {
			let results = await store.ContactsService.GetInvestorContacts();

			if(results && results.Success) {
				setInvestors(results.Data);
			}
		}

		if(show) {
			loadAvailableInvestors();
		}
	}, [show]);
	
	function handleSelectedInvestorChanged(investorId) {
		if(investorId) {
			var investor = investors.find((f) => f.Id === parseInt(investorId));

			if(investor) {
				setSelectedInvestor(investor);
				return;
			}
		}

		setSelectedInvestor(null);
	}

	function handleAddSelectedInvestorClicked(pushMethod) {
		if(selectedInvestor !== null) {
			pushMethod({
				ContactId: selectedInvestor.Id,
				Name: selectedInvestor.Name,
				Amount: 0,
				Balance: 0,
				Linked: false,
				Active: false,
				LimitHistory: [],
				BankAccounts: []
			});

			var investorIndex = investors.findIndex((f) => f.Id === selectedInvestor.Id);

			investors.splice(investorIndex, 1)
			setInvestors(investors);
			setSelectedInvestor(null);
		}
	}

	function handleDeleteInvestorClicked(index, investorId, investorName, removeMethod) {
		investors.push({ Id: investorId, Name: investorName });
		setInvestors(investors);
		removeMethod(index);
	}
	
	const permissions = getPermissions(Permissions.Sections.AllFunds, Permissions.Areas.FundManagement, Permissions.SubAreas.Funds, store.Session);

	return 	<Modal show={show} dialogClassName="modal-900">
				<Modal.Header>
					<h4>
						Link Investor
					</h4>
				</Modal.Header>
				<Modal.Body>
					{
						investors !== null &&
						<FieldArray name="LinkedProviders" validateOnChange={false}>
											{({ push, remove }) => (
						<React.Fragment>
							{
								permissions.Add &&
								<React.Fragment>
									<Row>
										<Col>
											<SelectField label="Company Name" onChange={ e => handleSelectedInvestorChanged(e.target.value) } disabled={ disabled }>
												<option value=""></option>
												{
													getAvailableProviders(investors, linkedInvestors).map((investor) => {
														return <option key={investor.Id} value={investor.Id}>{investor.Name}</option>
													})
												}
											</SelectField>
										</Col>
									</Row>
									<Row>
										<Col className="d-flex justify-content-end my-50">
											<Button onClick={ e => handleAddSelectedInvestorClicked(push) } disabled={ disabled }>Add</Button>
										</Col>
									</Row>
								</React.Fragment>
							}
							<b className="d-block mb-75 mt-1">Linked Investors</b>
							<Row>
								<Col>
									<Table bordered size="sm" className="align-middle" responsive>
										<thead>
											<tr>
												<th className="text-nowrap">Company Name</th>
												<th className="text-nowrap">Contact Type</th>
												<th>%</th>
												<th style={{ maxWidth: "220px", width: "220px" }}>Amount</th>
												<th className="text-end text-nowrap">Linked Bank Acc.</th>
												{
													(permissions.Edit || permissions.View) &&
													<th>Status</th>
												}
												{
													(permissions.Delete || permissions.View) &&
													<th className="text-center">Delete</th>
												}
											</tr>
										</thead>
											<tbody>
												{ linkedInvestors && linkedInvestors.map((investor, index) => (
												<tr key={ GetRandomKey() }>
													<td>{investor.Name}</td>
													<td>Investor</td>
													<td><NumberValue value={getProviderPercentage(investor, linkedInvestors)}></NumberValue>%</td>
													<td>
														<FinancialInstrumentProviderField limit={ getCurrentProviderTotal(investor) } onClick={ e => onProviderContributionsClicked(index) } col={12} currency={currency}></FinancialInstrumentProviderField>
													</td>
													<td className="text-end"><ClickableText onClick={ e => onProviderBankAccountsClicked(index) }>Linked Bank Acc.</ClickableText></td>
													{
														(permissions.Edit || permissions.View) &&
														<td>
															<CheckboxField col={1} swap label="Active" name={ `LinkedProviders[${index}].Active` } disabled={ disabled } active></CheckboxField>
														</td>
													}
													{
														(permissions.Delete || permissions.View) && investor.Linked && <td className="text-center">Linked</td>
													}
													{
														(permissions.Delete || permissions.View) && !investor.Linked &&
														<td className="text-center">
															<RoundButton onClick={ disabled? "" : e => handleDeleteInvestorClicked(index, investor.ContactId, investor.Name, remove) } icon={ faTimes }></RoundButton>
														</td>
													}
												</tr>))}
											</tbody>
									</Table>
								</Col>
							</Row>
						</React.Fragment>
						)}
						</FieldArray>
					}
					<BusyIndicator show={investors === null} size="2x"></BusyIndicator>
				</Modal.Body>
				<Modal.Footer>
					<Button disabled={ investors === null } onClick={ onClose }>Close</Button>
				</Modal.Footer>
			</Modal>
}