import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row, Table } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import { DateFormats } from "../../../Utilities/Enums";
import { getPermissions, Permissions } from "../../../Utilities/Permissions";
import { GetRandomKey, removeModalTabIndex } from "../../../Utilities/React";
import DateInput from "../../Core/Forms/DateInput";
import DateValue from "../../Core/DateValue";
import RoundButton from "../../Core/RoundButton";
import NumberInput from "../../Core/Forms/NumberInput";
import NumberValue from "../../Core/NumberValue";
import BusyIndicator from "../../Core/BusyIndicator";
import swal from "sweetalert";

export default function FacilityBatchPaymentDatesModal(props) {
	const store = useSessionStore();
	const permissions = getPermissions(Permissions.Sections.AllFacilities, Permissions.Areas.CreditManagement, Permissions.SubAreas.Facilities, store.Session);

	const [batchDates, setBatchDates] = useState(null);

	const [currentBatchDate, setCurrentBatchDate] = useState("");
	const [currentBatchLimit, setCurrentBatchLimit] = useState("");
	const [faciltyName, setFacilityName] = useState("");
	const [updatingBatchDates, setUpdatingBatchDates] = useState(false);

	useEffect(() => {
		async function getFacilityBatchDates() {
			setBatchDates(null);
			var results = await store.FacilitiesService.GetFacilityBatchDates(props.facility.Id);

			if(results && results.Success) {
				setBatchDates(results.Data);
			}
		}

		if(props.show) {
			setCurrentBatchDate("");
			setCurrentBatchLimit("");
			getFacilityBatchDates();

			removeModalTabIndex();
			setFacilityName(props.facility.Name)
		}
	}, [props.show]);

	function handleAddBatchDateClicked() {
		if(currentBatchDate) {
			var newBatchDate = {
				Limit: currentBatchLimit,
				Active: false,
				InUse: false,
				BatchDate: currentBatchDate,
			}

			batchDates.push(newBatchDate);
			setBatchDates([...batchDates]);
			setCurrentBatchDate("");
			setCurrentBatchLimit("");
		}
	}

	function handleBatchDateDeleteClicked(index) {
		batchDates.splice(index, 1);
		setBatchDates([...batchDates]);
	}

	function handleBatchDateActiveChecked(batchDate, active) {
		batchDate.Active = active;
		setBatchDates([...batchDates]);
	}

	async function handleSaveClicked() {
		setUpdatingBatchDates(true);
		let result = await store.FacilitiesService.UpdateFacilityBatchDates(props.facility.Id, batchDates);
		setUpdatingBatchDates(false);

		if(result.Success) {
			store.FacilitiesStore.GetFacilities();
			swal({ icon: "success", title: "Batch Dates Saved", text: "Facility batch payment dates saved successfully.", closeOnClickOutside: false });
		} else {
			swal({ icon: "error", title: "Error", text: "An error occurred saving the Facility batch dates.", closeOnClickOutside: false })
		}
	}

	return 	<Modal size="lg" show={ props.show }>
				<Modal.Header>
					<h4>
						Batch Payment Dates for {faciltyName}
					</h4>
				</Modal.Header>
				<Modal.Body>
					{
						batchDates && props.facility &&
						<React.Fragment>
							{
								permissions.Add &&
								<Form className="mb-50">
									<Form.Group as={Row} className="d-flex align-items-center mb-50">
										<Form.Label xs={4} column>Batch Payment Date</Form.Label>
										<Col>
											<DateInput minDate={ DateTime.now().toISODate() } date={currentBatchDate} onChange={ e => setCurrentBatchDate(e) }></DateInput>
										</Col>
									</Form.Group>
									<Form.Group as={Row} className="d-flex align-items-center mb-50">
										<Form.Label xs={4} column>Limit</Form.Label>
										<Col>
											<InputGroup>
												<InputGroup.Text>{props.facility.Currency}</InputGroup.Text>
												<NumberInput onChange={ e => setCurrentBatchLimit(e ? e : null) } value={ currentBatchLimit } ></NumberInput>
											</InputGroup>
										</Col>
									</Form.Group>
									<Row className="mt-50">
										<Col className="d-flex justify-content-end">
											<Button onClick={handleAddBatchDateClicked}>Add</Button>
										</Col>
									</Row>
								</Form>
							}
							<h6>Current Batch Payment Dates</h6>
							<Table bordered className="align-middle" size="sm" responsive>
								<thead>
									<tr>
										<th>Date</th>
										<th className="text-end">Limit</th>
										{
											(permissions.Edit || permissions.View) &&
											<th className="text-center">Status</th>
										}
										{
											(permissions.Delete || permissions.View) &&
											<th className="text-center">Delete</th>
										}
									</tr>
								</thead>
								<tbody>
									{
										batchDates.map((value, index) =>
										<tr key={GetRandomKey()}>
											<td><DateValue inputFormat={ DateFormats.FullLongDate } outputFormat={ DateFormats.FullLongDate } date={value.BatchDate}></DateValue></td>
											<td className="text-end">
												{
													value.Limit &&
													<NumberValue currency={props.facility.Currency} value={value.Limit}></NumberValue>
												}
												{
													!value.Limit &&
													<span>No Limit</span>
												}
											</td>
											{
												(permissions.Edit || permissions.View) &&
												<td className="text-center">
													<span>
														<Form.Check onChange={ e => handleBatchDateActiveChecked(value, e.target.checked) } checked={ value.Active } inline disabled={!permissions.Edit}/> Active
													</span>
												</td>
											}
											{
												(permissions.Delete || permissions.View) &&
												<td className="text-center">
													{
														value.InUse &&
														<span>Active</span>
													}
													{
														value.InUse === false &&
														<RoundButton onClick={ permissions.Edit ? e => handleBatchDateDeleteClicked(index) : null } icon={ faTimes }></RoundButton>
													}
												</td>
											}
										</tr>)
									}
								</tbody>
							</Table>
						</React.Fragment>
					}
					<BusyIndicator size="2x" show={(!batchDates || !props.facility)}></BusyIndicator>
				</Modal.Body>
				<Modal.Footer>
					{
						permissions.Edit &&
						<Button disabled={ batchDates === null || updatingBatchDates } onClick={ handleSaveClicked }>Save</Button>
					}
					<Button disabled={ batchDates === null || updatingBatchDates } onClick={ props.onClose }>Close</Button>
				</Modal.Footer>
			</Modal>
}