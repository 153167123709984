import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import { FieldArray } from "formik"
import React from "react"
import { useState } from "react"
import { Button, Col, Modal, Row, Table } from "react-bootstrap"
import { useSessionStore } from "../../../../../../Stores/SessionStore"
import { getPermissions, Permissions } from "../../../../../../Utilities/Permissions"
import { GetRandomKey } from "../../../../../../Utilities/React"
import CheckboxField from "../../../../../Core/Forms/CheckboxField"
import RoundButton from "../../../../../Core/RoundButton"
import { useEffect } from "react"
import SelectField from "../../../../../Core/Forms/SelectField"

export default function FundInvestorBankAccountsModal({ investor, investorIndex, show, disabled, onClose }) {
	const [bankAccountId, setBankAccountId] = useState("");
	const [bankAccounts, setBankAccounts] = useState(null);

	const store = useSessionStore();

	function handleAddBankAccountClicked(pushMethod) {
		if(bankAccountId) {
			pushMethod({ BankAccountId: parseInt(bankAccountId), Active: false });
			setBankAccountId("");
		}
	}
	
	useEffect(() => {
		async function loadInvestorBankAccounts() {
			let results = await store.ContactsService.GetBankAccountsForContacts([ investor.ContactId ]);

			if(results && results.Success && results.Data[0]) {
				setBankAccounts(results.Data[0].BankAccounts);
			} else {
				setBankAccounts([]);
			}
		}

		if(show) {
			loadInvestorBankAccounts();
			setBankAccountId(null);
		}
	}, [show]);

	function getAvailableBankAccounts() {
		let availableBankAccounts = [];

		for(let i = 0; i < bankAccounts.length; i++) {
			let investorBankAccount = investor.BankAccounts.find(b => b.BankAccountId === bankAccounts[i].BankAccountId);

			if(!investorBankAccount) {
				availableBankAccounts.push(bankAccounts[i]);
			}
		}

		return availableBankAccounts;
	}

	function getBankAccounts() {
		let accounts = [];

		for(let i = 0; i < investor.BankAccounts.length; i++) {
			let bankAccount = bankAccounts.find(b => b.BankAccountId === investor.BankAccounts[i].BankAccountId);

			if(bankAccount) {
				accounts.push(bankAccount);
			}
		}

		return accounts;
	}

	const permissions = getPermissions(Permissions.Sections.AllFunds, Permissions.Areas.FundManagement, Permissions.SubAreas.Funds, store.Session);

	return <Modal show={show} size="lg">
				<Modal.Header>
					<h4>
						Linked Bank Accounts - {investor && investor.Name}
					</h4>
				</Modal.Header>
				<Modal.Body>
					{
						investor && investor.BankAccounts && bankAccounts &&
						<FieldArray name={`LinkedProviders[${investorIndex}].BankAccounts`} validateOnChange={false}>
						{({ push, remove }) => (
						<React.Fragment>
							{
								permissions.Add &&
								<React.Fragment>
									<Row>
										<Col>
										<SelectField value={ bankAccountId } label="Account Number" onChange={ e => setBankAccountId(e.target.value) } disabled={ disabled }>
												<option value="">-- SELECT --</option>
												{
													getAvailableBankAccounts().map((bank) => {
														return <option key={bank.BankAccountId} value={bank.BankAccountId}>{`${bank.BankInstitution} - ${bank.BankAccountNumber}`}</option>
													})
												}
											</SelectField>
										</Col>
									</Row>
									<Row>
										<Col className="d-flex justify-content-end mt-1 mb-2">
											<Button onClick={ e => handleAddBankAccountClicked(push) } disabled={ disabled }>Add</Button>
										</Col>
									</Row>
								</React.Fragment>
							}
							<Row>
								<Col>
									<Table bordered className="align-middle" size="sm" responsive>
										<thead>
											<tr>
												<th>Bank</th>
												<th>Account Number</th>												
												{
													(permissions.Edit || permissions.View) &&
													<th>Status</th>
												}
												{
													(permissions.Delete || permissions.View) &&
													<th className="text-center">Delete</th>
												}
											</tr>
										</thead>
										<tbody>
											{ getBankAccounts().map((bank, index) => (
											<tr key={ GetRandomKey() }>
												<td>{bank.BankInstitution}</td>
												<td>{bank.BankAccountNumber}</td>
												{
													(permissions.Edit || permissions.View) &&
													<td>
														<CheckboxField col={1} swap label="Active" name={ `LinkedProviders[${investorIndex}].BankAccounts[${index}].Active` } disabled={ disabled } active></CheckboxField>
													</td>
												}
												{
													(permissions.Delete || permissions.View) &&
													<td className="text-center">
														{
															investor.Linked &&
															<span>Linked</span>
														}
														{
															!investor.Linked &&
															<RoundButton onClick={ disabled ? "" : e => remove(index) } icon={ faTimes }></RoundButton>
														}
													</td>
												}												
											</tr>))}
										</tbody>
									</Table>
								</Col>
							</Row>
						</React.Fragment>
						)}
						</FieldArray>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={ onClose }>Close</Button>
				</Modal.Footer>
			</Modal>
}