import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import ClickableText from "../../Core/ClickableText";

export default function FundAssignmentsModal(props){

    
    return <Modal size="lg" show={ props.show }>
        <Modal.Header>
            <h4>Assigned Facilities for { props.currentFund.Name }</h4>
        </Modal.Header>
        <Modal.Body>
            <h6>Assigned Facilities</h6>
            <Table bordered className="align-middle" size="sm" responsive>
                <thead>
                    <tr>
                        <th>Number</th>
                        <th>Name</th>
                        <th className="text-end">Limit</th>
                        <th className="text-end">Running Bal.</th>
                        <th className="text-end">Actual Bal.</th>
                        <th className="text-center">Status</th>
                    </tr>
                </thead>
            </Table>
        </Modal.Body>
        <Modal.Footer>
        <Button onClick={ props.onClose }>Close</Button>
        </Modal.Footer>
    </Modal>
}