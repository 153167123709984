import APIBase from "./APIBase";

/**
 * @typedef Contact
 * @property {number} Id
 * @property {string} Name
 */

/**
 * @typedef ContactsService
 * @type {ContactsService}
 */
export default class ContactsService extends APIBase {
	/**
	 * @param {string} apiUrl
	 * @param { import("../Stores/SessionStore").SessionStore } sessionStore
	 **/
	constructor(apiUrl, sessionStore) {
		super(apiUrl + "Contacts", sessionStore);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<Contact> }
	 */
	async GetAssignableContactsForFacility(facilityId, facilityTypeId) {
		return this.Get(`GetAssignableContactsForFacility?facilityId=${facilityId}&facilityTypeId=${facilityTypeId}`);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<any> }
	 */
	async GetBankAccountsForContacts(contactIds) {
		return this.Post(`GetBankAccountsForContacts`, contactIds);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<Contact> }
	 */
	async GetFunderContacts() {
		return this.Get(`GetFunderContacts`);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<Contact> }
	 */
	async GetInvestorContacts() {
		return this.Get(`GetInvestorContacts`);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<Contact> }
	 */
	async GetOffTakerContacts() {
		return this.Get(`GetOffTakerContacts`);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<import("./FacilitiesService").FacilityContact> }
	 */
	 async GetAssignedContactsForFacility(facilityId) {
		return this.Get(`GetAssignedContactsForFacility?facilityId=${facilityId}`);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<any> }
	 */
	 async GetContactCategories() {
		return this.Get(`GetContactCategories`);
	}

	async GetContacts(search, pageIndex, pageCount) {
		return this.Get(`GetContacts?search=${encodeURIComponent(search)}&pageIndex=${pageIndex}&pageCount=${pageCount}`);
	}

	async ExportContacts(search) {
		return this.Get(`ExportContacts?search=${encodeURIComponent(search)}`);
	}
	
	async CreateContact(contact) {
		return this.Post(`CreateContact`, contact);
	}

	async UpdateContact(contact) {
		return this.Post("UpdateContact", contact);
	}

	async DeleteContact(contactId) {
		return this.Post(`DeleteContact?contactId=${contactId}`);
	}

	/**
	 * @param {number} contactId The Id of the Contact to retrieve
	 * @returns { Promise<import("./APIBase").RequestResult<any>> }
	 */
	 async GetContact(contactId) {
		return this.Get(`GetContact?contactId=${contactId}`);
	}
}