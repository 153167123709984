import { useFormikContext } from "formik";
import { Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";
import { FinancialInstrumentStatuses, UserRoles } from "../../../../Utilities/Enums";
import { isFieldEnabled } from "../../../../Utilities/FinancialInstruments";
import InputField from "../../../Core/Forms/InputField";
import SelectField from "../../../Core/Forms/SelectField";
import Panel from "../../../Core/Panel";

export default function Status({ statusField, statusId, override }) {
	const store = useSessionStore();
	const formikContext = useFormikContext();
	const view = formikContext.values.View;

	const disabled = !isFieldEnabled([], [FinancialInstrumentStatuses.Active, FinancialInstrumentStatuses.Pending], statusId, override, store.Session) &&
						!isFieldEnabled([UserRoles.Admin],
							[FinancialInstrumentStatuses.Active, FinancialInstrumentStatuses.Expired, FinancialInstrumentStatuses.Cancelled],
							statusId, override, store.Session)


	function getAvailableStatuses() {
		const canMarkActive = formikContext.values.CanMarkActive;
		var statuses = [
			{ Id: FinancialInstrumentStatuses.Pending, Name: "Pending" },
			{ Id: FinancialInstrumentStatuses.Active, Name: "Active" },
			{ Id: FinancialInstrumentStatuses.Cancelled, Name: "Cancelled" },
			{ Id: FinancialInstrumentStatuses.Expired, Name: "Expired" }
		];

		if(!view) {
			if (statusId === FinancialInstrumentStatuses.Pending && canMarkActive) {
				statuses = statuses.filter(x => x.Id !== FinancialInstrumentStatuses.Pending);
			}
	
			if (statusId >= FinancialInstrumentStatuses.Active && canMarkActive) {
				statuses = statuses.filter(x => x.Id !== FinancialInstrumentStatuses.Pending);
			} else if (formikContext.values.Id === 0 || !canMarkActive) {
				statuses = statuses.filter(x => x.Id !== FinancialInstrumentStatuses.Active);
			}
	
			if (statusId !== FinancialInstrumentStatuses.Expired) {
				statuses = statuses.filter(x => x.Id !== FinancialInstrumentStatuses.Expired);
			}
	
			if (statusId === FinancialInstrumentStatuses.Expired) {
				statuses = statuses.filter(x => x.Id !== FinancialInstrumentStatuses.Active);
			}
		}

		return statuses;
	}

	return 	<Panel>
				<Row>
					<Col xs={4}>
						<SelectField view={view} disabled={disabled} fast label="Status" name={ statusField }>
							{
								getAvailableStatuses().map((status) =>
								<option key={ status.Id } value={status.Id}>{status.Name}</option>
								)
							}
						</SelectField>
					</Col>
					<Col xs={8}>
						<InputField view={view} disabled={disabled} label="Comment" name="Comment" labelCol></InputField>
					</Col>
				</Row>
			</Panel>
}