import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import FacilitiesService from "../Services/FacilitiesService";
import ContactsService from "../Services/ContactsService";
import StructureService from "../Services/StructureService";
import UsersService from "../Services/UsersService";
import CurrenciesService from "../Services/CurrenciesService";
import FinancialTermsService from "../Services/FinancialTermsService";
import AuditsService from "../Services/AuditsService";
import DocumentsService from "../Services/DocumentsService";
import AppHelpService from "../Services/AppHelpService";
import UnderutilisationService from "../Services/UnderutilisationService"
import TransactionDocumentsService from "../Services/TransactionDocumentsService"
import TransactionsService from "../Services/TransactionsService"
import PaymentInstructionsService from "../Services/PaymentInstructionsService"
import PaymentManagementStore from "./PaymentManagementStore";
import ReimbursementsService from "../Services/ReimbursementsService";
import PaymentInstructionRecoveriesService from "../Services/PaymentInstructionRecoveriesService"
import SystemAppsService from "../Services/SystemAppsService"
import ContactTypesService from "../Services/ContactTypesService"
import ContactCategoriesService from "../Services/ContactCategoriesService"
import CountryService from "../Services/CountryService";
import SettingsService from "../Services/SettingsService"
import MessagesService from "../Services/MessagesService"
import ApprovalsService from "../Services/ApprovalsService"
import ReportsService from "../Services/ReportsService"
import FundsService from "../Services/FundsService"
import AuditStore from "../Stores/AuditStore"
import FacilitiesStore from "./FacilitiesStore";
import FinanceStore from "./FinanceStore";
import { FacilityTypes } from "../Utilities/Enums";

/** @type {SessionStore} */
var SessionStoreInstance = null;

const SessionStoreContext = createContext(null);

export default class SessionStore {
	HasNotifications = false;
	HideSidebar = false;

	constructor(config) {
		/** @type {import("../Services/UsersService").Session } */
		this.Session = this.LoadPreviousSession();
		this.Config = config;

		//services
		this.UsersService = new UsersService(config.apiUrl, this);
		this.FacilitiesService = new FacilitiesService(config.apiUrl, this);
		this.ContactsService = new ContactsService(config.apiUrl, this);
		this.StructureService = new StructureService(config.apiUrl, this);
		this.CurrenciesService = new CurrenciesService(config.apiUrl, this);
		this.FinancialTermsService = new FinancialTermsService(config.apiUrl, this);
		this.AuditsService = new AuditsService(config.apiUrl, this);
		this.DocumentsService = new DocumentsService(config.apiUrl, this);
		this.AppHelpService = new AppHelpService(config.apiUrl, this);
		this.UnderutilisationService = new UnderutilisationService(config.apiUrl, this);
		this.TransactionDocumentsService = new TransactionDocumentsService(config.apiUrl, this);
		this.TransactionsService = new TransactionsService(config.apiUrl, this);
		this.PaymentInstructionsService = new PaymentInstructionsService(config.apiUrl, this);
		this.ReimbursementsService = new ReimbursementsService(config.apiUrl, this);
		this.PaymentInstructionRecoveriesService = new PaymentInstructionRecoveriesService(config.apiUrl, this);
		this.SystemAppsService = new SystemAppsService(config.apiUrl, this);
		this.ContactTypesService = new ContactTypesService(config.apiUrl, this);
		this.ContactCategoriesService = new ContactCategoriesService(config.apiUrl, this);
		this.CountryService = new CountryService(config.apiUrl, this);
		this.SettingsService = new SettingsService(config.apiUrl, this);
		this.MessagesService = new MessagesService(config.apiUrl, this);
		this.ApprovalsService = new ApprovalsService(config.apiUrl, this);
		this.ReportsService = new ReportsService(config.apiUrl, this);
		this.FundsService = new FundsService(config.apiUrl, this);

		//stores
		this.FacilitiesStore = null;
		this.TradeFinanceStore = new FinanceStore(FacilityTypes.TradeFinance, this.TransactionDocumentsService, this.TransactionsService);
		this.DebtorFinanceStore = new FinanceStore(FacilityTypes.InvoiceDiscounting, this.TransactionDocumentsService, this.TransactionsService);
		this.PaymentManagementStore = new PaymentManagementStore(this.PaymentInstructionsService, this.ReimbursementsService);
		this.AuditStore = new AuditStore(this.AuditsService)

		makeAutoObservable(this, { FacilitiesService: false });
	}

	GetFinanceStore(facilityType) {
		if(facilityType === FacilityTypes.InvoiceDiscounting) {
			return this.DebtorFinanceStore
		} else {
			return this.TradeFinanceStore
		}
	}

	LoadPreviousSession() {
		var sessionData = localStorage.getItem("Session");

		if(sessionData) {
			return JSON.parse(sessionData);
		}

		return null;
	}

	ShowSidebar(show) {
		if(this.HideSidebar !== !show) {
			this.HideSidebar = !show;
		}
	}

	SetSession(session, remember) {
		this.Session = session;
		
		if(remember) {
			localStorage.setItem("Session", JSON.stringify(session));
		}
	}

	SetProfilePic(base64, fileType) {
		this.Session.ProfilePictureBase64 = base64
		this.Session.ProfilePictureMIMEType = fileType;

		localStorage.setItem("Session", JSON.stringify(this.Session));
	}

	SetProfileName(name, surname) {
		this.Session.Name = name
		this.Session.Surname = surname;
		this.Session.FullName = name + " " +surname;
		localStorage.setItem("Session", JSON.stringify(this.Session));
	}

	Logout() {
		this.FacilitiesStore.ClearStoredFilters();
		this.PaymentManagementStore.ClearStoredFilters();
		this.DebtorFinanceStore.ClearStoredFilters();
		this.TradeFinanceStore.ClearStoredFilters();
		this.AuditStore.ClearStoredFilters();
		
		localStorage.clear();
		sessionStorage.clear();

		this.HasNotifications = false;
		this.Session = null;
	}

	/**
	 * Downloads and sets the latest app structure for the user
	 */
	async UpdateUserAppStructure() {
		var result = await this.StructureService.GetAvailableStructure();

		if(result && result.Success) {
			runInAction(() => {
				this.Session.AuthState.UserAppStructure = result.Data;
			});
		}
	}

	/**
	 * Loads the available Permissions, Controls and Groups, Market Positions and Stores for the logged in user
	 */
	async LoadAuthState() {
		var result = await this.UsersService.GetSystemAppAuthState();
		
		if(result && result.Success) {
			runInAction(() => {
				this.Session.AuthState = result.Data;
				
				this.FacilitiesStore = new FacilitiesStore(this.FacilitiesService, this.Session.AuthState.UserAppStructure.AvailableStructure);
				
				this.GetHasNotifications();
			});
		} else {
			if(this.FacilitiesStore !== null){
				this.FacilitiesStore.ClearStoredFilters();
			}

			if(this.PaymentManagementStore){
				this.PaymentManagementStore.ClearStoredFilters();
			}

			if(this.DebtorFinanceStore){
				this.DebtorFinanceStore.ClearStoredFilters();
			}

			if(this.TradeFinanceStore){
				this.TradeFinanceStore.ClearStoredFilters();
			}

			if(this.AuditStore){
				this.AuditStore.ClearStoredFilters();
			}

			localStorage.clear();
			sessionStorage.clear();
			this.Session = null;
		}
	}

	async GetHasNotifications() {
		let result = await this.MessagesService.GetTotalUnreadMessages();

		if(result && result.Success) {
			runInAction(() => {
				this.HasNotifications = result.Data > 0;
			});

			setTimeout(() => {
				this.GetHasNotifications();
			}, 60000);
		} else {
			if(result && result.Success === false) {
				setTimeout(() => {
					this.GetHasNotifications();
				}, 120000);
			}
		}
	}
}

export function SessionStoreProvider(props) {
    if(SessionStoreInstance === null) {
        SessionStoreInstance = new SessionStore(props.config);
    }

    return <SessionStoreContext.Provider value={ SessionStoreInstance }>{ props.children }</SessionStoreContext.Provider>
}

/**
 * @returns {SessionStore}
 */
export function useSessionStore() {
    const context = useContext(SessionStoreContext);

    return context;
}