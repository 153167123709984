import currency from "currency.js";

export function getCurrencySymbol(currencyId, currencies) {
	let currencySymbol = "R";

	if(currencies) {
		let currency = currencies.find(c => c.Id === parseInt(currencyId));

		if(currency) {
			return currency.Symbol;
		}
	}

	return currencySymbol;
}

export function roundUp(num, scale) {
	//dont roudn if we've already rounded
	let numComponents = (num + "").split(".");

	//if the number is an integer or a decimal with less places than requested, just return the number
	if(numComponents.length < 2 || numComponents[1].length <= scale) {
		return num;
	} else if (numComponents.length >=  2 && numComponents[1].length > scale +1) {
	
	} else {
		num = Math.round((num + Number.EPSILON) * 100) / 100;
	}

	return parseFloat(num.toFixed(scale));
}

export function formatCurrencyValue(currencySymbl, value) {
	return currency(value, { separator: ' ', symbol: `${currencySymbl} ` }).format();
}