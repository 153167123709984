import React from "react";
import Panel from "../../../../../Core/Panel";
import LinkedInvestors from "./LinkedInvestors";
import LinkedFacilities from "./LinkedFacilities";
import FundBalances from "./FundBalances";

export default function Balances({ fundDetails, currency }) {
	return 	<Panel>
				{
					fundDetails &&
					<React.Fragment>
						<div className="mb-1">
							<LinkedInvestors currency={ currency } investors={ fundDetails.Fund.LinkedProviders }></LinkedInvestors>
						</div>
						<div className="mb-1">
							<LinkedFacilities facilities={ fundDetails.LinkedFacilities  }></LinkedFacilities>
						</div>
						<FundBalances currency={ currency } fund={ fundDetails.Fund } fundBalances={ fundDetails.FundBalances }></FundBalances>
					</React.Fragment>
				}
			</Panel>
}