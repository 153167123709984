import { useEffect, useRef } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FastField, Field, useField, useFormikContext } from "formik";
import NumberFormat from "react-number-format";
import TextValue from "../TextValue";

export default function PhoneNumberField(props) {
	const formik = useFormikContext();
	const [field] = useField(props.name);
	const inputElement = useRef(null);

	function handleChange(event) {
		var value = event.formattedValue;
		
		if(props.onChange) {
			if(!props.onChange(value)) {
				formik.setFieldValue(props.name, value, true);
			}
		} else {
			formik.setFieldValue(props.name, value, true);
		}		
	}

	useEffect(() => {
		if (formik.isSubmitting ) {
			if (formik.errors[props.name]) {
				inputElement?.current?.children[0].setCustomValidity(formik.errors[props.name]);
				inputElement?.current?.children[0].reportValidity();
			} else {
				inputElement?.current?.children[0].setCustomValidity("");
				inputElement?.current?.children[0].reportValidity();
			}
		}
	}, [formik.isSubmitting ]);

	useEffect(() => {
		if (formik.isValidating && !formik.isSubmitting) {
			inputElement?.current?.children[0].setCustomValidity("");
			inputElement?.current?.children[0].reportValidity();
		}
	}, [formik.isValidating]);

	function getClass() {
		return `form-control ${((formik.errors[props.name] !== undefined || props.invalid) ? "is-invalid" : "")}`;
	}

	function getCustomInput() {
		if(props.fast) {
			return FastField;
		} else {
			return Field;
		}
	}

	function handleKeyPress(e) {
		var key = e.charCode || e.keyCode || 0; 

		if (key === 13) {
			e.preventDefault();
		}
	}

	return 	<Form.Group as={Row} className={`align-items-center ${props.className || ""}`}>
				<Form.Label column>{ props.label }</Form.Label>
				{
					props.view &&
					<Col xs={ (!props.label ? 12 : props.labelCol ? 10 : (props.col || 8)) } style={{ cursor: "not-allowed", color: "#004878" }}>						
						<TextValue value={ `${field.value || ""}` }></TextValue>						
					</Col>
				}
				{
					!props.view &&
					<Col xs={ (!props.label ? 12 : props.labelCol ? 10 : (props.col || 8))}>
						<InputGroup ref={inputElement}>
							{
								props.name &&
								<NumberFormat onKeyPress={ handleKeyPress }  allowEmptyFormatting={ true } format="+## (###) ###-####" mask="_" customInput={ getCustomInput() } onValueChange={ handleChange } value={ field.value } name={ props.name } required={ props.required }
								className={ getClass() } disabled={ props.disabled }></NumberFormat>
							}
						</InputGroup>
					</Col>
				}
			</Form.Group>
}