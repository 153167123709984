import { View, Text, Font } from "@react-pdf/renderer";
import {styles} from '../../StyleSheet';
import { formatCurrencyValue } from "../../../../Utilities/Currencies";
import { DateTime } from "luxon";
import { DateFormats } from "../../../../Utilities/Enums";


export default function PayablesDueCustomer(props){

    // const checkforZAR = props.data.Totals.find((x) => x.Currency.Abbreviation ==="ZAR");
    return(
        props.data.map(value =><View style={[styles.table, {marginBottom: 10}]}>
            <Text style={{fontSize: 7, paddingBottom:5, borderBottom: 1, borderColor: '#e3e3e3'}} fixed>{props.facilityType === 1 ? "Vendor: " : "Customer: "}<Text style={styles.boldFont}>{value.Name}</Text></Text>
            <View key={value.ContactId}style={styles.tableRowWrapping} fixed>
                <Text style={[styles.textHeaders, {textAlign:'left', width:'7%'}]}>Invoice No.</Text>
                <Text style={[styles.textHeaders, {textAlign:'left', width:'8%', paddingLeft:5}]}>Due Date</Text>
                <Text style={[styles.textHeaders, {textAlign:'center', width:'6%'}]}>Currency</Text>
                <Text style={[styles.textHeaders, {textAlign:'right', width:'12%'}]}>Document Amount</Text>
                <Text style={[styles.textHeaders, {textAlign:'right', width:'10%'}]}>VAT</Text>
                <Text style={[styles.textHeaders, {textAlign:'right', width:'10%'}]}>Document Total</Text>
                <Text style={[styles.textHeaders, {textAlign:'right', width:'8%', paddingRight: 4}]}>Financing %</Text>
                <Text style={[styles.textHeaders, {textAlign:'right', width:'10%'}]}>Payment Amount</Text>
                <Text style={[styles.textHeaders, {textAlign:'right', width:'10%', paddingRight:5}]}>Payment Diff</Text>
                <Text style={[styles.textHeaders, {textAlign:'right', width:'9%', paddingRight:5}]}>Interest</Text>
                <Text style={[styles.textHeaders, {textAlign:'right', width:'10%'}]}>Funding Amount</Text>
            </View>
            {value.VendorTransactions.map(value =>
            <View key={value.Id} style={styles.tableRowWrapping}>
                <Text style={[styles.textData, {textAlign:'left', width:'7%', ...styles.textEllipsis}]}>{value.DocumentNumber}</Text>
                <Text style={[styles.textData, {textAlign:'left', width:'8%', paddingLeft:5}]}>{DateTime.fromISO(value.DueDate).toFormat(DateFormats.ShortDate)}</Text>
                <Text style={[styles.textData, {textAlign:'center', width:'6%'}]}>{value.Amount.OriginalCurrency.Abbreviation}</Text>
                <Text style={[styles.textData, {textAlign:'right', width:'12%'}]}>{formatCurrencyValue(value.DocumentAmount.OriginalCurrency.Symbol,value.DocumentAmount.OriginalValue)}</Text>
                <Text style={[styles.textData, {textAlign:'right', width:'10%'}]}>{formatCurrencyValue(value.VATAmount.OriginalCurrency.Symbol,value.VATAmount.OriginalValue)}</Text>
                <Text style={[styles.textData, {textAlign:'right', width:'10%'}]}>{formatCurrencyValue(value.Amount.OriginalCurrency.Symbol,value.Amount.OriginalValue)}</Text>
                <Text style={[styles.textData, {textAlign:'right', width:'8%', paddingRight: 4}]}>{(value.FinancePercentage.toFixed(2))} %</Text>
                <Text style={[styles.textData, {textAlign:'right', width:'10%'}]}>{formatCurrencyValue(value.PaymentAmount.OriginalCurrency.Symbol,value.PaymentAmount.OriginalValue)}</Text>
                <Text style={[styles.textData, {textAlign:'right', width:'10%', paddingRight:5}]}>{formatCurrencyValue(value.PaymentAmount.OriginalCurrency.Symbol,(value.Amount.OriginalValue - value.PaymentAmount.OriginalValue))}</Text>
                <Text style={[styles.textData, {textAlign:'right', width:'9%', paddingRight:5}]}>{formatCurrencyValue(value.AccruedInterest.OriginalCurrency.Symbol,(value.ProjectedFundingAmount.OriginalValue - value.PaymentAmount.OriginalValue))}</Text>
                <Text style={[styles.textData, {textAlign:'right', width:'10%'}]}>{formatCurrencyValue(value.ProjectedFundingAmount.OriginalCurrency.Symbol,value.ProjectedFundingAmount.OriginalValue)}</Text>
            </View>)}
            {value.Totals.map(value =>
            <View style={styles.tableRowWrapping}>
                <Text style={[styles.textFooters, {textAlign:'left', width:'15%', borderBottom:0.5, borderColor:'lightgrey'}]}>Totals</Text>
                <Text style={[styles.textFooters,{textAlign:'center', width:'6%', borderBottom:0.5, borderColor:'lightgrey'}]}>{value.Currency.Abbreviation}</Text>
                <Text style={[styles.textFooters,{textAlign:'right', width:'12%', borderBottom:0.5, borderColor:'lightgrey'}]}>{formatCurrencyValue(value.Currency.Symbol,value.DocumentAmountTotal)}</Text>
                <Text style={[styles.textFooters,{textAlign:'right', width:'10%', borderBottom:0.5, borderColor:'lightgrey'}]}>{formatCurrencyValue(value.Currency.Symbol,value.VatTotal)}</Text>
                <Text style={[styles.textFooters,{textAlign:'right', width:'10%', borderBottom:0.5, borderColor:'lightgrey'}]}>{formatCurrencyValue(value.Currency.Symbol,value.AmountTotal)}</Text>
                <Text style={[styles.textFooters,{textAlign:'right', width:'8%', borderBottom:0.5, borderColor:'lightgrey', paddingRight: 4}]}>{(value.FinancePercentage.toFixed(2))} %</Text>
                <Text style={[styles.textFooters,{textAlign:'right', width:'10%', borderBottom:0.5, borderColor:'lightgrey'}]}>{formatCurrencyValue(value.Currency.Symbol,value.PaymentAmountTotal)}</Text>
                <Text style={[styles.textFooters,{textAlign:'right', width:'10%', borderBottom:0.5, borderColor:'lightgrey', paddingRight:5}]}>{formatCurrencyValue(value.Currency.Symbol,(value.AmountTotal - value.PaymentAmountTotal))}</Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'9%', borderBottom:0.5, borderColor:'lightgrey', paddingRight:5}]}>{formatCurrencyValue(value.Currency.Symbol,value.IntrestAmountTotal.toFixed(2))}</Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'10%', borderBottom:0.5, borderColor:'lightgrey'}]}>{formatCurrencyValue(value.Currency.Symbol,value.FundingAmountTotal)}</Text>
            </View>
           )}
           {value.Totals.find((x) => x.Currency.Symbol !== props.currency) &&
            <View style={styles.tableRowWrapping}>
                <Text style={[styles.textFooters, {textAlign:'left', width:'15%'}]}>Totals ({value.ConvertedTotals.Currency.Abbreviation} Conversion)</Text>
                <Text style={[styles.textFooters, {textAlign:'center', width:'6%'}]}>{value.ConvertedTotals.Currency.Abbreviation}</Text>
                <Text style={{width:'22%'}}></Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'10%'}]}>{formatCurrencyValue(value.ConvertedTotals.Currency.Symbol,value.ConvertedTotals.AmountTotal)}</Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'8%', paddingRight:4}]}>{(value.ConvertedTotals.FinancePercentage.toFixed(2))} %</Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'10%'}]}>{formatCurrencyValue(value.ConvertedTotals.Currency.Symbol,value.ConvertedTotals.PaymentAmountTotal)}</Text>
                <Text style={{width:'10%', paddingRight:5}}></Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'9%', paddingRight:5}]}>{formatCurrencyValue(value.ConvertedTotals.Currency.Symbol,value.ConvertedTotals.IntrestAmountTotal)}</Text>
                <Text style={[styles.textFooters, {textAlign:'right', width:'10%'}]}>{formatCurrencyValue(value.ConvertedTotals.Currency.Symbol,value.ConvertedTotals.FundingAmountTotal)}</Text>
            </View>
           }
        </View>)
        
    )
}