import { Button, Modal } from "react-bootstrap";
import { useSessionStore } from "../../../../Stores/SessionStore";
import React, { useEffect, useState } from "react";
import Notifications from "./Notifications";
import { getPermissions, Permissions } from "../../../../Utilities/Permissions";
import { FundBenchmarkNotificationTypes } from "../../../../Utilities/Enums";
import swal from "sweetalert";

export default function FundBenchmarkNotificationModal({ fundId, fundName, show, onClose }) {
    const store = useSessionStore();
	const permissions = getPermissions(Permissions.Sections.AllFunds, Permissions.Areas.FundManagement, Permissions.SubAreas.Funds, store.Session);

	const [users, setUsers] = useState(null)
    const [benchmarkAllocationNotifications, setBenchmarkAllocationNotifications] = useState([]);
	const [benchmarkRecoveryNotifications, setBenchmarkRecoveryNotifications] = useState([]);

    useEffect(() => {
        async function getBenchmarkNotifications(){
            let result = await store.FundsService.GetBenchmarkNotificationsForFund(fundId);

            if(result && result.Success){
                setBenchmarkAllocationNotifications(result.Data.filter(n => n.BenchmarkNotificationType === FundBenchmarkNotificationTypes.CapitalAllocation));
				setBenchmarkRecoveryNotifications(result.Data.filter(n => n.BenchmarkNotificationType === FundBenchmarkNotificationTypes.CapitalRecovery));
            }
        }

		async function getUsers(){
			let results = await store.UsersService.GetUsers("", 0, 0);
	
			if(results && results.Success){
				setUsers(results.Data.filter(u => u.Inactive === false));
			}
		}

        if(show && fundId){
            getBenchmarkNotifications();
			getUsers();
        }

    }, [show]);

    function handleAddNotification(percentage, userIds, notificationType) {
		let benchmarkNotification = {
			FundId: fundId,
			Percentage: percentage,
			BenchmarkNotificationType: notificationType,
			UserIds: userIds
		}

		if(notificationType === FundBenchmarkNotificationTypes.CapitalAllocation) {
			let newNotifications = [...benchmarkAllocationNotifications, benchmarkNotification];
			setBenchmarkAllocationNotifications(newNotifications);
		} else {
			let newNotifications = [...benchmarkRecoveryNotifications, benchmarkNotification];
			setBenchmarkRecoveryNotifications(newNotifications);
		}
	}

	function handleDeleteNotification(index, notificationType) {
		let array = null;
		let method = null;

		if(notificationType === FundBenchmarkNotificationTypes.CapitalAllocation) {
			array = benchmarkAllocationNotifications;
			method = setBenchmarkAllocationNotifications;
		} else {
			array = benchmarkRecoveryNotifications;
			method = setBenchmarkRecoveryNotifications;
		}

		if(index > -1) {
			array.splice(index, 1);

			method([...array]);
		}
	}
 
	async function handleSaveBenchmarkNotificationsClicked () {
		let benchmarkNotifications = benchmarkAllocationNotifications.concat(benchmarkRecoveryNotifications);
		let result = await store.FundsService.SetBenchmarkNotificationsForFund(fundId, benchmarkNotifications);

		if(result && result.Success){

			onClose();
			swal({ title: "Success", icon: "success", text: "Benchmark Notifications Saved Successfully.", closeOnClickOutside: false })

		}
	}

	return <Modal size="lg" show={ show }>
		<Modal.Header>
			<h4>{ `Benchmark Notifications for ${fundName || "fund"}`}</h4>
		</Modal.Header>
		<Modal.Body>
			<Notifications 
				users={ users || [] }
				notifications={ benchmarkAllocationNotifications } 
				notificationType={ FundBenchmarkNotificationTypes.CapitalAllocation }
				onAddNotification={ handleAddNotification }
				onDeleteNotification={ handleDeleteNotification }
				permissions={ permissions }
			/>
			
			<Notifications 
				users={ users || [] }
				notifications={ benchmarkRecoveryNotifications } 
				notificationType={ FundBenchmarkNotificationTypes.CapitalRecovery }
				onAddNotification={ handleAddNotification }
				onDeleteNotification={ handleDeleteNotification }
				permissions={ permissions }
			/>
		</Modal.Body>
		<Modal.Footer>
			{permissions.Edit &&
				<Button onClick={ handleSaveBenchmarkNotificationsClicked }>Save</Button>}
			<Button onClick={ onClose }>Close</Button>
		</Modal.Footer>
    </Modal>
}