import React, { useEffect, useState } from "react";
import PageHeader from "../../Core/PageHeader";
import { faFileExcel, faPencil, faPlus, faSackDollar, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { NavLink } from "react-router-dom";
import IconButton from "../../Core/IconButton";
import UseDocumentTitle from "../../../Utilities/UserDocumentTitle";
import Panel from "../../Core/Panel";
import { Table } from "react-bootstrap";
import Pagination from "../../Core/Pagination";
import FundsIndexFilter from "./FundsIndexFilter";
import FundAssignmentsModal from "./FundAssignmentsModal";
import ClickableText from "../../Core/ClickableText";
import FundLinkedInvestorsModal from "./FundLinkedInvestorsModal";
import FundsPreview from "./FundPreview";
import { useSessionStore } from "../../../Stores/SessionStore";
import RoundButton from "../../Core/RoundButton";
import BusyIndicator from "../../Core/BusyIndicator";
import FundBenchmarkNotificationModal from "./FundBenchmarkNotificationsModal/FundBenchmarkNotificationModal";
import { getPermissions, Permissions } from "../../../Utilities/Permissions";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import confirmDelete from "../../../Utilities/Delete";

export default function FundsIndex() {
	UseDocumentTitle('Listing \'Funds\' on CREST');

	const store = useSessionStore();

	const [filter, setFilter] = useState({PageIndex: 0, PageCount: 10, Total: 0, Search: "", FacilityId: ""});
	const [showAssignmentModal, setShowAssignmentModal] = useState(false);
	const [showBenchmarkModal, setShowBenchmarkModal] = useState(false);
	const [showLinkedInvestorsModal, setShowLinkedInvestorsModal] = useState(false);
	const [funds, setFunds] = useState(null);
	const [currentFund, setCurrentFund] = useState(null);
	const [exportingFunds, setExportingFunds] = useState(false);

	useEffect(() => {
		getFunds(filter);
	},[]);

	async function handleExportFundsClicked(){
		setExportingFunds(true);
		var result = await store.FundsService.ExportFunds(filter.Search, Number(filter.FacilityId));

		if(result && result.Success){
			//save file
			var blob = new Blob([result.Data], {type: "text/plain;charset=utf-8"});
			saveAs(blob, `FUNDS_${DateTime.now().toFormat("dd_MM_yyyy")}.csv`);
			setExportingFunds(false);
		}
	}

	async function getFunds(filter){
		let result = await store.FundsService.GetFunds(filter.PageIndex, filter.PageCount, filter.Search, filter.FacilityId);

		if(result && result.Success){
			setFilter({ ...filter, Total: result.Total });
			setFunds(result.Data);
		}
	}

	function handleAssignmentsClicked(fund){
		setShowAssignmentModal(true);
		setCurrentFund(fund);
	}

	function handleAssignmentsClosed() {
		setShowAssignmentModal(false);
	}

	function handleBenchmarkClicked(fund){
		setCurrentFund(fund);
		setShowBenchmarkModal(true);
	}
	
	function handleLinkedInvestorsClicked(fund) {
		setShowLinkedInvestorsModal(true);
		setCurrentFund(fund);
	}

	function handleLinkedInvestorsClosed() {
		setShowLinkedInvestorsModal(false);
		setCurrentFund(null);
	}

	function handleFundSearch(search){
		setFilter({...filter, Search: search.Search, FacilityId: search.FacilityId});
		getFunds({ ...filter, Search: search.Search, FacilityId: search.FacilityId });
	}

	function handlePageChange(pageIndex, pageCount) {
		let filters = {...filter, PageIndex: pageIndex, PageCount: pageCount};

		setFilter(filters);
		getFunds(filters);
	}

	async function handleFundDelete(fund){

		let result = await confirmDelete(fund.Name, fund.Id, (id) => store.FundsService.DeleteFund(id));

		if(result){
			getFunds(filter);
		}
	}

	const permissions = getPermissions(Permissions.Sections.AllFunds, Permissions.Areas.FundManagement, Permissions.SubAreas.Funds, store.Session);

	return <React.Fragment>
		<PageHeader helpItemDescriptor={ "Fund/Index" } icon={ faSackDollar } title="Funds" crumbs={[{ Title: "Fund Management"}, { Title:"Funds" }]}>
			{
				permissions.Add &&
				<NavLink to={ "./Add" }><IconButton icon={ faPlus } space>Add New</IconButton></NavLink>
			}
			{
				permissions.View &&
				<IconButton icon={ faFileExcel } disabled={ exportingFunds } onClick={ handleExportFundsClicked }>
					{ exportingFunds ? "Exporting..." : "Export All" }
				</IconButton>
			}
			
		</PageHeader>
		<Panel>
			<FundsIndexFilter handleSearch={ handleFundSearch } />
			<Table bordered size="sm" responsive>
				<thead>
					<tr>
						<th>Fund No.</th>
						<th>Name</th>
						<th>Type</th>
						<th className="text-center">Status</th>
						<th className="text-center">Linked Investors</th>
						<th className="text-center">Assigned</th>
						<th className="text-center">Benchmark Notifications</th>
						{
							permissions.Edit &&
							<th className="text-center">Edit</th>
						}
						{
							permissions.Delete &&
							<th className="text-center">Delete</th>
						}
						
						<th className="text-center">Preview</th>
					</tr>
				</thead>
				<tbody>
					{
						funds &&
						funds.length > 0 &&
						funds.map(fund => 
							<tr key={ fund.Id }>
								<td><NavLink to={`./View/${fund.Id}`}>{ fund.Number }</NavLink></td>
								<td>{ fund.Name }</td>
								<td>{ fund.Type }</td>
								<td className="text-center">{ fund.Status }</td>
								<td className="text-center"><ClickableText onClick={ e => handleLinkedInvestorsClicked(fund) }>Linked Investors</ClickableText></td>
								<td className="text-center"><ClickableText onClick={ e => handleAssignmentsClicked(fund) }>Assigned</ClickableText></td>
								<td className="text-center"><ClickableText onClick={ e => handleBenchmarkClicked(fund) }>Benchmark Notification</ClickableText></td>
								{
									permissions.Edit &&
										<td className="text-center"><NavLink to={ `./Edit/${fund.Id}/` }><RoundButton icon={ faPencil }></RoundButton></NavLink></td>
								}
								{
									permissions.Delete &&
									<td className="text-center">
										{ fund.Active && <span>Active</span> }
										{ fund.Active === false && <RoundButton onClick={ e => handleFundDelete(fund) } icon={ faTimes }></RoundButton> }
									</td>
								}
								<td className="text-center">
									<FundsPreview fund={ fund }></FundsPreview>
								</td>
							</tr>
						)
					}
				</tbody>
			</Table>
			<BusyIndicator show={ funds === null }></BusyIndicator>
			{
				funds &&
				<Pagination filter={ filter } totalResults={ funds.length } onPageChanged={ handlePageChange }></Pagination>
			}
			
		</Panel>
		<FundAssignmentsModal show={ showAssignmentModal } onClose={ handleAssignmentsClosed } currentFund={ currentFund !== null ? currentFund : "" }></FundAssignmentsModal>
		<FundBenchmarkNotificationModal 
			show={ showBenchmarkModal }
			fundId={ currentFund?.Id } 
			fundName={ currentFund?.Name } 
			onClose={ e => setShowBenchmarkModal(false) }>
		</FundBenchmarkNotificationModal>
		{
			currentFund &&
			<FundLinkedInvestorsModal show={ showLinkedInvestorsModal } fund={ currentFund } onClose={ handleLinkedInvestorsClosed }></FundLinkedInvestorsModal>
		}
		
	</React.Fragment>
}