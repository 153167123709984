import {View, Text, Image} from '@react-pdf/renderer';
import {styles} from '../../StyleSheet';
import Crestlogo from '../../../../Images/crest management system-LOGO.png';
import { DateTime } from 'luxon';
import { DateFormats } from '../../../../Utilities/Enums';

export default function VendorHeader(props){

   

    return(
        <View>
            <View style={{display: 'flex', flexDirection: 'row', justifyContent:'center', paddingLeft: 4, paddingRight: 4, paddingBottom: 15}}>
                <View style={{width:'20%', padding:1}}>
                    {/* {
                        !props.image.AssignmentPictureBase64 &&
                        <Image style={{height:35, alignSelf: "flex-start"}} src={ Crestlogo } />
                    } */}
                    {
                        props.image.AssignmentPictureBase64 &&
                        <Image style={{height:35, alignSelf: "flex-start", paddingLeft:1}} src={ `data:${props.image.AssignmentPictureMIMEType};base64,${props.image.AssignmentPictureBase64}` } />
                    }
                </View>
                <View style={{width:'60%', padding:1}}>
                    <Text style={[styles.Title, {textAlign:'center', paddingTop:10}]}>Contact Payments Report</Text>
                </View>
                <View style={{width:'20%', padding:1}}>
                    {
                        !props.image.ProfilePictureBase64 &&
                        <Image style={{height:35, alignSelf: "flex-end"}} src={ Crestlogo } />
                    }
                    {
                        props.image.ProfilePictureBase64 &&
                        <Image style={{height:35, alignSelf: "flex-end"}} src={ `data:${props.image.ProfilePictureMIMEType};base64,${props.image.ProfilePictureBase64}` } />
                    }
                </View>
            </View>

            <View style={[styles.tableRowWrapping, {fontSize:7, padding:10}]}>
                <Text style={{textAlign:'left', width:'33.3%', ...styles.textEllipsis}}>Payment instruction No.: <Text style={styles.boldFont}>{props.data.PaymentInstructionBalance.Number}</Text> </Text>
                <Text style={{textAlign:'left', width:'33.3%', ...styles.textEllipsis}}>Name: <Text style={styles.boldFont}>{props.data.PaymentInstructionBalance.Name}</Text></Text>
                <Text style={{textAlign:'left', width:'33.3%'}}>Payment Date: <Text style={styles.boldFont}>{ DateTime.fromISO(props.data.PaymentInstructionBalance.PaymentDate).toFormat(DateFormats.LongDate)}</Text></Text>
            </View>
        </View>
    )
}