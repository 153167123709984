import { faFilePdf, faBuildingColumns } from "@fortawesome/pro-regular-svg-icons";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Form, Table, Row, Col } from "react-bootstrap";
import { useSessionStore } from "../../Stores/SessionStore";
import RoundButton from "../Core/RoundButton";
import { hasSectionPermissionsbyArea, Permissions } from "../../Utilities/Permissions";
import PageHeader from "../Core/PageHeader";
import Panel from "../Core/Panel";
import ReportModal from "./ReportModal";
import UseDocumentTitle from "../../Utilities/UserDocumentTitle";
import Pagination from "../Core/Pagination";
import InterestReportModal from "./Facilities/InterestReport/InterestReportModal";

const ReportsIndex = observer(function ReportsIndex() {
	UseDocumentTitle("Credit Management Reports");

	const store = useSessionStore();

	const [showingReportsModal, showReportsModal] = useState(false);
	const [reportName, setReportName] = useState("");
	const [filteredReportNames, setFilteredReportNames] = useState([]);
	const [search, setSearch] = useState("");
	const [filter, setFilter] = useState({ PageIndex: 0, PageCount: 10, Total: 0 });

	const handleReportClicked = (Name) => {
		setReportName(Name);
		showReportsModal(true);
	};

	const handleReportsClosed = () => {
		showReportsModal(false);
	};

	useEffect(() => {
		store.FacilitiesStore.GetFacilities();
	}, [store.FacilitiesStore]);

	useEffect(() => {
		const filteredReports = reportnames.filter((report) => hasSectionPermissionsbyArea(Permissions.Areas.CreditManagement,report, store.Session));
		
		setFilteredReportNames(filteredReports);		
		setFilter({...filter, Total: filteredReports.length});
	}, []);

	const reportnames = [
		"Contact Payments Report",
		"Facility Movement Report",
		"Interest Report",
		"Maturity Date Report",
		"Payables Due Report",
		"Recoveries Report"
	];

	const handleChange = (e) => {
		var searchValue = e.target.value;
		setSearch(searchValue);
		localStorage.setItem("reportSearchValue", searchValue);
	
		if (searchValue === "") {
			setFilteredReportNames(reportnames);
			setFilter({ ...filter, Total: reportnames.length });
		} else {
			const searchFilteredReportNames = filteredReportNames.filter((reportName) => reportName.toLowerCase().includes(searchValue.toLowerCase()));
			setFilteredReportNames(searchFilteredReportNames);
			setFilter({ ...filter, Total: searchFilteredReportNames.length });
		}
	};

	return (
		<React.Fragment>
			<PageHeader
				helpItemDescriptor={"Reports/Index"}
				icon={faBuildingColumns}
				title="Reports"
				crumbs={[{ Title: "Credit Management" }, { Title: "Reports" }]}
			/>
		<Panel>			
			<h6>Filter Reports</h6>
			<Row className="mb-50">
				<Col xs={4}>
					<Form.Group>
						<Form.Control type="search" placeholder="Search..." value={ search } onChange={ handleChange } />
					</Form.Group>
				</Col>
			</Row>			
			<Table bordered size="sm" responsive>
				<thead>
					<tr>
						<th>Report Name</th>
						<th className="text-center">View</th>
					</tr>
				</thead>
				<tbody>
					{filteredReportNames.map((c) => (
						<tr key={c}>
							<td>{c}</td>
							<td className="text-center">
								<RoundButton
									onClick={(e) => handleReportClicked(c)}
									variant="primary"
									icon={faFilePdf}>
								</RoundButton>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
			<Pagination filter={ filter } totalResults={ filteredReportNames.length }></Pagination>
		</Panel>
		{
			reportName !== "Interest Report" &&
			<ReportModal
			show={showingReportsModal}
			Name={reportName}
			onClose={handleReportsClosed}
			></ReportModal>
		}
		{
			reportName === "Interest Report" &&
			<InterestReportModal show={ showingReportsModal } Name={reportName} onClose={handleReportsClosed} />
		}
		</React.Fragment>
	);
});

export default ReportsIndex;