import {View, Text, Image} from '@react-pdf/renderer';
import { styles } from '../../StyleSheet';
import Crestlogo from '../../../../Images/crest management system-LOGO.png';

export default function MaturityDateHeader(props){

 

    return(
        <View>
            <View style={{display: 'flex', flexDirection: 'row', justifyContent:'center', paddingLeft: 4, paddingRight: 4, paddingBottom: 15}}>
                <View style={{width:'20%', padding:1}}>
                    {/* {
                        !props.data.AssignmentPictureBase64 &&
                        <Image style={{height:35, alignSelf: "flex-start"}} src={ Crestlogo } />
                    } */}
                    {
                        props.data.AssignmentPictureBase64 &&
                        <Image style={{height:35, alignSelf: "flex-start", paddingLeft:1}} src={ `data:${props.data.AssignmentPictureMIMEType};base64,${props.data.AssignmentPictureBase64}` } />
                    }
                </View>
                <View style={{width:'60%', padding:1}}>
                    <Text style={[styles.Title, {textAlign:'center', paddingTop:10}]}>Maturity Date Report</Text>
                </View>
                <View style={{width:'20%', padding:1}}>
                    {
						!props.data.ProfilePictureBase64 &&
						<Image style={{height:35, alignSelf: "flex-end"}}  src={ Crestlogo } />
					}
					{
						props.data.ProfilePictureBase64 &&
						<Image style={{height:35, alignSelf: "flex-end"}}  src={ `data:${props.data.ProfilePictureMIMEType};base64,${props.data.ProfilePictureBase64}` } />
					}
                </View>
            </View>

            <View style={{paddingBottom:10, paddingLeft: 3}}>
                <View style={[styles.tableRowWrapping, {fontSize:7, paddingBottom:3}]}>
                    <Text style={{width: '33.3%', textAlign:'left'}}>From Date: <Text style={styles.boldFont}>{props.data.FromDate}</Text> </Text>
                    <Text style={{width: '33.3%', textAlign:'left'}}>To Date: <Text style={styles.boldFont}>{props.data.ToDate}</Text></Text>
                </View>
                <View style={[styles.tableRowWrapping, {fontSize:7}]}>
                    <Text style={{width: '33.3%', textAlign:'left', ...styles.textEllipsis}}>Group: <Text style={styles.boldFont}>{props.data.GroupName}</Text> </Text>
                    <Text style={{width: '33.3%', textAlign:'left', ...styles.textEllipsis}}>Market Position: <Text style={styles.boldFont}>{props.data.MarketPositionName}</Text></Text>
                    <Text style={{width: '33.3%', textAlign:'left', ...styles.textEllipsis}}>Store: <Text style={styles.boldFont}>{props.data.StoreName}</Text></Text>
                </View>
            </View>
        </View>
    )
}