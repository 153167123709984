import { Field, useFormikContext } from "formik";
import Panel from "../../../Core/Panel";
import TextValue from "../../../Core/TextValue";

export default function SpecialInstructions({ view, canoverride, disabled }) {
	const formik = useFormikContext();

	return <Panel title="Special Instructions">
				{
					!view && !canoverride &&
					<Field name="SpecialInstructions">
						{ ({ field }) => ( <textarea disabled={disabled} className="form-control" { ...field }></textarea> ) }
					</Field>
				}
				{
					view &&			
					<TextValue value={ formik.values["SpecialInstructions"] }></TextValue>
				}
			</Panel>
}