import { faFileExcel, faPencil, faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import React, { useState } from "react";
import { Badge, Button, Col, Form, Row, Table } from "react-bootstrap";
import BusyIndicator from "../../../Core/BusyIndicator";
import IconButton from "../../../Core/IconButton";
import PageHeader from "../../../Core/PageHeader";
import Pagination from "../../../Core/Pagination";
import Panel from "../../../Core/Panel";
import { NavLink, useParams } from "react-router-dom";
import RoundButton from "../../../Core/RoundButton";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { useEffect } from "react";
import BackButton from "../../../Core/BackButton";
import swal from "sweetalert";
import { useSessionStore } from "../../../../Stores/SessionStore";

export default function StructureIndex(props) {
	const store = useSessionStore();
	const [exporting, setExporting] = useState(false);

	const [filter, setFilter] = useState({ PageIndex: 0, PageCount: 10, Total: 0 });
	const [search, setSearch] = useState("");

	const params = useParams();
	function handlePageChange(pageIndex, pageCount) {
		let filters = {...filter, PageIndex: pageIndex, PageCount: pageCount};

		setFilter(filters);
		props.getEntities(search, filters);
	}

	async function handleExportClicked() {
		setExporting(true);
		var result = await props.export(search);
		setExporting(false);

		if(result && result.Success) {
			//save file
			var blob = new Blob([result.Data], {type: "text/plain;charset=utf-8"});
			saveAs(blob, `${props.entityName.replace(" ", "").toUpperCase()}_${DateTime.now().toFormat("dd_MM_yyyy")}.csv`);
		}
	}

	useEffect(() => {
		props.getEntities(search, filter);
		
	}, []);
	
	function getNavigationLink() {
		if(params.parentId) {
			return `/Admin/Groups/${props.entityName.replace(" ", "")}`;
		} else {
			return `/Admin/${props.entityName.replace(" ", "")}`;
		}
	}

	async function deleteEntity(entity) {
		let result = await props.deleteEntity(entity.Id);

		if(result) {
			await store.UpdateUserAppStructure();
			swal({ title: "Success", icon: "success", text: `${entity.Name} deleted`, closeOnClickOutside: false});
			props.getEntities(search, filter);
		} else {
			swal({ title: "Error", icon: "error", text: `An error occurred attempting to delete ${entity.Name}`, closeOnClickOutside: false});
		}
	}

	function handleDeleteClicked(entity) {
		swal({ 
			title: "Are you sure?", 
			text: `You are about to delete ${entity.Name}, are you sure?`,
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
					visible: true,
					closeModal: true,
			},
				confirm: {
					text: "OK",
					value: true,
					visible: true,
					closeModal: true
				}
			},
			closeOnClickOutside: false
		}).then(result => {
			if(result) {
				deleteEntity(entity);
			}
		});
	}

	return <React.Fragment>
				<PageHeader icon={ props.icon } title={ props.title }  helpItemDescriptor={ `Admin/${props.entityName.replace(" ", "")}` }
							crumbs={[{ Title: "Admin" }, { Title: "Groups" }, { Title: props.title }]}>
						
					<NavLink to={ `${getNavigationLink()}/Add${params.parentId ? `/${params.parentId}`: ""}${params.parentId ? `/${params.parentId}`: ""}${params.grandparentId ?`/${params.grandparentId}` :""}` }><IconButton space icon={ faPlus }>Add New</IconButton></NavLink>
					<IconButton space icon={ faFileExcel } disabled={ exporting } onClick={handleExportClicked}>
						{ exporting ? "Exporting..." : "Export All" }
					</IconButton>
					{
						params.parentId &&
						<BackButton parent={params.parentId}></BackButton>
					}
				</PageHeader>
				<Panel>
					<h6>Filter { props.entityName }</h6>
					<Row className="mb-50">
						<Col xs={4}>
							<Form.Group>
								<Form.Control placeholder="Search..." value={search} onChange={ e => setSearch(e.target.value) } type="search" ></Form.Control>
							</Form.Group>
						</Col>
						<Col className="d-flex justify-content-end align-items-end">
							<Button className="text-nowrap" onClick={ e => props.getEntities(search, filter) }>Filter</Button>
						</Col>
					</Row>
					<Table bordered size="sm" responsive>
						<thead>
							<tr>
								<th>Name</th>
								<th>Contact</th>
								<th>Cel No.</th>
								<th>Tel. No.</th>
								{
									props.childrenName &&
									<th>{props.childrenName}</th>
								}
								<th className="text-center">Edit</th>
								<th className="text-center">Delete</th>
							</tr>
						</thead>
						<tbody>
							{
								props.entities &&
								props.entities.map(entity =>
								<tr key={ entity.Id }>
									<td>
										<NavLink to={ `${getNavigationLink()}/View/${ entity.Id }${params.parentId ? `/${params.parentId}`: ""}${params.grandparentId ?`/${params.grandparentId}` :""}`}>{ entity.Name }</NavLink>
									</td>
									<td>{ entity.ContactName }</td>
									<td>{ entity.CellphoneNumber }</td>
									<td>{ entity.TelephoneNumber }</td>
									{
										props.childrenName &&
										<td>
											{ 
												entity.AllowChildren &&
												<NavLink to={`/Admin/Groups/${props.childrenName.replace(" ", "")}/${ entity.Id }${params.parentId ?`/${params.parentId}`: ""}`}>{ props.childrenName } <Badge>{entity.TotalChildren}</Badge></NavLink>
												
											}
											
											{ 
												!entity.AllowChildren &&
												<span>No {props.childrenName}</span>
											}
										</td>
									}
									
									<td className="text-center">
										<NavLink to={`${getNavigationLink()}/Edit/${ entity.Id }${params.parentId ? `/${params.parentId}`: ""}${params.grandparentId ?`/${params.grandparentId}` :""}`}><RoundButton icon={ faPencil }></RoundButton></NavLink>
									</td>
									<td className="text-center">
										{
											entity.Active && <span>Active</span>
										}
										{
											!entity.Active && <RoundButton onClick={ e => { handleDeleteClicked(entity) } } icon={ faTimes }></RoundButton>
										}
									</td>
								</tr>)
							}
						</tbody>
					</Table>
					{
						props.entities && props.entities.length === 0 &&
						<div className="text-center" >No data available in table</div>
					}
					<BusyIndicator show={ props.entities === null } size="2x"></BusyIndicator>
					{
						props.entities &&
						<Pagination filter={ {...filter, Total: props.total} } totalResults={ props.entities.length } onPageChanged={ handlePageChange }></Pagination>
					}
				</Panel>
			</React.Fragment>
}