import APIBase from "./APIBase";

export default class ReportsService extends APIBase{

     /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
      constructor(apiUrl, sessionStore) {
        super(apiUrl + "Reports", sessionStore);
    }

	async GetFacilityPreview(facilityId) {
		return this.Get(`GetFacilityPreview?facilityId=${facilityId}`);
	}

	async GetFundPreview(fundId){
		return this.Get(`GetFundPreview?fundId=${ fundId }`);
	}

  	async GetPaymentInstructionPreview(paymentInstructionId,showRecoveries) {
		return this.Get(`GetPaymentInstructionPreview?PaymentInstructionId=${paymentInstructionId}&showRecoveries=${showRecoveries}`);
	}
  
  	async GetMaturityDateReport(fromDate, toDate, groupId, marketPositionId, storeId) {
		return this.Get(`GetMaturityDateReport?FromDate=${fromDate}&ToDate=${toDate}&GroupId=${groupId}&MarketPositionId=${marketPositionId}&StoreId=${storeId}`);
	}

	async GetFacilitiesMovementReport(fromDate, toDate, groupId, facilitiesIds, detailed) {
		return this.Post(`GetFacilitiesMovementReport?FromDate=${fromDate}&ToDate=${toDate}&GroupId=${groupId}&Detailed=${detailed}`, facilitiesIds);
	}

  	async GetRecoveriesReport(paymentInstructionId) {
		return this.Get(`GetRecoveriesReport?PaymentInstructionId=${paymentInstructionId}`);
	}

  	async GetPayablesDueReport(paymentInstructionId) {
		return this.Get(`GetPayablesDueReport?PaymentInstructionId=${paymentInstructionId}`);
	}

  	async GetVendorReport(vendorId, paymentInstructionId) {
		return this.Get(`GetVendorReport?VendorId=${vendorId}&PaymentInstructionId=${paymentInstructionId}`);
	}

  	async GetContacts() {
		return this.Get(`GetContacts`);
	}

  	async GetPaymentInstructions(groupId, facilitiesIds) {
		return this.Post(`GetPaymentInstructions?groupId=${groupId || ""}`, facilitiesIds);
	}

 	async GetFacilities(groupId){
		return this.Get(`GetFacilities?GroupId=${groupId}`);
	}

	async GetFacilitiesInterestReport(filters){
		return this.Post(`GetFacilitiesInterestReport`, filters)
	}
}