import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite"
import { Container } from "react-bootstrap";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSessionStore } from "../../Stores/SessionStore";
import { getPermissions, Permissions, hasSectionPermissions } from "../../Utilities/Permissions";
import PageFooter from "../Core/PageFooter";
import Sidebar from "../Core/Sidebar";
import AccountRoutes from "./Account/AccountRoutes";
import AdminRoutes from "./Admin/AdminRoutes";
import ApprovalRoutes from "./Approvals/ApprovalRoutes";
import AuditRoutes from "./AuditLogs/AuditRoutes";
import FacilitiesIndex from "./Facilities/FacilitiesIndex";
import Facility from "./Facilities/FacilityDetails/Facility";
import Underutilisation from "./Facilities/Underutilisation";
import FinanceRoutes from "./Finance/FinanceRoutes";
import PaymentManagementRoutes from "./PaymentManagement/PaymentManagementRoutes";
import Evaluator from "./MyZone/Evaluator";
import ReportRouting from "../Reports/ReportRoutes";
import FundRoutes from "./FundManagement/FundRoutes";

const Main = observer(function Main() {
	const store = useSessionStore();
	const facilityPermissions = getPermissions(Permissions.Sections.AllFacilities, Permissions.Areas.CreditManagement, Permissions.SubAreas.Facilities, store.Session);
	const UnderutilisationPermissions = getPermissions(Permissions.Sections.Underutilisation, Permissions.Areas.CreditManagement, Permissions.SubAreas.Facilities, store.Session);
	const [defaultRoute, setDefaultRoute] = useState("");

	useEffect(() => {
		getDefaultRoute();
	}, []);

	function getDefaultRoute() {
		const routes = [
			{ subArea: 'Funds', section: 'All Funds', navLink: '/Funds'},
			{ subArea: 'Facilities', section: 'All Facilities', navLink: '/Facilities' },
			{ subArea: 'Facilities', section: 'Underutilisation', navLink: '/Underutilisation' },
			{ subArea: 'Trade Finance', section: 'Vendor Documents', navLink: '/TradeFinance/VendorDocuments' },
			{ subArea: 'Trade Finance', section: 'Payable Transactions', navLink: '/TradeFinance/PayableTransactions' },
			{ subArea: 'Trade Finance', section: 'Transaction Recoveries', navLink: '/TradeFinance/TransactionRecoveries' },
			{ subArea: 'Debtor Finance', section: 'Customer Documents', navLink: '/DebtorFinance/CustomerDocuments' },
			{ subArea: 'Debtor Finance', section: 'Debt Transactions', navLink: '/DebtorFinance/DebtTransactions' },
			{ subArea: 'Debtor Finance', section: 'Transaction Recoveries', navLink: '/DebtorFinance/TransactionRecoveries' },
			{ subArea: 'Payment Management', section: 'Payment Instructions', navLink: '/PaymentManagement/PaymentInstructions' },
			{ subArea: 'Payment Management', section: 'Payment Processing', navLink: '/PaymentManagement/PaymentProcessing' },
			{ subArea: 'Payment Management', section: 'Payment Recoveries', navLink: '/PaymentManagement/PaymentRecoveries' },
			{ subArea: 'Payment Management', section: 'Payment Reimbursements', navLink: '/PaymentManagement/Reimbursements' },
			{ subArea: 'Reports', section: 'Contact Payments Report', navLink: '/Reports/CreditManagementReports' },
			{ subArea: 'Reports', section: 'Facility Movement Report', navLink: '/Reports/CreditManagementReports' },
			{ subArea: 'Reports', section: 'Interest Report', navLink: '/Reports/CreditManagementReports' },
			{ subArea: 'Reports', section: 'Maturity Date Report', navLink: '/Reports/CreditManagementReports' },
			{ subArea: 'Reports', section: 'Payables Due Report', navLink: '/Reports/CreditManagementReports' },
			{ subArea: 'Reports', section: 'Recoveries Report', navLink: '/Reports/CreditManagementReports' }
		]

		for (let route of routes) {
			if (hasSectionPermissions(route.subArea, route.section, store.Session)) {
				setDefaultRoute(route.navLink);
				break;
			}
		}
	}

	return	<React.Fragment>
				<Sidebar></Sidebar>
				<div className="app-content content" style={{ marginLeft: (store.HideSidebar ? "0px" : "269px"), padding: "1rem" }}>
						<div className="content-overlay"></div>
						<div className="header-navbar-shadow"></div>
						<Container fluid className="content-wrapper px-0 pb-0" style={{ paddingTop: "40px" }}>
							<div className="content-body">
								<div className="flex-grow-1">
									<Routes>
										<Route path="/Facilities" element={ <FacilitiesIndex></FacilitiesIndex> }></Route>
										
										{
											
											facilityPermissions.Add &&
											<Route path="/Facilities/Add" element={ <Facility Permissions={facilityPermissions.Add} add></Facility> }></Route>
										}

										{
											facilityPermissions.Edit &&
											<Route path="/Facilities/Edit/:facilityId" element={ <Facility edit></Facility> }></Route>
										}

										{
											facilityPermissions.View &&
											<Route path="/Facilities/View/:facilityId" element={ <Facility view></Facility> }></Route>
										}

										{
											UnderutilisationPermissions.View &&
											<Route path="/Underutilisation" element={ <Underutilisation></Underutilisation> }></Route>
										}
										{
											ReportRouting()
										}
										{
											FinanceRoutes()
										}
										{
											PaymentManagementRoutes()
										}
										{
											AdminRoutes()
										}
										{
											AccountRoutes()
										}
										{
											ApprovalRoutes()
										}
										{
											AuditRoutes()
										}
										{
											FundRoutes()
										}
										<Route path="/MyZone/Home" element={ <Evaluator></Evaluator> }></Route>
										{defaultRoute && <Route path="*" element={ <Navigate to={defaultRoute} /> }></Route>}
									</Routes>
								</div>
							</div>
						</Container>
					</div>
					<PageFooter></PageFooter>
			</React.Fragment>
});

export default Main;