import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import { FieldArray } from "formik"
import { DateTime } from "luxon"
import React, { useEffect } from "react"
import { useState } from "react"
import { Button, Col, Form, InputGroup, Modal, Row, Table } from "react-bootstrap"
import { GetRandomKey, removeModalTabIndex } from "../../../../../Utilities/React"
import DateInput from "../../../../Core/Forms/DateInput"
import NumberInput from "../../../../Core/Forms/NumberInput"
import DateValue from "../../../../Core/DateValue"
import NumberValue from "../../../../Core/NumberValue"
import CheckboxField from "../../../../Core/Forms/CheckboxField"
import { DateFormats } from "../../../../../Utilities/Enums"
import RoundButton from "../../../../Core/RoundButton"

export default function RateHistoryModal({ show, rates, rateType, title, fieldName, disabled, onClose, permissions }) {
	const [date, setDate] = useState("");
	const [rate, setRate] = useState("");

	useEffect(() => {
		if(show === false) {
			setDate("");
			setRate("");
		} else {
			removeModalTabIndex();
		}
	}, [show])

	function handleAddRateClicked(pushMethod) {
		if(date && (rate || rate === 0)) {
			pushMethod({ StartDate: date, Rate: rate, Active: false, RateHistoryType: rateType });
			setDate("");
			setRate("");
		}
	}

	function getSortedRates() {
		rates.sort((a, b) => {
            var aDate = DateTime.fromISO(a.StartDate);
            var bDate = DateTime.fromISO(b.StartDate);

            var result = bDate - aDate;

            return result;
        })

		return rates;
	}

	return <Modal show={show} size="lg">
				<Modal.Header>
					<h4>
						{title}
					</h4>
				</Modal.Header>
				<Modal.Body>
					{
						rates &&
						<FieldArray name={fieldName} validateOnChange={false}>
											{({ push, remove }) => (
						<React.Fragment>
							{
								permissions.Add &&
								<React.Fragment>
									<Row>
										<Col>
											<Form.Group as={Row} className="d-flex align-items-center mb-50">
												<Form.Label xs={4} column>Date</Form.Label>
												<Col>
													<DateInput date={date} disabled={ disabled } onChange={ e => setDate(e) }></DateInput>
												</Col>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col>
											<Form.Group as={Row} className="d-flex align-items-center mb-50">
												<Form.Label xs={4} column>Rate</Form.Label>
												<Col>
													<InputGroup>
														<NumberInput value={rate} disabled={ disabled } onChange={ e => setRate(e) }></NumberInput>
														<InputGroup.Text>%</InputGroup.Text>
													</InputGroup>
												</Col>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col className="d-flex justify-content-end mb-2">
											<Button onClick={ e => handleAddRateClicked(push) } disabled={ disabled }>Add</Button>
										</Col>
									</Row>
								</React.Fragment>
							}
							<Row>
								<Col>
									<Table bordered className="align-middle" size="sm" responsive>
										<thead>
											<tr>
												<th>Date</th>
												<th>Rate</th>
												{
													(permissions.Edit || permissions.View) &&
													<th>Status</th>
												}
												{
													(permissions.Delete || permissions.View) &&
													<th className="text-center">Delete</th>
												}
											</tr>
										</thead>
										<tbody>
											{ getSortedRates().map((history, index) => (
											<tr key={ GetRandomKey() }>
												<td><DateValue date={ history.StartDate } outputFormat={ DateFormats.FullLongDate }></DateValue> </td>
												<td><NumberValue value={ history.Rate }></NumberValue>%</td>
												{
													(permissions.Edit || permissions.View) &&
													<td>
														<CheckboxField col={1} swap label="Active" name={ `${fieldName}[${index}].Active` } disabled={ disabled } active></CheckboxField>
													</td>
												}
												{
													(permissions.Delete || permissions.View) &&
													<td className="text-center">
														<RoundButton onClick={ disabled ? "" : e => remove(index) } icon={ faTimes }></RoundButton>
													</td>
												}
											</tr>))}
										</tbody>
									</Table>
								</Col>
							</Row>
						</React.Fragment>
						)}
						</FieldArray>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={ onClose }>Close</Button>
				</Modal.Footer>
			</Modal>
}