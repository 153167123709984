/**
 * @typedef PermissionSet
 * @property {boolean} View
 * @property {boolean} Add
 * @property {boolean} Edit
 * @property {boolean} Delete
 * @property {function} All
 * @property {function} Any
 */

export const Roles = {
	Admin: "1",
	User: "2"
}

export const PermissionAction = {
	View: 1,
	Edit: 2,
	Add: 3,
	Delete: 4
}

export const Permissions = {
	Sections: {
		AllFacilities: "All Facilities",
		FacilitiesReview: "Facilities Review",
		Reports: "Reports",
		VendorDocuments: "Vendor Documents",
		PayableTransactions: "Payable Transactions",
		TransactionRecoveries: "Transaction Recoveries",
		PaymentInstructions: "Payment Instructions",
		PaymentProcessing: "Payment Processing",
		PaymentRecoveries: "Payment Recoveries",
		Home: "Home",
		Finance: "Finance",
		Credit:"Credit",
		Trade:"Trade",
		ContactPaymentsReport: "Contact Payments Report",
		PayablesDueReport: "Payables Due Report",
		MaturityDateReport: "Maturity Date Report",
		RecoveriesReport: "Recoveries Report",
		FacilityMovementReport: "Facility Movement Report",
		CustomerDocuments: "Customer Documents",
		DebtTransactions: "Debt Transactions",
		PaymentReimbursements: "Payment Reimbursements",
		TradeControl: "Trade Control",
		PurchaseDocuments: "Purchase Documents",
		PurchaseOrders: "Purchase Orders",
		PurchaseReceipts: "Purchase Receipts",
		SalesDocuments: "Sales Documents",
		SalesOrders: "Sales Orders",
		Returns: "Returns",
		ItemAdjustments: "Item Adjustments",
		InventoryItems: "Inventory Items",
		Warehouses: "Warehouses",
		Categories: "Categories",
		Departments: "Departments",
		InventoryClasses: "Inventory Classes",
		Underutilisation: "Underutilisation",
		AllFunds: "All Funds",
		FundsUnderutilisation: "Underutilisation",
		FundsReview: "Fund Review",
		CapitalAllocations: "Capital Allocations",
		CapitalRecoveries: "Capital Recoveries"
	},
	Areas: {
		CreditManagement: "Credit Management",
		MyZone: "My Zone",
		TradeManagement: "Trade Management",
		FundManagement: "Fund Management"
	},
	SubAreas: {
		Facilities: "Facilities",
		TradeFinance: "Trade Finance",
		PaymentManagement: "Payment Management",
		Reports: "Reports",
		DebtorFinance: "Debtor Finance",
		TradeControl: "Trade Control",
		Procurement: "Procurement",
		Sales: "Sales",
		ItemAdjustments: "Item Adjustments",
		Inventor: "Inventory",
		Funds: "Funds",
		FundsPaymentManagement: "Payment Management"
	}
}

/**
 * @param { string } area
 * @param { string } subArea
 * @param { string } section
 * @param { import("../Services/UsersService").Session } session
 */
export function hasPermissions(area, subArea, section, session, permissionAction) {
	if(session.Role === Roles.Admin || session.AuthState.UserAppStructure.GroupAdminLevel) {
		return true;
	} else {
		var permission = session.AuthState.Permissions.find(p => p.Section === section && p.Area === area && p.SubArea === subArea);

		if(permission) {
			switch (permissionAction) {
				case PermissionAction.Add:
					return permission.Add;
				case PermissionAction.Edit:
					return permission.Edit;
				case PermissionAction.View:
					return permission.View;
				case PermissionAction.Delete:
					return permission.Delete;
				default:
					return false;
			}
		}
	}

	return false;
}

export function hasSubPermissions(area, subArea, session) {
	if(session.Role === Roles.Admin||session.AuthState.UserAppStructure.GroupAdminLevel) {
		return true;
	} else {
		var permissions = session.AuthState.Permissions.filter(p => p.Area === area && p.SubArea === subArea);
		var aretrue = false;

		if(permissions) {
			for (var i = 0; i < permissions.length; i++) {
				const permission = Object.values(Object.values(permissions)[i]);
				
				aretrue = permission.some(
					value => value === true
				);
				if (aretrue) {
					break;
				}
			}
			return aretrue;
						
			}
	}
	return false;
}

export function hasSectionPermissions(subArea, section, session) {
	if(session.Role === Roles.Admin || session.AuthState.UserAppStructure.GroupAdminLevel) {
		return true;
	} else {
		var permission = session.AuthState.Permissions.find(p => p.SubArea === subArea && p.Section === section);

		if(permission) {
			const aretrue = Object.values(permission).some(value => value === true);
			
			return aretrue;
		}
	}

	return false;
}

export function hasSectionPermissionsbyArea(Area,section, session) {
	if(session.Role === Roles.Admin || session.AuthState.UserAppStructure.GroupAdminLevel) {
		return true;
	} else {
		var permissions = session.AuthState.Permissions.find( p => p.Area === Area && p.Section === section);

		if(permissions) {
			const hasPermission = Object.values(permissions).some(value => value === true);				
			return hasPermission;						
		}
	}

	return false;
}

export function hasMenuPermissions(area, session) {
	if(session.Role === Roles.Admin || session.AuthState.UserAppStructure.GroupAdminLevel) {
		return true;
	} else {
		var areaPermissions = session.AuthState.Permissions.filter(p => p.Area === area);
		
		if(areaPermissions) {
			const hasPermission = Object.values(areaPermissions).some(value => Object.values(value).some(val => val === true));				
			return hasPermission;
		}
	}

	return false;
}

/**
 * @param { string } name
 * @param { import("../Services/UsersService").Session } session
 */
export function hasControl(name, session) {	
	if(session.AuthState.Controls.find(c => c.Section === name)) {
		return true;
	}

	return false;
}

/**
 * @returns { PermissionSet }
 */
function getNewPermission(add, edit, view, del) {
	return {
		Add: add,
		Delete: del,
		Edit: edit,
		View: view,
		All: function () {
			return this.Add && this.Edit && this.View && this.Delete;
		},
		Any: function () {
			return this.Add || this.Edit || this.View || this.Delete;
		}
	}
}

/**
 * @param { string } area
 * @param { string } subArea
 * @param { string } section
 * @param { import("../Services/UsersService").Session } session
 * @returns { PermissionSet }
 */
 export function getPermissions(section, area, subArea, session) {
	if(session.Role === Roles.Admin || session.AuthState.UserAppStructure.GroupAdminLevel) {
		return getNewPermission(true, true, true, true);
	} else {
		var permission = session.AuthState.Permissions.find(p => p.Section === section && p.Area === area && p.SubArea === subArea);
	
		if(permission) {
			return getNewPermission(permission.Add, permission.Edit, permission.View, permission.Delete);
		} else {
			return getNewPermission(false, false, false, false);
		}
	}
}