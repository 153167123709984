import { faFilePdf } from "@fortawesome/pro-regular-svg-icons";
import { useSessionStore } from "../../../Stores/SessionStore";
import RoundButton from "../../Core/RoundButton";
import { useState } from "react";
import { FundPreview as FundPreviewPDF } from "../../Reports/Funds/FundPreview";
import ReactPDF from "@react-pdf/renderer";
import { saveAs } from "file-saver";

export default function FundPreview(props){

    const store = useSessionStore();

    const [generatingPreview, setGeneratingPreview] = useState(false);

    async function handleFundPreviewClicked(){

        setGeneratingPreview(true);

        const result = await store.ReportsService.GetFundPreview(props.fund.Id);
        
        const blob = await ReactPDF.pdf(FundPreviewPDF({ data: result.Data })).toBlob();
        saveAs(blob, `Fund Preview - ${result.Data.Header.FundName}.pdf`);

        setGeneratingPreview(false);

    }

    return <RoundButton onClick={ handleFundPreviewClicked } busy={ generatingPreview } variant="primary" icon={ faFilePdf }></RoundButton>;
}