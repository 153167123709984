import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, useFormikContext } from "formik";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { GetRandomKey } from "../../../../Utilities/React";
import InputField from "../../../Core/Forms/InputField";
import Panel from "../../../Core/Panel";

export default function Agreements({ view, name, agreements, title, heading1, heading2 }) {
	const formik = useFormikContext();

	return 	<Panel title={ title }>
				{
					(heading1 || heading2) &&
					<Row>
						<Col><small>{ heading1 }</small></Col>
						<Col><small>{ heading2 }</small></Col>
					</Row>
				}
				<FieldArray name={ name } validateOnChange={false}>
					{({ push }) => (
						<div>
							{ agreements && agreements.map((agreement, index) => (
								<Row key={ agreement.Key } className="mb-50">
									<Col><InputField  disabled={view} invalid={ (index === 0 && formik.errors[name]) } required={ index === 0 } name={ `${name}.${index}.Field` }></InputField></Col>
									<Col><InputField disabled={view}  invalid={ (index === 0 && formik.errors[name]) } required={ index === 0 } name={ `${name}.${index}.Value` }></InputField></Col>
								</Row>))
							}

							{
								!view && 
								<Row>
									<Col className="d-flex justify-content-end">
										<Button onClick={() => push({ Id: 0, Field: "", Value: "", Key: GetRandomKey() })}>
											<FontAwesomeIcon transform="grow-2" icon={ faPlus } ></FontAwesomeIcon>
										</Button>
									</Col>
								</Row>
							}							
						</div>
					)}
				</FieldArray>
			</Panel>
}