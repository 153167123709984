import { DateTime } from "luxon";
import swal from "sweetalert";
import { FacilityConditions, UserRoles } from "./Enums";
import { getRateForFinancialTerm } from "./FinancialTerms";
import { hasControl } from "./Permissions";


/**
 * Retrieves the list of providers that can be associated with the instrument, will just remove any providers that are already associated
 */
export function getAvailableProviders(providers, linkedProviders) {
	var availableProviders = [];

	if(linkedProviders && providers) {
		for(var i = 0; i < providers.length; i++) {
			let investor = providers[i];
			let existingInvestor = linkedProviders.find((f) => f.ContactId === investor.Id);

			if(!existingInvestor) {
				availableProviders.push(investor);
			}
		}
	}

	return availableProviders;
}

export function getProviderPercentage(provider, providers) {
	var total = 0;
	var limit = getCurrentProviderTotal(provider);

	for(var i = 0; i < providers.length; i++) {
		total += getCurrentProviderTotal(providers[i]);
	}

	if(total > 0) {
		return (limit / total) * 100.0;
	}

	return 0;
}

/**
 * Gets the latest active provider total for the given provider
 */
export function getCurrentProviderTotal(provider) {
	var activeLimits = provider.LimitHistory.filter((l) => l.Active === true);

	activeLimits.sort((a, b) => {
		var aDate = DateTime.fromISO(a.Date);
		var bDate = DateTime.fromISO(b.Date);

		var result = bDate - aDate;

		return result;
	});

	let limit = activeLimits.reduce((total, object) => { 
		if(object.Active === true && DateTime.fromISO(object.Date).ts <= DateTime.now()) {
			total = total + object.Limit
		} 
		return total;
	}, 0);

	return (limit);
}

export function getCurrentRate(rates) {
	var today = Date.now()
	var activeRates = rates.filter((r) => r.Active === true && DateTime.fromISO(r.StartDate) <= today);

	activeRates.sort((a, b) => {
		var aDate = DateTime.fromISO(a.StartDate);
		var bDate = DateTime.fromISO(b.StartDate);

		var result = bDate - aDate;

		return result;
	});

	return (activeRates[0] ? activeRates[0].Rate : 0);
}

export function getTotalMargins(fundersMargins, tradingMargins, useFixedBaseRate, baseInterestRates, financialTerms, financialTermId) {
	var total = getCurrentRate(fundersMargins) + getCurrentRate(tradingMargins);

	if(useFixedBaseRate) {
		total += getCurrentRate(baseInterestRates);
	} else {
		var rate = getRateForFinancialTerm(financialTerms, financialTermId);

		if(rate) {
			total += rate;
		}
	}

	return total;
}

function hasInterestEndDateBeenUpdated(oldFacility, newFacility) {
	let oldEndDate = DateTime.fromISO(oldFacility.FixedBaseRateExpiryDate);
	let newEndDate = DateTime.fromISO(newFacility.FixedBaseRateExpiryDate);

	if(newEndDate.invalidReason) {
		return false;
	}

	if(oldEndDate.invalidReason && !newEndDate.invalidReason) {
		return true;
	}

	return oldEndDate.diff(newEndDate, "days").days !== 0;
}

/**
 * returns whether or not the base interest rate end date has been updated if the base interest rates have changed
 * @param {any} oldInstrument 
 * @param {any} newInstrument 
 */
export function validateBaseInterestRateChanges(oldInstrument, newInstrument) {
	let oldRates = oldInstrument.BaseInterestRates;
	let newRates = newInstrument.BaseInterestRates;

	let rateEndDateUpdated = hasInterestEndDateBeenUpdated(oldInstrument, newInstrument);

	if(!oldRates && !newRates) {
		return true;
	}

	if((!oldRates && newRates) || (oldRates && !newRates))  {
		return rateEndDateUpdated;
	}

	if(oldRates.length !== newRates.length) {
		return rateEndDateUpdated;
	}

	if(oldRates.filter(r => r.Active).length !== newRates.filter(r => r.Active).length) {
		return rateEndDateUpdated;
	}

	return true;
}

/**
 * Check if any of the COF and UU margins total to 0% and show a message if true
 */
export async function checkMarginsForZeroes(values, name) {

	const uuRequired = parseInt(values.FinancialInstrumentConditionsID) !== FacilityConditions.InterestAccruingOnDrawnPortionsOnly;

	var cofTradingMargins = getCurrentRate(values.CostofFinanceTradingMargins);
	var cofFunderMargins = getCurrentRate(values.CostofFinanceFundersMargins);
	var uuFeeFunderMargins = getCurrentRate(values.UnderutilisationFundersMargins);
	var uuFeeTradingMargins = getCurrentRate(values.UnderutilisationTradingMargins);
	var fixedBaseRate = getCurrentRate(values.BaseInterestRates);

	var showMessage = (cofTradingMargins === 0.0 || cofFunderMargins === 0.0 ||
						(uuRequired && (uuFeeFunderMargins === 0.0 || uuFeeTradingMargins === 0.0)) ||
						(fixedBaseRate === 0.0 && values.UseFixedBaseRate));

	if(showMessage) {
		var errorMessage = 	`<div>
								<p>You are about to create a new ${name} but the following fields are currently set to 0%</p>
								<p>
								${ ((fixedBaseRate === 0.0 && values.UseFixedBaseRate) ? "<div> - Fixed Base Rate of Interest</div>" : "") }
								${ ((cofTradingMargins === 0.0) ? "<div> - Cost of Finance: Trading Margin</div>" : "") }
								${ ((cofFunderMargins === 0.0) ? "<div> - Cost of Finance: Funders Margin</div>" : "") }
								${ ((uuFeeFunderMargins === 0.0 && uuRequired)? "<div> - Underutilisation: Funders Margin</div>" : "") }
								${ ((uuFeeTradingMargins === 0.0 && uuRequired) ? "<div> - Underutilisation: Trading Margin</div>" : "") }
								</p>
								<p>Click OK to save the new ${name} anyway or Cancel to go back</p>
							</div>`

		let parser = new DOMParser();
		let content = parser.parseFromString(errorMessage, "text/html").body.firstChild;
		
		return swal({ 
			icon: "warning", 
			title: "Fields are currently zero", 
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
					visible: true,
					closeModal: true,
			},
				confirm: {
					text: "OK",
					value: true,
					visible: true,
					closeModal: true
				}
			},
			content: { element: content }, 
			closeOnClickOutside:false
		});
	}

	return true;
}

/**
 * Will return whether or not a field should be enabled or disabled, this will be a result of the combination of the facilities status
 * as well as the current role of the user
 */
export function isFieldEnabled(allowedRoles, allowedStates, statusId, override, session) {	
	const currentRole = (session.Role === UserRoles.Admin ? UserRoles.Admin : session.AuthState.UserAppStructure.GroupAdminLevel ? UserRoles.GroupAdmin : UserRoles.User);
	if (statusId === 0) return true;

	var roleAllowed = ((allowedRoles.length === 0) || (allowedRoles.findIndex(function (v) { return v === currentRole }) !== -1));
	var statusAllowed = allowedStates.findIndex(function (v) { return v === statusId }) !== -1;

	if(currentRole === UserRoles.User && allowedRoles.includes(UserRoles.UserWithControl)) {
		roleAllowed = hasControl(override, session);
	}

	return roleAllowed && statusAllowed;
}