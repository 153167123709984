import { Col, Row } from "react-bootstrap";
import Panel from "../../../../Core/Panel";
import InputField from "../../../../Core/Forms/InputField";
import SelectField from "../../../../Core/Forms/SelectField";
import CheckboxField from "../../../../Core/Forms/CheckboxField";
import { useFormikContext } from "formik";
import FundInvestorsModal from "./Investors/FundInvestorsModal";
import FundInvestorContributionsModal from "./Investors/FundInvestorContributionsModal";
import { useEffect, useState } from "react";
import NumberField from "../../../../Core/Forms/NumberField";
import { FinancialInstrumentStatuses, FundTypes } from "../../../../../Utilities/Enums";
import { UserRoles } from "../../../../../Utilities/Enums";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import { getCurrentProviderTotal, isFieldEnabled } from "../../../../../Utilities/FinancialInstruments";
import { Controls } from "../../../../../Utilities/Controls";
import FinancialInstrumentProviderField from "../../../Common/FinancialInstruments/FinancialInstrumentProviderField";
import FundInvestorBankAccountsModal from "./Investors/FundInvestorBankAccountsModal";

export default function FundDetails(props) {
	const formikContext = useFormikContext();
	const store = useSessionStore();
	const view = formikContext.values.View;
	const fundAllocation = formikContext.values.FundAllocation;

	const [showInvestorsModal, setShowInvestorsModal] = useState(false);
	const [showInvestorContributionsModal, setShowInvestorContributionsModal] = useState(false);
	const [showInvestorBankAccountsModal, setShowInvestorBankAccountsModal] = useState(false);
	const [activeInvestorIndex, setActiveInvestorIndex] = useState(null);

	const allWhileActive = !isFieldEnabled([], [FinancialInstrumentStatuses.Active, FinancialInstrumentStatuses.Pending], 
											formikContext.values.FundStatusId, Controls.FundOverride, store.Session);
	
	const adminWhileActive = !isFieldEnabled([UserRoles.Admin], 
												[FinancialInstrumentStatuses.Active, FinancialInstrumentStatuses.Pending], 
												formikContext.values.FundStatusId, Controls.FundOverride, store.Session);
	
	const nonUserWhileActive = !isFieldEnabled([UserRoles.Admin, UserRoles.GroupAdmin,UserRoles.UserWithControl], 
												[FinancialInstrumentStatuses.Active, FinancialInstrumentStatuses.Pending],
												formikContext.values.FundStatusId, Controls.FundOverride, store.Session);
	
	const nonUserWhileExpired = !isFieldEnabled([UserRoles.Admin, UserRoles.GroupAdmin,UserRoles.UserWithControl], 
												[FinancialInstrumentStatuses.Active, FinancialInstrumentStatuses.Pending, FinancialInstrumentStatuses.Expired],
												formikContext.values.FundStatusId, Controls.FundOverride, store.Session);

	function handleProviderContributionsClicked(index) {
		setActiveInvestorIndex(index);
		setShowInvestorsModal(false);
		setShowInvestorContributionsModal(true);
	}

	function handleProviderBankAccountsClicked(index) {
		setActiveInvestorIndex(index);
		setShowInvestorsModal(false);
		setShowInvestorBankAccountsModal(true);
	}

	function handleInvestorsClicked() {
		setShowInvestorsModal(true)
	}

	function handleInvestorsModalClosed() {
		setShowInvestorsModal(false);
	}

	function getFundersLimit() {
		//set the fund limit
		var limit = 0;

		for(var i = 0; i < formikContext.values.LinkedProviders.length; i++) {
			if(formikContext.values.LinkedProviders[i].Active) {
				limit += getCurrentProviderTotal(formikContext.values.LinkedProviders[i]);
			}
		}

		return limit;
	}

	function getFundContacts(contactTypeName) {
		if(props.fundContacts) {
			return props.fundContacts.filter(c => c.ContactTypeName === contactTypeName)
		}

		return [];
	}

	function handleFundAdminChanged() {
		formikContext.setFieldValue("BankAccounts", [], true);
		return false;
	}

	function handleFundTypeChanged(event) {
		let fundType = parseInt(event.target.value);

		if(fundType === FundTypes.SingleAsset) {
			formikContext.setFieldValue("FundAllocation", 100, true);
		}

		return false;
	}

	useEffect(() => {
		if(fundAllocation > 100) {
			formikContext.setFieldValue("FundAllocation", 100, true);
		}
	}, [fundAllocation]);
	

	return 	<Panel title="Fund Details">
				<Row>
					<Col>
						<InputField view={ view } disabled={ adminWhileActive } required fast name="Number" label="Number" className="mb-50"></InputField>
					</Col>
					<Col>
						<InputField view={ view } disabled={ adminWhileActive } required fast name="ExternalAccountReference" label="External Account Ref." className="mb-50"></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputField view={ view } disabled={ adminWhileActive } required fast name="Name" label="Name" className="mb-50"></InputField>
					</Col>
					<Col>
						<SelectField onChange={ handleFundTypeChanged } view={ view } required disabled={ nonUserWhileActive } name="FundTypeId" label="Type" className="mb-50">
							<option value="">SELECT</option>							
							<option value="1">Single Asset</option>
						</SelectField>
					</Col>
				</Row>
				<Row>
					<Col>
						<FinancialInstrumentProviderField 
												view={ view } className="mb-50" label="Fund Amount" onClick={ handleInvestorsClicked } 
												currency={ props.currency } limit={ getFundersLimit() } 
												disabled={ allWhileActive || (view && !allWhileActive) }>
						</FinancialInstrumentProviderField>
					</Col>
					<Col>
						<SelectField view={ view } required fast disabled={ adminWhileActive }  name="AssetDescriptionId" label="Asset Description" className="mb-50">
							<option value="">SELECT</option>
							<option value="1">Alternative Investment Fund</option>
							<option value="2">Cash Fund</option>
							<option value="3">Equity Fund</option>
							<option value="4">Fixed Interest / Bond Fund</option>
							<option value="5">Mortgage Fund</option>
							<option value="6">Property Fund</option>
						</SelectField>
					</Col>
				</Row>
				<Row>
					<Col>
						<SelectField view={ view } disabled={ adminWhileActive }  required fast name="FinancialInstrumentConditionsID" label="Conditions" className="mb-50">
							<option value="">SELECT</option>
							<option value="1">Interest Accruing on Drawn Portions Only</option>
							<option value="2">Interest Accruing on Fund Limit</option>
						</SelectField>
					</Col>
					<Col>
						<SelectField view={ view } required fast disabled={ adminWhileActive } name="FinancialTermId" label="Cost of Finance Terms" className="mb-50">
							<option value="">SELECT</option>
							{	
								props.financialTerms.map((term) => 
									<option key={term.Id} value={term.Id}>{term.Name}</option>)
							} 
						</SelectField>
					</Col>
				</Row>
				<Row>
					<Col>
						<SelectField view={ view } required fast disabled={ adminWhileActive }  name="FinancialInstrumentTermConditionsID" label="Term Conditions" className="mb-50">
							<option value="">SELECT</option>
							<option value="6">Revolving and Renewable Annually</option>
							<option value="11">Revolving and Renewable on Termination</option>
							<option value="12">Non-Revolving</option>
						</SelectField>
					</Col>
					<Col>
						<NumberField view={ view } required disabled={ adminWhileActive } name="FundAllocation" suffix="%" label="Fund Allocation" className="mb-50"></NumberField>
					</Col>
				</Row>
				<Row>
					<Col>
						<SelectField view={ view } required fast disabled={ adminWhileActive }  name="InterestCalculation" label="Interest Calculation" className="mb-50">
							<option value="">SELECT</option>
							<option value="Daily">Daily</option>
						</SelectField>
					</Col>
					<Col>
						<SelectField view={ view } required fast disabled={ nonUserWhileExpired } name="FinancialInstrumentTermID" label="Term" className="mb-50">
							<option value="">SELECT</option>
							<option value="1">12 Months</option>
							<option value="2">24 Months</option>
							<option value="3">36 Months</option>
							<option value="4">48 Months</option>
							<option value="5">60 Months</option>
						</SelectField>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputField view={ view } required fast disabled={ adminWhileActive }  name="ProjectedFundingDays" label="Projected Funding Days" type="number" className="mb-50"></InputField>
					</Col>
					<Col>
						<SelectField view={ view } required name="CurrencyID" disabled={ adminWhileActive } label="Currency" className="mb-50">
							<option value="0">SELECT</option>
							{
								props.currencies && props.currencies.map(c =>
									<option key={ c.Id } value={c.Id}>{c.Abbreviation}</option>)
							}
						</SelectField>
					</Col>
				</Row>
				<Row>
					<Col xs={6}>
						<InputField className="mb-50" view={ view } disabled={ adminWhileActive }  required={ formikContext.values.InterestCalculation === "Daily" } fast name="YearDays" label="Year Days" type="number"></InputField>
					</Col>
					<Col >
						<CheckboxField col={6} view={ view } disabled={ allWhileActive } name="IntercreditorRequired" label="Intercreditor Required"></CheckboxField>
					</Col>
					<Col xs={2}>
						<CheckboxField align="justify-content-end" col={4} view={ view } disabled={ allWhileActive } name="EquityRequired" label="Equity Required"></CheckboxField>
					</Col>
				</Row>
				<Row>
					<Col>
						<SelectField onChange={ handleFundAdminChanged } view={ view } required name="FundManagerId" disabled={ adminWhileActive } label="Fund Manager" className="mb-50">
							<option value="">SELECT</option>
							{
								getFundContacts("Fund Manager").map(c =>
								<option key={ c.Id } value={c.Id}>{c.Name}</option>)
							}
						</SelectField>
					</Col>
					<Col>
						<SelectField view={ view } required name="AdministratorId" disabled={ adminWhileActive } label="Administrator" className="mb-50">
							<option value="">SELECT</option>
							{
								getFundContacts("Administrator").map(c =>
								<option key={ c.Id } value={c.Id}>{c.Name}</option>)
							}
						</SelectField>
					</Col>
				</Row>
				<FundInvestorsModal onProviderContributionsClicked={ handleProviderContributionsClicked }
									onProviderBankAccountsClicked={ handleProviderBankAccountsClicked }
									currency={ props.currency }
									linkedInvestors={ formikContext.values.LinkedProviders } 
									onClose={ e => handleInvestorsModalClosed() } 
									show={showInvestorsModal} disabled={ allWhileActive || (view && !allWhileActive) }>
				</FundInvestorsModal>
				<FundInvestorContributionsModal 	show={ showInvestorContributionsModal } investorIndex={ activeInvestorIndex }
													investor={ (activeInvestorIndex !== null ? formikContext.values.LinkedProviders[activeInvestorIndex] : null) }
													currency={ props.currency }
													onClose={ e => { setShowInvestorContributionsModal(false); setShowInvestorsModal(true);} }
													disabled={ allWhileActive || (view && !allWhileActive) }>
				</FundInvestorContributionsModal>
				<FundInvestorBankAccountsModal 	show={ showInvestorBankAccountsModal } investorIndex={ activeInvestorIndex }
													investor={ (activeInvestorIndex !== null ? formikContext.values.LinkedProviders[activeInvestorIndex] : null) }
													onClose={ e => { setShowInvestorBankAccountsModal(false); setShowInvestorsModal(true);} }
													disabled={ allWhileActive || (view && !allWhileActive) }>
				</FundInvestorBankAccountsModal>
			</Panel>
}