import { Col, Row } from "react-bootstrap";
import { FacilityConditions, FinancialInstrumentStatuses, UserRoles } from "../../../../Utilities/Enums";
import { RateHistoryTypes } from "../../../../Utilities/Enums";
import CheckboxField from "../../../Core/Forms/CheckboxField";
import DateField from "../../../Core/Forms/DateField";
import NumberField from "../../../Core/Forms/NumberField";
import SelectField from "../../../Core/Forms/SelectField";
import Panel from "../../../Core/Panel";
import RateHistoryField from "./RateHistories/RateHistoryField";
import { useSessionStore } from "../../../../Stores/SessionStore";
import RateValue from "./RateHistories/RateValue";
import { getCurrentRate, getTotalMargins, isFieldEnabled } from "../../../../Utilities/FinancialInstruments";

export default function FeesAndCosts({ values, statusId, override, permissions, financialTerms, currency }) {
	const store = useSessionStore();
	const disabled = !isFieldEnabled([UserRoles.Admin, UserRoles.GroupAdmin,UserRoles.UserWithControl], 
									[FinancialInstrumentStatuses.Active, FinancialInstrumentStatuses.Pending], statusId, override, store.Session);
	
	const ratesDisabled = !isFieldEnabled([], [FinancialInstrumentStatuses.Active, FinancialInstrumentStatuses.Pending], 
											statusId, override, store.Session);
	
	const view = values.View;

	function getIntervalSelect(name) {
		return <SelectField view={ view } disabled={ disabled } fast name={name}>
					<option value="">SELECT</option>
					<option value="1">Per Issuance</option>
					<option value="2">On Commencement</option>
					<option value="3">Monthly</option>
					<option value="4">Per Annum</option>
					<option value="5">Quarterly</option>
				</SelectField>
	}

	function getTotalCostOfFinance() {
		return getTotalMargins(values.CostofFinanceFundersMargins, values.CostofFinanceTradingMargins,
								values.UseFixedBaseRate, values.BaseInterestRates, financialTerms, parseInt(values.FinancialTermId));
	}

	function getTotalUnderutilisation() {
		return getTotalMargins(values.UnderutilisationFundersMargins, values.UnderutilisationTradingMargins,
			values.UseFixedBaseRate, values.BaseInterestRates, financialTerms, parseInt(values.FinancialTermId));
	}
	
	return <Panel  title="Fees and Costs">
				<Row className="mb-50 align-items-center">
					<Col xs={2}><CheckboxField col={2} label="Use Fixed Base Rate of Interest" name="UseFixedBaseRate"></CheckboxField></Col>
					<Col xs={4}><RateHistoryField permissions={permissions} view={ view } disabled={ ratesDisabled || (view && !ratesDisabled) } rateType={ RateHistoryTypes.BaseInterestRate } col={6} title="Base Rate of Interest" label="Base Rate of Interest" fieldName="BaseInterestRates" rates={ values.BaseInterestRates }></RateHistoryField></Col>
					
					<Col xs={6}><DateField view={ view }  required={ values.UseFixedBaseRate } label="Base Rate End Date" name="FixedBaseRateExpiryDate"></DateField></Col>
				</Row>
				<Row className="mb-50 align-items-center">
					<Col xs={6}>
						<RateValue view={ view } value={ getTotalCostOfFinance() } label="Cost of Finance - Base Rate Of Interest / Funders Margin / Trading Margin"></RateValue>
					</Col>
					<Col xs={2}>
						<RateValue view={ view } value={(values.UseFixedBaseRate ? getCurrentRate(values.BaseInterestRates) : 0)}></RateValue>
					</Col>
					<Col xs={2}><RateHistoryField permissions={permissions} view={ view } disabled={ ratesDisabled || (view && !ratesDisabled) } rateType={ RateHistoryTypes.CostOfFinanceFundersMargin } title="Cost of Finance - Funders Margin" fieldName="CostofFinanceFundersMargins" rates={ values.CostofFinanceFundersMargins }/></Col>
					<Col xs={2}><RateHistoryField permissions={permissions} view={ view } disabled={ ratesDisabled || (view && !ratesDisabled) } rateType={ RateHistoryTypes.CostOfFinanceTradingMargin } title="Cost of Finance - Trading Margin" fieldName="CostofFinanceTradingMargins" rates={ values.CostofFinanceTradingMargins }/></Col>
				</Row>
				<Row className="mb-50 align-items-center">
					<Col xs={6}>
						<RateValue view={ view } value={ getTotalUnderutilisation() } label="Underutilisation - Base Rate Of Interest / Funders Margin / Trading Margin"></RateValue>
					</Col>
					<Col xs={2}>
						<RateValue view={ view } value={(values.UseFixedBaseRate ? getCurrentRate(values.BaseInterestRates) : 0)}></RateValue>
					</Col>
					<Col xs={2}>
						<RateHistoryField permissions={permissions} view={ view } rateType={ RateHistoryTypes.UnderutilisationFeeFunderMargin } title="Underutilisation - Funders Margin" fieldName="UnderutilisationFundersMargins"
											disabled={ ratesDisabled || (view && !ratesDisabled) || parseInt(values.FinancialInstrumentConditionsID) === FacilityConditions.InterestAccruingOnDrawnPortionsOnly } rates={ values.UnderutilisationFundersMargins }/>
					</Col>
					<Col xs={2}>
						<RateHistoryField permissions={permissions} view={ view } rateType={ RateHistoryTypes.UnderutilisationFeeTradingMargin } title="Underutilisation - Trading Margin" fieldName="UnderutilisationTradingMargins"
											disabled={ ratesDisabled || (view && !ratesDisabled) || parseInt(values.FinancialInstrumentConditionsID) === FacilityConditions.InterestAccruingOnDrawnPortionsOnly } rates={ values.UnderutilisationTradingMargins }/>
					</Col>
				</Row>
				<Row className="mb-50">
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} suffix="%" name="FacilityFee" label="Facility Fee"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FinancialInstrumentFeeFrequencies.FacilityFeeFrequency") }</Col>
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} suffix="%" name="StructureFee" label="Structuring Fee"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FinancialInstrumentFeeFrequencies.StructuringFeeFrequency") }</Col>
				</Row>
				<Row className="mb-50">
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} suffix="%" name="RenewalFee" label="Renewal Fee"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FinancialInstrumentFeeFrequencies.RenewalFeeFrequency") }</Col>
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} prefix={ currency } name="InsuranceCost" label="Insurance Cost"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FinancialInstrumentFeeFrequencies.InsuranceCostFrequency") }</Col>
				</Row>
				<Row className="mb-50">
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} suffix="%" name="ServiceFee" label="Service Fee"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FinancialInstrumentFeeFrequencies.ServiceFeeFrequency") }</Col>
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} prefix={ currency } name="LegalCost" label="Legal Cost"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FinancialInstrumentFeeFrequencies.LegalCostFrequency") }</Col>
				</Row>
				<Row className="mb-50">
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} suffix="%" name="PlatformFee" label="Platform Fee"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FinancialInstrumentFeeFrequencies.PlatformFeeFrequency") }</Col>
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} prefix={ currency } name="IssuingCost" label="Issuing Cost"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FinancialInstrumentFeeFrequencies.IssuingCostFrequency") }</Col>
				</Row>
				<Row className="mb-50">
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} suffix="%" name="TradingFee" label="Trading Fee"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FinancialInstrumentFeeFrequencies.TradingFeeFrequency") }</Col>
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} prefix={ currency } name="CollateralManagementCost" label="Collateral Management Cost"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FinancialInstrumentFeeFrequencies.CollateralManagementCostFrequency") }</Col>
				</Row>
				<Row className="mb-50">
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} suffix="%" name="OtherFees" label="Other Fees"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FinancialInstrumentFeeFrequencies.OtherFeeFrequency") }</Col>
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} prefix={ currency } name="DocumentFee" label="Document Fee"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FinancialInstrumentFeeFrequencies.DocumentFeeFrequency") }</Col>
				</Row>
				<Row className="d-flex align-items-center mb-50">
					<Col xs={2}><label className="control-label">Trading Discount Split</label></Col>
					<Col xs={4}>
						<Row>
							<Col xs={5}><NumberField view={ view } disabled={ disabled } required fast name="TradingDiscountSplitNumerator" suffix="%"></NumberField></Col>
							<Col xs={2} className="text-center"><label>:</label></Col>
							<Col xs={5}><NumberField view={ view } disabled={ disabled } required fast name="TradingDiscountSplitDenominator" suffix="%"></NumberField></Col>
						</Row>
					</Col>
					<Col xs={4}><NumberField view={ view } disabled={ disabled } required fast col={6} prefix={ currency } name="OtherCosts" label="Other Costs"></NumberField></Col>
					<Col xs={2}>{ getIntervalSelect("FinancialInstrumentFeeFrequencies.OtherCostsFrequency") }</Col>
				</Row>
				<Row>
					<Col><NumberField view={ view } disabled={ disabled } fast name="Factor" label="Factor"></NumberField></Col>
					<Col><CheckboxField disabled={ disabled } label="Linked Trading" name="LinkedTrading"></CheckboxField> </Col>
				</Row>
			</Panel>
}