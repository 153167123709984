import { faUsers } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { useSessionStore } from "../../../Stores/SessionStore";
import { getAuditType } from "../../../Utilities/Audits";
import BackButton from "../../Core/BackButton";
import BusyIndicator from "../../Core/BusyIndicator";
import DateValue from "../../Core/DateValue";
import PageHeader from "../../Core/PageHeader";
import Pagination from "../../Core/Pagination";
import Panel from "../../Core/Panel";
import AuditLogsIndexFilters from "./AuditLogsIndexFilters";
import { observer } from "mobx-react-lite";
import UseDocumentTitle from "../../../Utilities/UserDocumentTitle";

const AuditLogsIndex = observer( function AuditLogsIndex() {
	UseDocumentTitle('Listing \'Audit\' on CREST');

	const store = useSessionStore();
	const params = useParams();
	
	useEffect(() => {
		if(params.entityId !== store.AuditStore.table) {
			store.AuditStore.ClearStoredFilters()
		}

		store.AuditStore.GetAudits(params.table, params.entityId);		
	}, []);
	
	function handlePageChanged(pageIndex, pageCount) {
		store.AuditStore.SetFilter({...store.AuditStore.Filter, PageIndex : pageIndex, PageCount :pageCount });
		store.AuditStore.GetAudits(params.table,params.entityId);
	}

	return  <React.Fragment>
				<PageHeader helpItemDescriptor={ "Audit/Index" }  icon={ faUsers } title={ store.AuditStore.RetrievingAudits ? "Audits":`Audit ${store.AuditStore.DisplayName} ${store.AuditStore.Audits ? `(${store.AuditStore.TotalAudits} found)` : ""}` } crumbs={[{ Title: "Audit" }, { Title: "List" }]}>
					<BackButton table={params.table}></BackButton>
				</PageHeader>
				<Panel>
					<AuditLogsIndexFilters 
						search={ store.AuditStore.Search } 
						type={ store.AuditStore.type } 
						fromDate={ store.AuditStore.startDate} 
						toDate={ store.AuditStore.endDate }
						onFilter={ e =>  store.AuditStore.GetAudits(params.table,params.entityId,true) } 
						disabled={ store.AuditStore.Audits === null }>
					</AuditLogsIndexFilters>
					{
						
						<React.Fragment>
							<Table bordered responsive>
								<thead>
									<tr>
										<th>Date & Time</th>
										<th>User</th>
										<th>Table</th>
										<th>Type</th>
										<th className="text-center">Actions</th>
									</tr>
								</thead>
								<tbody>
									{   !store.AuditStore.RetrievingAudits &&
										store.AuditStore.TotalAudits > 0 &&
										store.AuditStore.Audits.map((audit, index) =>
											<tr key={ index }>
												<td> <DateValue date={ audit.Date } outputFormat="dd/MM/yyyy HH:mm"></DateValue> </td>
												<td>{ audit.User }</td>
												<td>{ audit.Table }</td>
												<td className={`${ audit.Type === "I" ? "bg-primary" : "bg-success"} text-white`}>{ getAuditType(audit.Type) }</td>
												<td className="text-center"><NavLink to={`/Audits/${audit.Id}`}>View</NavLink></td>
											</tr>
										)
									}
								</tbody>
							</Table>
							{
								store.AuditStore.TotalAudits > 0 &&
								<Pagination onPageChanged={ handlePageChanged } totalResults={ store.AuditStore.Audits.length } filter={store.AuditStore.Filter}></Pagination>
							}
						</React.Fragment>
					}
					<BusyIndicator size="2x" show={ store.AuditStore.RetrievingAudits }></BusyIndicator>
				</Panel>
			</React.Fragment>
})

export default AuditLogsIndex