import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";

export default function FundsIndexFilter(props){

    const store = useSessionStore()

    const [searchFilter, setSearchFilter] = useState({ Search: "", FacilityId: "", PageIndex: 0, PageCount: 10 });
    const [facilities, setFacilities] = useState(null);
    const [selectedFacility, setSelectedFacility] = useState(null);

    useEffect(() =>{
        getFacilities();
    }, [])

    async function getFacilities(){
        let results = await store.FacilitiesService.GetFacilities(0, 0, "", 0, 0, 0);

        if(results && results.Success){
            setFacilities(results.Data);
        }
    }

    function handleSearch(e){
        setSearchFilter({...searchFilter, Search: e.target.value});
    }

    function handleFacilityChange(e){
        setSelectedFacility(e.target.value);
        setSearchFilter({...searchFilter, FacilityId: e.target.value});
    }

    function handleFilterClick(){
        props.handleSearch(searchFilter);
    }


    return <React.Fragment>
        <Form className="mb-50">
            <h6>Filter Funds</h6>
            <Row>
                <Col>
                    <Form.Group as={ Row }>
                        <Col lg={4}>
                            <Form.Control type="search" placeholder="search..." onChange={ handleSearch } />
                        </Col>
                        <Col className="d-flex align-items-center">
                            <Col lg={1}>
                                <Form.Label className="text-end mb-0">Facility</Form.Label>
                            </Col>
                            <Col lg={5}>
                            <Form.Select value={ selectedFacility } onChange={ handleFacilityChange }>
                                <option value=""> SELECT </option>
                                {
                                    facilities &&
                                    facilities.length > 0 &&
                                    facilities.map( (facility) =>
                                        <option key={ facility.Id } value={ facility.Id }>{ `${ facility.Number } - ${ facility.Name }` }</option>
                                    )
                                }
                            </Form.Select>
                            </Col>
                        </Col>
                        <Col lg={1} className="text-end">
                            <Button onClick={ handleFilterClick }>Filter</Button>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    </React.Fragment>
}