import { StyleSheet} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
	page: {
		flexDirection: 'row',
		backgroundColor: 'white',
		color: "#333",
		fontFamily: "Roboto",
		paddingTop: 15,
		paddingBottom: 15
	},
	section: {
		margin: 1,
		padding: 5,
		flexGrow: 1
	},
	table:{
		border: 1,
		borderColor: '#e3e3e3',
		borderRadius: 2,
		padding: 10
	},
	Title:{
		alignSelf:'center',
		fontSize: 10,
		fontFamily: 'Roboto',
		color: "#333",
		fontWeight: "bold",
		marginBottom: 5
	},
	boldFont:{
		fontWeight: "bold",
	},
	normalFont:{
		fontWeight: "normal",
	},
	tableRowWrapping:{
		flexDirection: 'row',
	},
	paymentInstructionDetail:{
		width: '25%',
		padding: 1,
		fontSize: 7,
		fontFamily: "Roboto",
		textAlign: 'left'
	},
	funderDetails:{
		width: '50%',
		padding: 1.5,
		fontSize: 7,
		textAlign: 'left'
	},
	groupBankDetails:{
		width: '25%',
		padding: 1,
		fontSize: 7,
		textAlign: 'left'
	},
	detailsOfCustomerTransactions:{
		width: '25%',
		padding: 1,
		fontSize: 7,
		textAlign: 'left'
	},
	detailsOfVendorTransactions:{
		width: '25%',
		padding: 1,
		fontSize: 7,
		textAlign: 'left'
	},
		textHeaders:{
		paddingTop: 1.5,
		paddingBottom: 1.5,
		fontSize: 7,
		borderBottom: 0.5,
		borderBottomColor: 'grey',
		fontFamily: 'Roboto',
		fontWeight: 700
	},
		textData:{
		width: '35%',
		paddingTop: 3,
		paddingBottom: 3,
		fontSize: 7,
		borderBottom: 0.5,
		borderBottomColor: 'lightgrey'
	},
		textFooters:{
		width: '35%',
		paddingTop: 1.5,
		paddingBottom: 1.5,
		fontSize: 7,
		fontFamily: 'Roboto',
		fontWeight: 700
	},
		viewAuthorisation: {
		border: 1,
		borderColor: '#e3e3e3',
		borderRadius: 2,
		padding: 5,
		fontSize: 7
	},
		textUnderline: {
		textDecoration: 'underline',
		fontSize: 7,
		paddingBottom: 7,
		fontFamily: 'Roboto',
		fontWeight: 700
	},
		fieldText:{
		fontFamily: 'Roboto',
		fontWeight: 700,
		padding: 1
	},
	viewTermsAndConditions: {
		border: 1,
		borderColor: '#e3e3e3',
		borderRadius: 2,
		padding: 10,
		fontSize: 7
	},
	detailsOfReimbursementTransactions:{
		width: '25%',
		padding: 1,
		fontSize: 7,
		textAlign: 'left'
	},
	facilityHeaders:{
		padding: 3,
		fontSize: 7,
		backgroundColor: '#2D94C7',
		color: '#fff'
	},
	facilityData:{
		padding: 3,
		fontSize: 7,
		textOverflow: "ellipsis",
		maxLines: 1
	},
	pageNumbers: {
		position: 'absolute',
		bottom: -10,
		left: 0,
		right: 0,
		textAlign: 'center',
		fontSize: 8,
		fontWeight: 700,
		padding: 3,
		paddingBottom: 6
	},
	pageDate: {
		position: 'absolute',
		bottom: -13,
		left: 0,
		right: 0,
		textAlign: 'center',
		fontSize: 8,
		padding: 3,
		paddingBottom: 6
	},
	textEllipsis: {
		overflow:'hidden',
		textOverflow:'ellipsis',
		maxLines:1
	}
});