import React from "react";
import { Document, Page, View, Text } from "@react-pdf/renderer";
import { DateTime } from "luxon";
import { styles } from "./StyleSheet";
import InterestHeader from "./Components/Interest/InterestHeader";
import InterestTransactions from "./Components/Interest/InterestTransactions";
import SingleFacility from "./Components/Interest/SingleFacility";
import InterestUnderutilisation from "./Components/Interest/InterestUnderutilsation";

export default function Interest(props){

    var dateTimeSamp = DateTime.now();

    return(
        <Document>
            <Page size="A4" style={[styles.page, {paddingBottom: 30}]}>
                <View style={styles.section}>
                    <InterestHeader header={ props.data.Header } facilities={ props.data.Facilities } state={ props.state } detailed={ props.detailed } />

                    {
                        props.data.Facilities.map( facility =>
                            <View style={[styles.table, {paddingBottom:25, marginBottom:10}]}>
                                <SingleFacility facility={ facility } detailed={ props.detailed } closingTotalDate={ props.closingTotalDate } />
                            </View>
                        )
                    }
                    

                    <View style={[styles.table, {paddingBottom:25, marginBottom:10}]} wrap={ false }>
                        <InterestUnderutilisation underutilisations={ props.data.Underutilisations } />
                    </View>

                    <View style={{ position: 'absolute', bottom: -25, alignSelf: 'center'}} render={({ pageNumber, totalPages }) => (
                        <View>
                            <Text style={{ fontSize: 7, fontFamily: 'Roboto', fontWeight: 700, paddingBottom: 3, left:'20%'}}>
                                Page {pageNumber} of {totalPages}
                            </Text>
                            <Text style={{ fontSize: 7}}>
                                {DateTime.fromISO(dateTimeSamp).toFormat("dd/MM/yyyy HH:mma")}
                            </Text>
                        </View>
                    )} fixed />
                </View>
            </Page>
        </Document>
    )

}