import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function RoundButton({ variant, onClick, busy, icon }) {
	var color = (variant && variant === "primary") ? "#fff" : "#999";

	return 	<span onClick={onClick} className="fa-layers fa-fw" style={{ fontSize: "27px",
														borderRadius: "100px",
														border: ((variant && variant === "primary") ? "" : "1px solid #999"),
														width: "27px",
														height: "27px",
														cursor: "pointer",
														backgroundColor: (variant && variant === "primary" ? "#38a7df" : "#fff") }}>
				<FontAwesomeIcon icon={ busy ? faSpinnerThird : icon } spin={ busy } color={ color } transform="shrink-7" />
  			</span>
}