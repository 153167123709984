import { Text, View } from "@react-pdf/renderer";
import { styles } from "../../StyleSheet";
import { formatCurrencyValue } from "../../../../Utilities/Currencies";

export default function FundBalances(props){

    return(
        <View  style={{paddingBottom:10}}>
            <Text style={[styles.boldFont, {fontSize:7, padding: 3}]}>Fund Balances</Text>
            <View style={styles.tableRowWrapping}>
                <Text style={[styles.facilityHeaders, {width: '22%', textAlign: 'left', borderRight:1, borderLeft:1, borderColor:'#e3e3e3'}]}>Number</Text>
                <Text style={[styles.facilityHeaders, {width: '14%', textAlign: 'left', borderRight:1, borderColor:'#e3e3e3'}]}>Name</Text>
				<Text style={[styles.facilityHeaders, {width: '15%', textAlign: 'right', borderRight:1, borderColor:'#e3e3e3'}]}>Fund Amount</Text>
				<Text style={[styles.facilityHeaders, {width: '15%', textAlign: 'right', borderRight:1, borderColor:'#e3e3e3'}]}>Running Bal.</Text>
				<Text style={[styles.facilityHeaders, {width: '17%', textAlign: 'right', borderRight:1, borderColor:'#e3e3e3'}]}>Actual Bal.</Text>
				<Text style={[styles.facilityHeaders, {width: '17%', textAlign: 'right', borderRight:1, borderColor:'#e3e3e3'}]}>Available Bal.</Text>
            </View>
            <View style={styles.tableRowWrapping}>
                <Text style={[styles.facilityData, {width: '22%', textAlign: 'left', borderRight:1, borderLeft:1, borderBottom:1, borderColor:'#e3e3e3'}, styles.textEllipsis]}>{ props.header.FundNumber }</Text>
                <Text style={[styles.facilityData, {width: '14%', textAlign: 'left', borderRight:1, borderBottom:1, borderColor:'#e3e3e3'}, styles.textEllipsis]}>{ props.header.FundName }</Text>
				<Text style={[styles.facilityData, {width: '15%', textAlign: 'right', borderRight:1, borderBottom:1, borderColor:'#e3e3e3'}]}>{ formatCurrencyValue(props.currency, props.fundBalances.FundAmount) }</Text>
				<Text style={[styles.facilityData, {width: '15%', textAlign: 'right', borderRight:1, borderBottom:1, borderColor:'#e3e3e3'}]}>{ formatCurrencyValue(props.currency, props.fundBalances.Balances.RunningTotal) }</Text>
				<Text style={[styles.facilityData, {width: '17%', textAlign: 'right', borderRight:1, borderBottom:1, borderColor:'#e3e3e3'}]}>{ formatCurrencyValue(props.currency, props.fundBalances.Balances.ActualTotal) }</Text>
				<Text style={[styles.facilityData, {width: '17%', textAlign: 'right', borderRight:1, borderBottom:1, borderColor:'#e3e3e3'}]}>{ formatCurrencyValue(props.currency, props.fundBalances.AvailableBalance) }</Text>
            </View>
        </View>
    )
}