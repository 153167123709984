import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import InputField from "../../../../Core/Forms/InputField";
import SelectField from "../../../../Core/Forms/SelectField";

export default function BankAccount({ index, view, onIsDefaultChanged, onDeleteClicked, prePopulatedBanks, disabled }) {	
	const formikContext = useFormikContext();
	
	let isChecked = formikContext.values.BankAccounts[index].IsDefault;

	function getAvailableInstitutions() {
		let banks = [];
		if(prePopulatedBanks) {
			let bankAccounts = formikContext.values.BankAccounts;

			for(let i = 0; i < prePopulatedBanks.length; i++) {
				let usedBank = bankAccounts.find(b => b.BankAccountId === prePopulatedBanks[i].Id);

				if(!usedBank || bankAccounts[index].BankAccountId === prePopulatedBanks[i].Id) {
					banks.push({ Id: prePopulatedBanks[i].Id, Value: prePopulatedBanks[i].BankInstitution });
				}
			}
		}

		return banks;
	}

	function handleInstitutionChanged(value) {
		let bank = prePopulatedBanks.find(b => b.Id === parseInt(value));

		if(bank) {
			let bankAccounts = formikContext.values.BankAccounts;
			
			bankAccounts[index].BankAccountId = bank.Id;
			bankAccounts[index].AccountHolder = bank.BankAccountHolder;
			bankAccounts[index].IsDefault = bank.IsDefault;

			formikContext.setValues({
				...formikContext.values,
				BankAccounts: bankAccounts
			}, true);
		}

		return true;
	}

	function getBankAccountField(fieldName) {
		let bankAccountId = formikContext.values.BankAccounts[index].BankAccountId;

		if(bankAccountId) {
			let bank = prePopulatedBanks.find(b => b.Id === parseInt(bankAccountId));

			if(bank) {
				return bank[fieldName];
			}
		}

		return "";
	}
	
	return 	<Row className={ `pb-1${(index > 0 ? " mt-2" : "")}` }>
				<Col>
					<Row>
						<Col>
							<InputField disabled={disabled} invalid={ !formikContext.values.BankAccounts[index].AccountHolder } view={view} className="mb-50" required label="Account Holder" name={ `BankAccounts[${index}].AccountHolder` }></InputField>
						</Col>
						<Col>
							<SelectField disabled={disabled} view={view} invalid={ !formikContext.values.BankAccounts[index].BankAccountId } label="Institution" value={ formikContext.values.BankAccounts[index].BankAccountId ?? "" }  required onChange={ e => handleInstitutionChanged(e.target.value) }>
								<option value="">-- SELECT --</option>
								{
									getAvailableInstitutions().map(bank => <option key={bank.Id} value={bank.Id}>{bank.Value}</option>)
								}
							</SelectField>
						</Col>
					</Row>
					<Row>
						<Col>
							<InputField disabled view={view} className="mb-50" label="Account Number" value={ getBankAccountField("BankAccountNumber") }></InputField>
						</Col>
						<Col>
							<InputField disabled view={view} className="mb-50" label="Account Type" value={ getBankAccountField("BankAccountType") }></InputField>
						</Col>
					</Row>
					<Row>
						<Col>
							<InputField disabled view={view} className="mb-50" label="Branch Name" value={ getBankAccountField("BranchName") }></InputField>
						</Col>
						<Col>
							<InputField disabled view={view} className="mb-50" label="Branch Code" value={ getBankAccountField("BranchCode") }></InputField>
						</Col>
					</Row>
					<Row>
						<Col>
							<InputField disabled view={view} className="mb-50" label="IBAN Number" value={ getBankAccountField("IBANNumber") }></InputField>
						</Col>
						<Col>
							<InputField disabled view={view} className="mb-50" label="Swift Code" value={ getBankAccountField("SwiftCode") }></InputField>
						</Col>
					</Row>
					<Row style={{paddingTop: 9}}>
						<Col xs={2}>
							<label className="d-flex mb-50"> Set as Default </label>
						</Col>
						<Col xs={5}>
							<input disabled={disabled || view } type="checkbox" onChange={ e => onIsDefaultChanged(e.target.checked) } checked={ isChecked } className="mb-50 me-1"></input>
						</Col>
					</Row>
					<Row>
						<Col>
							<Row>
								<Col className="d-flex justify-content-end mt-50 fw-bold">
									{
										!view &&
										<Button variant="light" disabled={disabled}  onClick={onDeleteClicked}>
											<FontAwesomeIcon icon={ faClose }></FontAwesomeIcon>
										</Button>
									}
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
}