import { faUsers } from "@fortawesome/pro-regular-svg-icons";
import { Formik, Form as FormikForm } from "formik";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import BusyIndicator from "../../../../Core/BusyIndicator";
import PageHeader from "../../../../Core/PageHeader";
import Preferences from "./Details/Preferences";
import SecurityInformation from "./Details/SecurityInformation";
import Systems from "./Details/Systems";
import UserProfileInformation from "./Details/UserProfileInformation";
import * as yup from 'yup';
import swal from "sweetalert"
import { Button, Col, Row } from "react-bootstrap";
import AuditLogEntitySummary from "../../../AuditLogs/AuditLogEntitySummary";
import { Roles } from "../../../../../Utilities/Permissions";
import UseDocumentTitle from "../../../../../Utilities/UserDocumentTitle";

export default function UserDetails(props) {

	const params = useParams();
	const store = useSessionStore();
	const navigate = useNavigate();

	const [user, setUser] = useState(null);
	const [saving, setSaving] = useState(false);
	const [userName, setUserName] = useState('');
	const [userSurname, setUserSurname] = useState('');

	async function getUser() {
		if(params.userId) {
			let result = await store.UsersService.GetUser(params.userId);
			setUserName(result.Data.Name);
			setUserSurname(result.Data.Surname);

			if(result && result.Success) {
				setUser(result.Data);
			}
		}
	}

	UseDocumentTitle('Editing \''+ userName + ' ' + userSurname +'\'');
	
	useEffect(() => {
		if(props.add) {
			setUser({
				Name: "",
				Surname: "",
				EmailAddress: "",
				PhoneNumber: "",
				CellphoneNumber: "",
				Username: "",
				Password: "",
				RoleId: "",
				ShowAllMenu: false,
				PreferredLanguageId: "",
				PreferredCurrencyId: "",
				SystemLogo: "",
				Active: true,
				UserTypeId: null,
				AssociatedSystems: []
			})
		} else {
			getUser();
		}
	}, []);

	async function handleSaveClicked(values) {
		setSaving(true);
		let result = null;

		if(props.add) {
			result = await store.UsersService.CreateUser(values);
			//store.SetProfilePic(values.ProfilePictureBase64,values.ProfilePictureMIMEType)
		} else {
				result = await store.UsersService.UpdateUser(values);
				if(store.Session.UserId === params.userId){
					store.SetProfileName(values.Name,values.Surname)
					store.SetProfilePic(values.ProfilePictureBase64,values.ProfilePictureMIMEType)
				}
			
		}

		setSaving(false);

		if(result && result.Success) {
			swal({ icon: "success", title: "User Saved", text: "User Saved Successfully!", closeOnClickOutside: false });
			navigate(-1);

		} else {
			swal({ icon: "error", title: "Something went wrong", text: `An error occurred creating the new user: Email '${values.EmailAddress}' is already taken.`  , closeOnClickOutside: false });
		}
	}

	const validationSchema = yup.object({
		Name: yup.mixed().required(),
		Surname: yup.mixed().required(),
		EmailAddress: yup.string().email().required(),
		PhoneNumber: yup.string().matches(/^\+\d{2} \(\d{3}\) \d{3}-\d{4}$/, 'Phone number is not valid').required(),
		Username: yup.mixed().required(),
		RoleId: yup.mixed().required(),
		PreferredLanguageId: yup.mixed().required(),
		PreferredCurrencyId: yup.mixed().required(),
		Password: (user && !user.Id ? yup.mixed().required() : null),
		UserTypeId: yup.mixed().when("RoleId", { is: "2", then: yup.mixed().required() })
	}).required();

	return <React.Fragment>
				<PageHeader icon={ faUsers } title={ "Users" }  helpItemDescriptor={ "User/Edit" }
							crumbs={[{ Title: "Admin" }, { Title: "Users" }, { Active: true, Title: ( props.add ? "Adding new System User" : "Editing System User") }]}></PageHeader>
				{
					user && user.Id &&
					<AuditLogEntitySummary hideLog table="Users" entityid={ user.Id } auditsummary={user.AuditSummary}></AuditLogEntitySummary>
				}
				{
					user &&
					<Formik initialValues={user} enableReinitialize validateOnMount validationSchema={ validationSchema } onSubmit={ handleSaveClicked }>
						{({ values }) => (

						<FormikForm>
							<UserProfileInformation view={props.view}></UserProfileInformation>
							
							<SecurityInformation view={props.view}></SecurityInformation>
							{
								values.RoleId === Roles.User &&
								<Systems view={props.view}></Systems>
							}
							<Preferences view={props.view}></Preferences>
							<Row>
								<Col className="d-flex justify-content-end">
									{
										!props.view &&
										<Button disabled={ saving } type="submit" className="me-1">{ `${(saving ? "Saving..." : "Save")}`}</Button>
									}
									<Button disabled={ saving } onClick={ e =>  navigate(`/Admin/Users`)  } >Cancel</Button>
								</Col>
							</Row>
						</FormikForm>)}
					</Formik>
				}
				<BusyIndicator show={ user === null }></BusyIndicator>
			</React.Fragment>
}