import { FieldArray, useFormikContext } from "formik";
import Panel from "../../../../Core/Panel";
import { Col, Row } from "react-bootstrap";
import InputField from "../../../../Core/Forms/InputField";
import { useEffect, useState } from "react";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import MultiSelect from "../../../../Core/MultiSelect";
import { isFieldEnabled } from "../../../../../Utilities/FinancialInstruments";
import { FinancialInstrumentStatuses } from "../../../../../Utilities/Enums";
import { Controls } from "../../../../../Utilities/Controls";

export default function ExclusionCriteria({ view }) {
	const store = useSessionStore();
	const formikContext = useFormikContext();
	const criteria = formikContext.values.ExclusionCriteria;

	const allWhileActive = !isFieldEnabled([], [FinancialInstrumentStatuses.Active, FinancialInstrumentStatuses.Pending], 
											formikContext.values.FundStatusId, Controls.FundOverride, store.Session);

	const [categories, setCategories] = useState(null);
	const [countries, setCountries] = useState(null);

	async function getCountries() {
		const result = await store.CountryService.GetCountries("", 0, 0);

		if(result && result.Success) {
			let availableCountries = result.Data.map(u => { return { value: u.Id, label: u.Name } });
			setCountries(availableCountries);
		}
	}

	async function getCategories() {
		const result = await store.ContactCategoriesService.GetContactCategories("", 0, 0);

		if(result && result.Success) {
			let availableCategories = result.Data.map(u => { return { value: u.Id, label: u.Name, number: u.Number } });
			setCategories(availableCategories);
		}
	}

	useEffect(() => {
		getCountries();
		getCategories();
	}, []);

	function getKey(index) {
		if(index === 0) {
			return "Countries";
		} else {
			return `Contact Category ${index}`;
		}
	}

	function handleSelectedCriteriaChanged(index, data, replaceMethod) {
		if(data) {
			let ids = data.map(u => u.value).join(",");
			let criterion = formikContext.values.ExclusionCriteria[index];

			replaceMethod(index, { Id: criterion ? criterion.Id : null, Field: getKey(index), Value: ids });
		}
	}

	function getSelections(index) {
		let selected = [];
		let selection = formikContext.values.ExclusionCriteria[index];
		let availableOptions = (index === 0 ? countries : categories);

		if(selection && selection.Value) {
			let ids = selection.Value.split(",");

			for(let i = 0; i < ids.length; i++) {
				let option = availableOptions.find(u => u.value === parseInt(ids[i]));

				if(option) {
					selected.push(option);
				}
			}
		}

		return selected;
	}

	return 	<Panel bodyClassName="overflow-visible" title="Eligibility to Trade Exclusion Criteria">
				<FieldArray name="ExclusionCriteria" validateOnChange={false}>
					{({ push, replace }) => (
						<div>
							{ categories && countries && criteria && criteria.map((criterion, index) => (
								<Row key={ criterion.Id } className="mb-50">
									<Col><InputField view={view} disabled name={ `ExclusionCriteria.${index}.Field` }></InputField></Col>
									<Col>
										<MultiSelect 
											placeholder={ view ? "" : "Select..." }
											onChange={ e => handleSelectedCriteriaChanged(index, e, replace) } 
											value={ getSelections(index) } 
											options={index === 0 ? countries : categories.filter(c => c.number === index)} 
											name="ExclusionCriteria"
											disabled={ allWhileActive || view }
										/>
									</Col>
								</Row>))
							}				
						</div>
					)}
				</FieldArray>
			</Panel>
}