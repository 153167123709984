import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../StyleSheet";
import { formatCurrencyValue } from "../../../../Utilities/Currencies";
import {getRecoveryStatus} from "../../../../Utilities/Transactions"
import { DateTime } from "luxon";
import { DateFormats } from "../../../../Utilities/Enums";

export default function RecoveriesCustomer(props) {
	return(
		props.data.map(value =>
		<View style={[styles.table, {marginBottom: 10}]}>
			<Text style={{fontSize: 7, paddingBottom:5, borderBottom: 1, borderColor: '#e3e3e3'}} fixed>{props.facilityType === 1 ? "Vendor: " : "Customer: "}<Text style={styles.boldFont}>{value.Name}</Text></Text>
			<View style={styles.tableRowWrapping} fixed>
				<Text style={[styles.textHeaders, {textAlign:'left', width:'7%'}]}>Invoice No.</Text>
				<Text style={[styles.textHeaders, {textAlign:'center', width:'6%'}]}>Currency</Text>
				<Text style={[styles.textHeaders, {textAlign:'right', width:'10%'}]}>Document Total</Text>
				<Text style={[styles.textHeaders, {textAlign:'right', width:'8%'}]}>Financing %</Text>
				<Text style={[styles.textHeaders, {textAlign:'right', width:'10%'}]}>Payment Amount</Text>
				<Text style={[styles.textHeaders, {textAlign:'right', width:'8%'}]}>Payment Diff</Text>
				<Text style={[styles.textHeaders, {textAlign:'right', width:'9%'}]}>Interest</Text>
				<Text style={[styles.textHeaders, {textAlign:'right', width:'10%'}]}>Funding Amount</Text>
				<Text style={[styles.textHeaders, {textAlign:'left', width:'10%', paddingLeft:5}]}>Recovery Date</Text>
				<Text style={[styles.textHeaders, {textAlign:'left', width:'10%'}]}>Status</Text>
				<Text style={[styles.textHeaders, {textAlign:'right', width:'12%'}]}>Recovered Amount (ZAR)</Text>
			</View>
			{
				value.VendorTransactions.map(value =>
				<View style={styles.tableRowWrapping}>
					<Text style={[styles.textData, {textAlign:'left', width:'7%'}, styles.textEllipsis]}>{value.Details.DocumentNumber}</Text>
					<Text style={[styles.textData, {textAlign:'center', width:'6%'}]}>{value.Details.Amount.OriginalCurrency.Abbreviation}</Text>
					<Text style={[styles.textData, {textAlign:'right', width:'10%'}]}>{formatCurrencyValue(value.Details.Amount.OriginalCurrency.Symbol,value.Details.Amount.OriginalValue)}</Text>               
					<Text style={[styles.textData, {textAlign:'right', width:'8%'}]}>{(value.Details.FinancePercentage).toFixed(2)} %</Text>
					<Text style={[styles.textData, {textAlign:'right', width:'10%'}]}>{formatCurrencyValue(value.Details.PaymentAmount.OriginalCurrency.Symbol,value.Details.PaymentAmount.OriginalValue)}</Text>
					<Text style={[styles.textData, {textAlign:'right', width:'8%'}]}>{formatCurrencyValue(value.Details.PaymentAmount.OriginalCurrency.Symbol,(value.Details.Amount.OriginalValue - value.Details.PaymentAmount.OriginalValue))}</Text>
					<Text style={[styles.textData, {textAlign:'right', width:'9%'}]}>{formatCurrencyValue(value.Details.AccruedInterest.ConvertedCurrency.Symbol,(value.Details.ProjectedFundingAmount.OriginalValue - value.Details.PaymentAmount.OriginalValue))}</Text>
					<Text style={[styles.textData, {textAlign:'right', width:'10%'}]}>{formatCurrencyValue(value.Details.ProjectedFundingAmount.OriginalCurrency.Symbol,value.Details.ProjectedFundingAmount.OriginalValue)}</Text>
					<Text style={[styles.textData, {textAlign:'left', width:'10%', paddingLeft:5}]}>{value.RecoveryDate !== null ? DateTime.fromISO(value.RecoveryDate).toFormat(DateFormats.ShortDate): ""}</Text>
					<Text style={[styles.textData, {textAlign:'left', width:'10%', overflow:'hidden', textOverflow:'ellipsis', maxLines:1}]}>{getRecoveryStatus(value.Transaction)}</Text>
					<Text style={[styles.textData, {textAlign:'right', width:'12%'}]}>{formatCurrencyValue(props.currency,value.RecoveryAmount)}</Text>
				</View>
			)}
			{
				value.Totals.map(value =>
				<View style={styles.tableRowWrapping}>
					<Text style={[styles.textFooters, {textAlign:'left', width:'7%', borderBottom:0.5, borderColor: 'lightgrey'}]}>Totals</Text>
					<Text style={[styles.textFooters, {textAlign:'center', width:'6%', borderBottom:0.5, borderColor: 'lightgrey'}]}>{value.Currency.Abbreviation}</Text>
					<Text style={[styles.textFooters, {textAlign:'right', width:'10%', borderBottom:0.5, borderColor: 'lightgrey'}]}>{formatCurrencyValue(value.Currency.Symbol,value.AmountTotal)}</Text>
					<Text style={[styles.textFooters, {textAlign:'right', width:'8%', borderBottom:0.5, borderColor: 'lightgrey'}]}>{(value.FinancePercentage).toFixed(2)} %</Text>
					<Text style={[styles.textFooters, {textAlign:'right', width:'10%', borderBottom:0.5, borderColor: 'lightgrey'}]}>{formatCurrencyValue(value.Currency.Symbol,value.PaymentAmountTotal)}</Text>
					<Text style={[styles.textFooters, {textAlign:'right', width:'8%', borderBottom:0.5, borderColor: 'lightgrey'}]}>{formatCurrencyValue(value.Currency.Symbol,(value.AmountTotal - value.PaymentAmountTotal))}</Text>
					<Text style={[styles.textFooters, {textAlign:'right', width:'9%', borderBottom:0.5, borderColor: 'lightgrey'}]}>{formatCurrencyValue(value.Currency.Symbol,value.IntrestAmountTotal)}</Text>
					<Text style={[styles.textFooters, {textAlign:'right', width:'10%', borderBottom:0.5, borderColor: 'lightgrey'}]}>{formatCurrencyValue(value.Currency.Symbol,value.FundingAmountTotal)}</Text>
					<Text style={[styles.textFooters, {textAlign:'right', width:'20%', borderBottom:0.5, borderColor: 'lightgrey'}]}></Text>
					<Text style={[styles.textFooters, {textAlign:'right', width:'12%', borderBottom:0.5, borderColor: 'lightgrey'}]}>{formatCurrencyValue(props.currency,value.RecoveriesTotal)}</Text>
				</View>)
			}
			{
				value.Totals.find((x) => x.Currency.Symbol !== props.currency) &&
				<View style={styles.tableRowWrapping}>
					<Text style={[styles.textFooters, {textAlign:'left', width:'7%'}]}>Totals ({value.ConvertedTotals.Currency.Abbreviation} Conversion)</Text>
					<Text style={[styles.textFooters, {textAlign:'center', width:'6%'}]}>{value.ConvertedTotals.Currency.Abbreviation}</Text>
					<Text style={[styles.textFooters, {textAlign:'right', width:'10%'}]}>{formatCurrencyValue(value.ConvertedTotals.Currency.Symbol,value.ConvertedTotals.AmountTotal)}</Text>
					<Text style={[styles.textFooters, {textAlign:'right', width:'8%'}]}>{(value.ConvertedTotals.FinancePercentage).toFixed(2)} %</Text>
					<Text style={[styles.textFooters, {textAlign:'right', width:'10%'}]}>{formatCurrencyValue(value.ConvertedTotals.Currency.Symbol,value.ConvertedTotals.PaymentAmountTotal)}</Text>
					<Text style={[styles.textFooters, {textAlign:'right', width:'8%'}]}>{formatCurrencyValue(value.ConvertedTotals.Currency.Symbol,(value.ConvertedTotals.AmountTotal - value.ConvertedTotals.PaymentAmountTotal))}</Text>
					<Text style={[styles.textFooters, {textAlign:'right', width:'9%'}]}>{formatCurrencyValue(value.ConvertedTotals.Currency.Symbol,value.ConvertedTotals.IntrestAmountTotal)}</Text>
					<Text style={[styles.textFooters, {textAlign:'right', width:'10%'}]}>{formatCurrencyValue(value.ConvertedTotals.Currency.Symbol,value.ConvertedTotals.FundingAmountTotal)}</Text>
					<Text style={[styles.textFooters, {textAlign:'right', width:'20%'}]}></Text>
					<Text style={[styles.textFooters, {textAlign:'right', width:'12%'}]}>{formatCurrencyValue(value.ConvertedTotals.Currency.Symbol,value.ConvertedTotals.RecoveriesTotal)}</Text>
				</View>
			}
		</View>)
	)
}