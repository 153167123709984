import { useFormikContext } from "formik";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { FacilityTypes } from "../../../../Utilities/Enums";
import DateField from "../../../Core/Forms/DateField";
import InputField from "../../../Core/Forms/InputField";
import NumberField from "../../../Core/Forms/NumberField";
import SelectField from "../../../Core/Forms/SelectField";
import Panel from "../../../Core/Panel";
import swal from "sweetalert"
import { getCurrencySymbol } from "../../../../Utilities/Currencies";

export default function DocumentMeta(props) {
	const formikContext = useFormikContext();

	const documentDateValue = formikContext.values["DocumentDate"];
	const dueDateValue = formikContext.values["DueDate"];

	useEffect(() => {
		if(dueDateValue && documentDateValue) {
			let dueDate = DateTime.fromISO(dueDateValue);
			let documentDate = DateTime.fromISO(documentDateValue);

			if(dueDate.diff(documentDate, 'days') < 0) {
				swal({ icon: "warning", title: "Warning", text: "Due Date must be greater than Document Date.", closeOnClickOutside: false});
			}
		}

	}, [documentDateValue, dueDateValue]);

	const canoverride = props.canoverride

	return 	<Panel title="Document Meta Information">
				<Row>
					<Col><InputField view={props.view} disabled={canoverride}  className="mb-50" required label="Account Number" name="AccountNumber"></InputField></Col>
					<Col>
						<SelectField view={props.view} className="mb-50" required label="Ledger Account" name="LedgerAccountId" disabled={ canoverride }>
							<option value=""> --- Select --- </option>
							<option value="1"> Default Clearing Account </option>
						</SelectField>
					</Col>
				</Row>
				<Row>
					<Col>
						<SelectField disabled={ props.transaction && props.canoverride } view={props.view}  className="mb-50" required label="Document Type" name="DocumentType">
							<option value="">-- Select --</option>
							<option value="Invoice">Invoice</option>
							{
								props.facilityTypeId === FacilityTypes.TradeFinance &&
								<option value="Credit Note">Credit Note</option>
							}
						</SelectField>
					</Col>
					<Col>
						<InputField view={props.view} disabled={canoverride} className="mb-50" required label="Document Number" name="DocumentNumber"></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						{ 
							formikContext.values["DocumentType"] === "Credit Note" && 
							<InputField required view={props.view} disabled={canoverride}className="mb-50" label="Original Invoice Number" name="OriginalInvoiceNumber"></InputField>
						}
						{ 
							(!formikContext.values["DocumentType"] || formikContext.values["DocumentType"] === "Invoice") && 
							<InputField view={props.view} className="mb-50" disabled={canoverride} label="Original Invoice Number" name="OriginalInvoiceNumber"></InputField>
						}
					</Col>
					<Col>
						<InputField view={props.view} className="mb-50" disabled={canoverride} label="Purchase Order Number" name="PurchaseOrderNumber"></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputField view={props.view} className="mb-50" disabled={canoverride} label="GRN Number" name="GRNNumber"></InputField>
					</Col>
					<Col>
						<InputField view={props.view} className="mb-50" disabled={canoverride} label="Document Reference" name="DocumentReference"></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputField view={props.view} className="mb-50" disabled={canoverride} label="Invoice To Acc. Number" name="InvoiceToAccountNumber"></InputField>
					</Col>
					<Col>
						<InputField view={props.view} className="mb-50"disabled={canoverride} label="Invoice To Name" name="InvoiceToName"></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputField view={props.view} className="mb-50" disabled={canoverride} label="Delivery To Acc. Number" name="DeliveryToAccountNumber"></InputField>
					</Col>
					<Col>
						<InputField view={props.view} className="mb-50" disabled={canoverride} label="Delivery To Name" name="DeliveryToName"></InputField>
					</Col>
				</Row>
				<Row>
					<Col>
						<NumberField view={props.view} className="mb-50" disabled={canoverride} label="Discount Percentage" name="DiscountPercentage" suffix="%"></NumberField>
					</Col>
					<Col>
						<NumberField prefix={ getCurrencySymbol(formikContext.values["CurrencyId"], props.currencies) } disabled={canoverride} view={props.view} className="mb-50" label="Discount Amount" name="DiscountAmount"></NumberField>
					</Col>
				</Row>
				<Row>
					<Col>
						<DateField view={props.view} className="mb-50" required label="Document Date" disabled={canoverride} name="DocumentDate"></DateField>
					</Col>
					<Col>
						<DateField view={props.view} disabled={canoverride} required label="Due Date" name="DueDate"></DateField>
					</Col>
				</Row>
				<Row>
					<Col>
						<DateField disabled view={props.view} label="Transmission Date" name="TransmissionDate"></DateField>
					</Col>
					<Col>
						<SelectField view={props.view} className="mb-50" disabled label="Transmission Source" name="TransmissionSource">
							<option value="MANUAL">MANUAL</option>
							{
								(props.view || formikContext.values.TransmissionSource === "BULK IMPORT") &&
								<option value="BULK IMPORT">BULK IMPORT</option>
							}
						</SelectField>
					</Col>
				</Row>
			</Panel>
}