import * as yup from 'yup';
import { GetRandomKey } from "./React";

export const FacilityValidationSchema =
	yup.object({
		CurrencyID: yup.mixed().required().not([ 0, "0" ]),
		FacilityNumber: yup.string().required(),
		Name: yup.string().required(),
		FacilityTypeID: yup.mixed().not([ 0, "0" ]),
		FinancialInstrumentConditionsID: yup.string().required().not([ 0, "0" ]),
		FinancialInstrumentTermID: yup.mixed().required().not([ 0, "0" ]),
		FinancialInstrumentTermConditionsID: yup.mixed().required().not([ 0, "0" ]),
		InterestCalculation: yup.string().required(),
		ProjectedFundingDays: yup.mixed().required(),
		AccountNumber: yup.string().required(),
		FinancialTermId: yup.mixed().required().not([ 0, "0" ]),
		FinancialPercentage: yup.mixed().required(),
		AdvanceDate: yup.string().required(),
		TerminationDate: yup.string().required(),
		FacilityFee: yup.mixed().required(),
		RenewalFee: yup.mixed().required(),
		ServiceFee: yup.mixed().required(),
		DocumentFee: yup.mixed().required(),
		StructureFee: yup.mixed().required(),
		OtherFees: yup.mixed().required(),
		TradingFee: yup.mixed().required(),
		PlatformFee: yup.mixed().required(),
		IssuingCost: yup.mixed().required(),
		LegalCost: yup.mixed().required(),
		CollateralManagementCost: yup.mixed().required(),
		InsuranceCost: yup.mixed().required(),
		OtherCosts: yup.mixed().required(),
		TradingDiscountSplitNumerator:  yup.mixed().required(),
		TradingDiscountSplitDenominator: yup.mixed().required(),
		FixedBaseRateExpiryDate: yup.mixed().test({ test: (value, ctx) => !ctx.parent.UseFixedBaseRate || (ctx.parent.UseFixedBaseRate && value !== undefined) }),
		YearDays: yup.mixed().test({ test: (value, ctx) => ctx.parent.InterestCalculation !== "Daily" ||
															(ctx.parent.InterestCalculation === "Daily" && value !== undefined) }),
		Securities: yup.array().test({ test: (securities) => 	securities.length > 0 &&
																securities[0].Field && securities[0].Field.length > 0 &&
																securities[0].Value && securities[0].Value.length > 0 }),
		Covenants: yup.array().test({ test: (covenants) => 		covenants.length > 0 &&
																covenants[0].Field && covenants[0].Field.length > 0 &&
																covenants[0].Value && covenants[0].Value.length > 0 })
}).required();

export function getNewFacility(preferredCurrencyId) {
	return {
		FacilityStatusID: 0, Comment: "", CurrencyID: preferredCurrencyId, FacilityNumber: "", Name: "", AccountNumber: "", InterestCalculation: "",
        FinancialTermId: 0, FacilityTypeID: 0, FinancialInstrumentConditionsID: 0, FinancialInstrumentTermID: 0, FinancialInstrumentTermConditionsID: 0,
		ProjectedFundingDays: "", AdvanceDate: "", TerminationDate: "", CostFinancePercentage: "", FacilityFee: "", RenewalFee: "",
		UnderutilisationFee: 0, ServiceFee: "", DocumentFee: "", IssuingCost: "", LegalCost: "", CollateralManagementCost: "",
		InsuranceCost: "", FinancialPercentage: "", StructureFee: "", OtherCosts: "", Factor: "", OtherFees: "", TradingFee: "",
		TradingDiscountSplitNumerator: "", TradingDiscountSplitDenominator: "", PlatformFee: "", LinkedTrading: false, YearDays: "",
		OffTakerContactId: "", UseFixedBaseRate: false, FixedBaseRateExpiryDate: "", AutoFinanceOption: false, PayableTransactionApprovalUserOne: "",
		PayableTransactionApprovalUserTwo: "", PayableTransactionVerificationUserOne: "", PayableTransactionVerificationUserTwo: "",
		PaymentInstructionConfirmationUserOne: "", PaymentInstructionConfirmationUserTwo: "", PaymentInstructionAuthorisationUserOne: "",
		PaymentInstructionAuthorisationUserTwo: "", DebtTransactionApprovalUserOne: "", DebtTransactionApprovalUserTwo: "",
		DebtTransactionVerificationUserOne: "", DebtTransactionVerificationUserTwo: "",
		Securities: [ { Field: "", Value: "", Key: GetRandomKey()}, { Field: "", Value: "", Key: GetRandomKey() } ],
		Covenants: [ { Field: "", Value: "", Key: GetRandomKey() }, { Field: "", Value: "", Key: GetRandomKey() } ],
		FinancialInstrumentFeeFrequencies: {
			FacilityFeeFrequency: 0, StructuringFeeFrequency: 0, RenewalFeeFrequency: 0, InsuranceCostFrequency: 0, ServiceFeeFrequency: 0,
			LegalCostFrequency: 0, PlatformFeeFrequency: 0, IssuingCostFrequency: 0, CollateralManagementCostFrequency: 0, TradingFeeFrequency: 0,
			DocumentFeeFrequency: 0, OtherFeeFrequency: 0, OtherCostsFrequency: 0
		},
		LinkedProviders: [], BaseInterestRates: [], CostofFinanceFundersMargins: [], CostofFinanceTradingMargins: [], UnderutilisationFundersMargins: [],
		UnderutilisationTradingMargins: [], Limit: 0,CurrencySymbol:""
	}
}