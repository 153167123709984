import { View, Text, Font } from "@react-pdf/renderer";
import { styles } from "../../StyleSheet";
import { formatCurrencyValue } from "../../../../Utilities/Currencies";
import { DateTime } from "luxon";
import { DateFormats } from "../../../../Utilities/Enums";

export default function VendorPaymentsTransactions(props){

    const showConversion = props.data.Totals.find((x) => x.Currency.Symbol !== props.currency);

    return(
        <View>
            <View style={[styles.tableRowWrapping, {paddingTop:3, paddingBottom:3}]} fixed>
                <Text style={[styles.textHeaders,{textAlign:'left', width:'18%'}, styles.textEllipsis]}>Invoice No.</Text>
                <Text style={[styles.textHeaders,{textAlign:'left', width:'7%'}]}>Due Date</Text>
                <Text style={[styles.textHeaders,{textAlign:'center', width:'6%'}]}>Currency</Text>
                <Text style={[styles.textHeaders,{textAlign:'right', width:'15%'}]}>Amount Excl. VAT</Text>
                <Text style={[styles.textHeaders,{textAlign:'right', width:'8%'}]}>VAT</Text>
                <Text style={[styles.textHeaders,{textAlign:'right', width:'11%'}]}>Document Total</Text>
                <Text style={[styles.textHeaders,{textAlign:'right', width:'11%'}]}>Financing %</Text>
                <Text style={[styles.textHeaders,{textAlign:'right', width:'11%'}]}>Payment Amount</Text>
                <Text style={[styles.textHeaders,{textAlign:'right', width:'13%'}]}>Payment Difference</Text>
            </View>
            {props.data.FinanceDetails.TransactionBalances.map(value =>
            <View key={value.Id} style={styles.tableRowWrapping}>
                <Text style={[styles.textData,{textAlign:'left', width:'18%', maxLines: 1, textOverflow: 'ellipsis'}]}>{value.DocumentNumber}</Text>
                <Text style={[styles.textData,{textAlign:'left', width:'7%'}]}>{DateTime.fromISO(value.DueDate).toFormat(DateFormats.ShortDate)}</Text>
                <Text style={[styles.textData,{textAlign:'center', width:'6%'}]}>{value.Amount.OriginalCurrency.Abbreviation}</Text>
                <Text style={[styles.textData,{textAlign:'right', width:'15%'}]}>{formatCurrencyValue(value.DocumentAmount.OriginalCurrency.Symbol,value.DocumentAmount.OriginalValue)}</Text>
                <Text style={[styles.textData,{textAlign:'right', width:'8%'}]}>{formatCurrencyValue(value.VATAmount.OriginalCurrency.Symbol,value.VATAmount.OriginalValue)}</Text>
                <Text style={[styles.textData,{textAlign:'right', width:'11%'}]}>{formatCurrencyValue(value.Amount.OriginalCurrency.Symbol,value.Amount.OriginalValue)}</Text>
                <Text style={[styles.textData,{textAlign:'right', width:'11%'}]}>{(value.FinancePercentage).toFixed(2)} %</Text>
                <Text style={[styles.textData,{textAlign:'right', width:'11%'}]}>{formatCurrencyValue(value.PaymentAmount.OriginalCurrency.Symbol,value.PaymentAmount.OriginalValue)}</Text>
                <Text style={[styles.textData,{textAlign:'right', width:'13%'}]}>{formatCurrencyValue(value.PaymentAmount.OriginalCurrency.Symbol,(value.Amount.OriginalValue - value.PaymentAmount.OriginalValue))}</Text>
            </View>
            )}
            {props.data.Totals.map(value =>
            <View style={styles.tableRowWrapping}>
                <Text style={[styles.textFooters,{textAlign:'left', width:'25%'}]}>Totals</Text>
                <Text style={[styles.textFooters,{textAlign:'center', width:'6%'}]}>{value.Currency.Abbreviation}</Text>
                <Text style={[styles.textFooters,{textAlign:'right', width:'15%'}]}>{formatCurrencyValue(value.Currency.Symbol,value.DocumentAmountTotal)}</Text>
                <Text style={[styles.textFooters,{textAlign:'right', width:'8%'}]}>{formatCurrencyValue(value.Currency.Symbol,value.VatTotal)}</Text>
                <Text style={[styles.textFooters,{textAlign:'right', width:'11%'}]}>{formatCurrencyValue(value.Currency.Symbol,value.AmountTotal)}</Text>
                <Text style={[styles.textFooters,{textAlign:'right', width:'11%'}]}>{(props.faciltity.FinancialPercentage).toFixed(2)} %</Text>
                <Text style={[styles.textFooters,{textAlign:'right', width:'11%'}]}>{formatCurrencyValue(value.Currency.Symbol,value.PaymentAmountTotal)}</Text>
                <Text style={[styles.textFooters,{textAlign:'right', width:'13%'}]}>{formatCurrencyValue(value.Currency.Symbol,(value.AmountTotal - value.PaymentAmountTotal))}</Text>
            </View>
            )}
            {showConversion &&
            <View style={styles.tableRowWrapping}>
                <Text style={[styles.textFooters,{textAlign:'left', width:'25%', borderTop:0.5, borderColor:'lightgrey'}]}>Totals (ZAR Conversion)</Text>
                <Text style={[styles.textFooters,{textAlign:'center', width:'6%', borderTop:0.5, borderColor:'lightgrey'}]}>ZAR</Text>
                <Text style={[styles.textFooters,{textAlign:'right', width:'15%', borderTop:0.5, borderColor:'lightgrey'}]}>{formatCurrencyValue("R",props.data.FinanceDetails.TransactionBalances.reduce((total,currentItem) => total =total + currentItem.DocumentAmount.ConvertedValue,0))}</Text>
                <Text style={[styles.textFooters,{textAlign:'right', width:'8%', borderTop:0.5, borderColor:'lightgrey'}]}>{formatCurrencyValue("R",props.data.FinanceDetails.TransactionBalances.reduce((total,currentItem) => total =total + currentItem.VATAmount.ConvertedValue,0))}</Text>
                <Text style={[styles.textFooters,{textAlign:'right', width:'11%', borderTop:0.5, borderColor:'lightgrey'}]}> {formatCurrencyValue("R",props.data.FinanceDetails.TransactionBalances.reduce((total,currentItem) => total =total + currentItem.Amount.ConvertedValue,0))}</Text>
                <Text style={[styles.textFooters,{textAlign:'right', width:'11%', borderTop:0.5, borderColor:'lightgrey'}]}>{(props.faciltity.FinancialPercentage).toFixed(2)} %</Text>
                <Text style={[styles.textFooters,{textAlign:'right', width:'11%', borderTop:0.5, borderColor:'lightgrey'}]}> {formatCurrencyValue("R",props.data.FinanceDetails.TransactionBalances.reduce((total,currentItem) => total =total + currentItem.PaymentAmount.ConvertedValue,0))}</Text>
                <Text style={[styles.textFooters,{textAlign:'right', width:'13%', borderTop:0.5, borderColor:'lightgrey'}]}>{formatCurrencyValue("R",props.data.FinanceDetails.TransactionBalances.reduce((total,currentItem) => total =total + (currentItem.Amount.ConvertedValue - currentItem.PaymentAmount.ConvertedValue),0))}</Text>
            </View>}

        </View>
    )
}