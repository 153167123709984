import { Button, Form, Modal, Table } from "react-bootstrap"
import DateValue from "../../Core/DateValue";
import { DateFormats } from "../../../Utilities/Enums";
import NumberValue from "../../Core/NumberValue";

export default function FundInvestorContributionsModal(props) {

    return <Modal show={props.show} size="lg">
        <Modal.Header>
            <h4>
                Investor Contributions - {props.linkedFundInvestor ? props.linkedFundInvestor.Name : ''}
            </h4>
        </Modal.Header>
        <Modal.Body>

            <Table bordered size="sm" responsive>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th className="text-end">Contribution</th>
                        <th className="text-center">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.linkedFundInvestor &&
                        props.linkedFundInvestor.LimitHistory.map((history, index) =>
                            <tr>
                                <td><DateValue date={ history.Date } outputFormat={ DateFormats.FullLongDate }></DateValue></td>
                                <td className="text-end"><NumberValue currency={ props.linkedFundInvestor.Currency } value={ history.Limit }></NumberValue></td>
                                <td className="text-center"><span><Form.Check checked={history.Active} inline /></span>Active</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>

        </Modal.Body>
        <Modal.Footer>
            <Button onClick={ props.onClose }>Close</Button>
        </Modal.Footer>
    </Modal>

}