import React from "react";
import { Col, Row } from "react-bootstrap";
import { FinancialInstrumentStatuses, FacilityTypes } from "../../../../../../Utilities/Enums";
import UserAuthorisationGroup from "./UserAuthorisationGroup";
import { isFieldEnabled } from "../../../../../../Utilities/FinancialInstruments"
import { useSessionStore } from "../../../../../../Stores/SessionStore";
import { useFormikContext } from "formik";
import { Controls } from "../../../../../../Utilities/Controls";

export default function UserAuthorisation(props) {
	const store = useSessionStore();
	const formik = useFormikContext();
	const view = formik.values.View;

	const disabled = !isFieldEnabled([], [FinancialInstrumentStatuses.Active, FinancialInstrumentStatuses.Pending], 
									formik.values.FacilityStatusID, Controls.FacilityOverride, store.Session);

	return 	<React.Fragment>
				<Row>
					<Col xs={6}>
						<UserAuthorisationGroup view={view} disabled={ parseInt(props.facilityTypeId) === FacilityTypes.InvoiceDiscounting || disabled }
												group="PayableTransaction" users={props.users} title="Payable Transactions"
												stage1="Approval" stage2="Verification">
						</UserAuthorisationGroup>
					</Col>
					<Col xs={6}>
						<UserAuthorisationGroup view={view} disabled={ disabled } group="PaymentInstruction" users={props.users} title="Payment Instructions"
												stage1="Confirmation" stage2="Authorisation">
						</UserAuthorisationGroup>
					</Col>
					<Col xs={6}>
						<UserAuthorisationGroup view={view} disabled={ parseInt(props.facilityTypeId) === FacilityTypes.TradeFinance || disabled } group="DebtTransaction"
												users={props.users} title="Debt Transactions" stage1="Approval" stage2="Verification">
						</UserAuthorisationGroup>
					</Col>
				</Row>
			</React.Fragment>
}