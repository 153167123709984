import React from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import Crestlogo from "../../../../Images/crest management system-LOGO.png";
import { styles } from "../../StyleSheet";
import { DateTime } from "luxon";
import { DateFormats } from "../../../../Utilities/Enums";

export default function InterestHeader(props) {

    return (
        <View>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingLeft: 4, paddingRight: 4, paddingBottom: 15 }}>
                <View style={{ width: '20%', padding: 1 }}>
                    {
                        !props.header.AssignmentPictureBase64 &&
                        <Image style={{ height: 35, alignSelf: "flex-start" }} src={Crestlogo} />
                    }
                    {
                        props.header.AssignmentPictureBase64 &&
                        <Image style={{ height: 35, alignSelf: "flex-start", paddingLeft: 1 }} src={`data:${props.header.AssignmentPictureMIMEType};base64,${props.header.AssignmentPictureBase64}`} />
                    }
                </View>
                <View style={{ width: '60%', padding: 1 }}>
                    <Text style={[styles.Title, { textAlign: 'center', paddingTop: 10 }]}>Interest Report - { props.header.GroupName } { props.detailed ? "Detailed " : "Summary "} ({ props.state === 1 ? "Open" : props.state === 2 ? "Closed" : "All"}) </Text>
                </View>
                <View style={{ width: '20%', padding: 1 }}>
                    {
                        !props.header.ProfilePictureBase64 &&
                        <Image style={{ height: 35, alignSelf: "flex-end" }} src={Crestlogo} />
                    }
                    {
                        props.header.ProfilePictureBase64 &&
                        <Image style={{ height: 35, alignSelf: "flex-end" }} src={`data:${props.header.ProfilePictureMIMEType};base64,${props.header.ProfilePictureBase64}`} />
                    }
                </View>
            </View>

            <View style={[styles.tableRowWrapping, { fontSize: 7, padding: 10, marginBottom: -15 }]}>
                <Text style={{ textAlign: 'left', width: '25%' }}>To Date: <Text style={styles.boldFont}>{ DateTime.fromISO(props.header.ToDate).toFormat(DateFormats.LongDate) }</Text></Text>
            </View>
            <View style={[styles.tableRowWrapping, { fontSize: 7, padding: 10 }]}>
                <Text style={{ textAlign: 'left', width: '25%', ...styles.textEllipsis }}>Group: <Text style={styles.boldFont}>{ props.header.GroupName }</Text></Text>
                <Text style={{ textAlign: 'left', width: '25%' }}>Facility: <Text style={styles.boldFont}></Text></Text>
            </View>
            {
                props.facilities.map( facility =>
                    <View style={[styles.tableRowWrapping, { fontSize: 7, paddingLeft: 5, marginTop: -18, paddingBottom: 18 }]}>
                        <Text style={{ textAlign: 'left', width: '25%' }}></Text>
                        <Text style={{ textAlign: 'left', width: '25%', marginLeft: 30, ...styles.textEllipsis }}><Text style={styles.boldFont}> { facility.FacilityNumber } </Text></Text>
                    </View>
                )
            }
        </View >
    )
}