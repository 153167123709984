import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import RateHistoryModal from "./RateHistoryModal";
import { getCurrentRate } from "../../../../../Utilities/FinancialInstruments";
import NumberInput from "../../../../Core/Forms/NumberInput";
import NumberValue from "../../../../Core/NumberValue";

export default function RateHistoryField({ title, fieldName, rates, rateType, label, col, view, disabled, permissions }) {
	const [showModal, setShowModal] = useState(false);
	const [rate, setRate] = useState(0);

	useEffect(() => {
		setRate(getCurrentRate(rates));
	}, []);

	function handleModalClosed() {
		setRate(getCurrentRate(rates));
		setShowModal(false);
	}

	function handleClick () {
		setShowModal(true)
	}

	return 	<React.Fragment>
				<Form.Group as={Row} className="align-items-center" >
					{
						label &&
						<Form.Label column>{label}</Form.Label>
					}
					<Col xs={(label ? (col || 8) : 12 )}>
						{
							!view &&
							<InputGroup onClick={ handleClick }>
								<NumberInput style={{ cursor: "not-allowed" }} disabled value={rate}></NumberInput>
								<InputGroup.Text>%</InputGroup.Text>
								<Button><FontAwesomeIcon transform="grow-2" icon={ faPlus }></FontAwesomeIcon></Button>
							</InputGroup>
						}
						{
							view &&
							<div className="d-flex flex-row align-items-center justify-content-between" onClick={ disabled ? handleClick : ''}>
								<div style={{ color: "#004878", cursor: "not-allowed"}}>
									<NumberValue value={ rate }></NumberValue><span>%</span>
								</div>
								{
									disabled &&
									<Button><FontAwesomeIcon transform="grow-2" icon={ faPlus }></FontAwesomeIcon></Button>
								}
							</div>
						}
					</Col>
				</Form.Group>
				<RateHistoryModal permissions={permissions} rateType={ rateType } onClose={ handleModalClosed } title={ title } fieldName={ fieldName } show={ showModal } rates={rates} disabled={ disabled }></RateHistoryModal>
			</React.Fragment>
}