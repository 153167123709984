import {View, Text} from '@react-pdf/renderer';
import { styles } from '../../StyleSheet';

import { formatCurrencyValue } from "../../../../Utilities/Currencies";

export default function VendorTransactionsDetails(props){
    var Limitcontact = props.data.FinanceDetails.AssignedContacts.find((contact) => contact.ContactId === parseInt(props.data.Vendor.Id));  
            return(
               <View style={{borderBottom:0.5, borderColor: 'lightgrey', paddingBottom:10}} fixed>
                    <View style={styles.tableRowWrapping}>
                        <Text style={[styles.detailsOfVendorTransactions, styles.textEllipsis]}>Contact: <Text style={styles.boldFont}>{props.data.Vendor.CompanyName}</Text></Text>
                        <Text style={styles.detailsOfVendorTransactions}>Accounting Code: <Text style={styles.boldFont}>{props.data.Vendor.AccountingCode}</Text></Text>
                        <Text style={styles.detailsOfVendorTransactions}>Contact Limit: <Text style={styles.boldFont}>{formatCurrencyValue(Limitcontact.Currency,Limitcontact.Limit)}</Text></Text>
                        <Text style={styles.detailsOfVendorTransactions}>Contact Balance: <Text style={styles.boldFont}>{formatCurrencyValue(Limitcontact.Currency,Limitcontact.Balances.ActualTotal)}</Text></Text>                       
                    </View>
                    <View style={styles.tableRowWrapping}>
                        <Text style={styles.detailsOfVendorTransactions}>Bank: <Text style={styles.boldFont}>{props.data.Vendor.BankAccounts[0].BankInstitution}</Text></Text>
                        <Text style={styles.detailsOfVendorTransactions}>Branch Code: <Text style={styles.boldFont}>{props.data.Vendor.BankAccounts[0].BranchCode}</Text></Text>
                        <Text style={styles.detailsOfVendorTransactions}>Account Number: <Text style={styles.boldFont}>{props.data.Vendor.BankAccounts[0].BankAccountNumber}</Text></Text>
                    </View>
                </View>
            
            )
        }
