import { View, Text, Font } from "@react-pdf/renderer";
import { styles } from "../../StyleSheet";
import { formatCurrencyValue } from "../../../../Utilities/Currencies";
import React from "react";
import { DateTime } from "luxon";
import { DateFormats } from "../../../../Utilities/Enums";

export default function PaymentRecoveryReimbursementTransactions(props){

    var bankaccount = props.data.Group.BankAccounts.find(x => x.IsDefault === true);

    return(
        <View style={{paddingBottom:10}}>
            <View style={styles.table}>
                <View style={{borderBottom:0.5, borderColor: 'lightgrey', paddingBottom:10}}>
                    <View style={styles.tableRowWrapping}>
                        <Text style={{...styles.detailsOfReimbursementTransactions, ...styles.textEllipsis}}>Group: <Text style={styles.boldFont}>{props.data.Group.CompanyName}</Text></Text>
                        <Text style={styles.detailsOfReimbursementTransactions}>Accounting Code: <Text style={styles.boldFont}>{props.data.Group.AccountingCode}</Text></Text>
                    </View>
                    <View style={styles.tableRowWrapping}>
                        <Text style={styles.detailsOfReimbursementTransactions}>Bank: <Text style={styles.boldFont}>{bankaccount.BankInstitution}</Text></Text>
                        <Text style={styles.detailsOfReimbursementTransactions}>Branch Code: <Text style={styles.boldFont}>{bankaccount.BranchCode}</Text></Text>
                        <Text style={styles.detailsOfReimbursementTransactions}>Account Number: <Text style={styles.boldFont}>{bankaccount.BankAccountNumber}</Text></Text>
                    </View>
                </View>
                <View style={styles.tableRowWrapping}>
                    <Text style={[styles.textHeaders, {textAlign:'left', width:'16%'}]}>Reimbursement No.</Text>
                    <Text style={[styles.textHeaders, {textAlign:'left', width:'7%'}]}>Date</Text>
                    <Text style={[styles.textHeaders, {textAlign:'center', width:'17%'}]}>Currency</Text>
                    <Text style={[styles.textHeaders, {textAlign:'right', width:'60%'}]}>Reimbursement Amount</Text>
                </View>
                {props.data.Reimbursements.map(value =>
                <React.Fragment>
                <View style={styles.tableRowWrapping}>
                    <Text style={[styles.textData, {textAlign:'left', width:'16%', ...styles.textEllipsis}]}>{value.DocumentNumber}</Text>
                    <Text style={[styles.textData, {textAlign:'left', width:'7%'}]}>{ DateTime.fromISO(value.DateTimeStamp).toFormat(DateFormats.ShortDate) }</Text>
                    <Text style={[styles.textData, {textAlign:'center', width:'17%'}]}>{value.CurrencyAbbreviation}</Text>
                    <Text style={[styles.textData, {textAlign:'right', width:'60%'}]}>{formatCurrencyValue(value.Currency,value.PaymentAmount)}</Text>
                </View>
                
                <View style={styles.tableRowWrapping}>
                    <Text style={[styles.textFooters, {textAlign:'left', width:'23%'}]}>Totals</Text>
                    <Text style={[styles.textFooters, {textAlign:'center', width:'17%'}]}>{value.CurrencyAbbreviation}</Text>
                    <Text style={[styles.textFooters, {textAlign:'right', width:'60%'}]}>{formatCurrencyValue(value.Currency,value.PaymentAmount)}</Text>
                </View>
                </React.Fragment>
                )}
            </View>
        </View>
    )
}