import React from "react";
import { Page, View, Document} from '@react-pdf/renderer';
import {styles} from "./StyleSheet";
import PaymentInstructionHeader from "./Components/PaymentInstruction/PaymentInstructionHeading";
import PaymentInstructionDetail from "./Components/PaymentInstruction/PaymentInstructionDetail";
import PaymentInstructionGroupBankDeatils from "./Components/PaymentInstruction/PaymentInstructionGroupBankDetails";
import PaymentTotals from "./Components/PaymentInstruction/PaymentTotals";
import PaymentInstructionTransactions from "./Components/PaymentInstruction/PaymentInstrcutionTransactions";
import PaymentInstructionAuthorisation from "./Components/PaymentInstruction/PaymentInstructionAuthorisation";
import PaymentInstructionTermsAndConditions from "./Components/PaymentInstruction/PaymentInstructionTermsAndConditions";
import PaymentFunderDetails from "./Components/PaymentInstruction/PaymentFunderDetails";
import PaymentRecoveryPaymentRecoveries from "./Components/PaymentRecoveries/PaymentRecoveryPaymentRecoveries";
import PaymentRecoveryReimbursementTransactions from "./Components/PaymentRecoveries/PaymentRecoveryReimbursementTransactions";
import PaymentRecoveryPaymentInstructionTotalReimbursementAmount from "./Components/PaymentRecoveries/PaymentRecoveryPaymentInstructionTotalReimbursementAmount";

export function PaymentInstruction(props) {
	return	<Document>
				<Page size="A4" style={styles.page}>
					<View style={styles.section}>				
						<PaymentInstructionHeader Pi={props.data.FinanceDetails} showRecoveries={props.data.ShowRecoveries} group={props.data.Group} data={props.data}/>				
						<PaymentInstructionDetail data={props.data} />
						<PaymentFunderDetails data={props.data} />
						{
							props.data.FinanceDetails.PaymentInstructionBalance.IsReimbursement === true &&
							<React.Fragment>
								<PaymentRecoveryReimbursementTransactions data={props.data} />
								<PaymentRecoveryPaymentInstructionTotalReimbursementAmount data={props.data} />
							</React.Fragment>
						}
						{	
							props.data.FinanceDetails.PaymentInstructionBalance.IsReimbursement === false &&
							<React.Fragment>
								{
									props.data.Facility.FacilityTypeID === 2 &&
									<PaymentInstructionGroupBankDeatils data={props.data.Group} piBankAccount={props.data.GroupBA}/>
								}

								{
									props.data.Vendors.map( c =>
									<PaymentInstructionTransactions data={c} showRecoveries={props.data.ShowRecoveries} facilityType={props.data.Facility.FacilityTypeID} currency={props.data.Facility.CurrencySymbol}/>
									)
								}
							<PaymentTotals data={props.data.Totals} convertedTotals={props.data.ConvertedTotals} showRecoveries={props.data.ShowRecoveries} currency={props.data.Facility.CurrencySymbol} facilityType={props.data.Facility.facilityTypeID}/>
								{
									props.data.ShowRecoveries &&
									<PaymentRecoveryPaymentRecoveries data={props.data.RecoveryState}/>
								}
							</React.Fragment>
						}
						{	
							props.data.FinanceDetails.PaymentInstructionBalance.PaymentInstructionStatusId === 7 &&
							<React.Fragment>
								<PaymentInstructionAuthorisation /> 
								<PaymentInstructionTermsAndConditions data={props.data.TermsConditions} />
							</React.Fragment>
						}
					</View>
				</Page>
			</Document>
}