import { Formik, Form as FormikForm } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BusyIndicator from "../../../Core/BusyIndicator";
import PageHeader from "../../../Core/PageHeader";
import AuditLogEntitySummary from "../../AuditLogs/AuditLogEntitySummary";
import { faWallet } from "@fortawesome/pro-regular-svg-icons";
import swal from "sweetalert"
import { ContactableEntityTypes, getNewObject, getValidation } from "../Common/ContactableEntity/ContactableEntity";
import CompanyDetails from "../Common/ContactableEntity/CompanyDetails";
import { ContactDetails } from "../Common/ContactableEntity/ContactDetails";
import BankingDetails from "../Common/ContactableEntity/BankingDetails";
import OtherInformation from "../Common/ContactableEntity/OtherInformation";
import Categories from "../Common/ContactableEntity/Categories";
import AdditionalDetails from "../Common/ContactableEntity/AdditionalDetails";
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";

export default function StructureDetails(props) {
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();
	const back = checkBack();
	
	const [structure, setStructure] = useState(null);
	const [saving, setSaving] = useState(false);
	const [companyName, setCompanyName] = useState();

	UseDocumentTitle(props.add ? ('Adding New ' + props.name) : ('Editing \'' + companyName + '\''));

	async function getStructure() {
		let result = await props.getStructure(params.id)
		
		setCompanyName(result.Data.CompanyName);

		if(result && result.Success) {
			setStructure(result.Data);
		}
	}

	function checkBack() {
		if(location.pathname.includes("Edit")) {
			return params.parentid ? (params.grandparentId ? location.pathname.substring(0,location.pathname.lastIndexOf("E")).concat(params.parentid + "/" + params.grandparentId) : location.pathname.substring(0,location.pathname.lastIndexOf("E")).concat(params.parentid)) :location.pathname.substring(0,location.pathname.lastIndexOf("E"));
		} else if(location.pathname.includes("Add")) {
			return params.id ? (params.grandparentId ? location.pathname.substring(0,location.pathname.lastIndexOf("A")).concat(params.id + "/" + params.grandparentId) :location.pathname.substring(0,location.pathname.lastIndexOf("A")).concat(params.id) ):location.pathname.substring(0,location.pathname.lastIndexOf("A"));
		} else {
			return params.parentid ? (params.grandparentId ?location.pathname.substring(0,location.pathname.lastIndexOf("V")).concat(params.parentid + "/" + params.grandparentId) :location.pathname.substring(0,location.pathname.lastIndexOf("V")).concat(params.parentid)) :location.pathname.substring(0,location.pathname.lastIndexOf("V"));
		}
	}
	
	useEffect(() => {
		if(props.add) {
			setStructure(getNewObject(ContactableEntityTypes.Structure));
		} else {
			getStructure();	
		}
	}, []);

	function cancelClicked() {
		if(navigate(-1)) {
			return navigate(-1);
		} else {
			navigate(back)
		}
	}

	async function handleSubmitClicked(values) {
		let result = null;
		setSaving(true);

		values.ContactCategory1Id = values.ContactCategory1Id || null;
		values.ContactCategory2Id = values.ContactCategory2Id || null;
		values.ContactCategory3Id = values.ContactCategory3Id || null;
		values.ContactCategory4Id = values.ContactCategory4Id || null;

		if(props.add) {
			result = await props.createStructure(values);
		} else {
			result = await props.updateStructure(values);
		}
		
		setSaving(false);

		if(result && result.Success) {
			swal({ icon: "success", title: "Success", text: `${props.name} ${ props.add ? "created" : "updated" } successfully`, closeOnClickOutside: false});
			navigate(-1);
		} else {
			swal({ icon: "error", title: "Error", text: `An error ocurred ${ props.add ? "creating" : "updating" } the ${props.name}. ${result.Message}`, closeOnClickOutside: false});
		}
	}
		
	const validationSchema = props.name === "Store" ? getValidation(ContactableEntityTypes.Store) : getValidation(ContactableEntityTypes.Structure);
	const structureName = props.name.replace(" ", "");

	return 	<React.Fragment>
				<PageHeader icon={ faWallet } title={ props.title }
							helpItemDescriptor={ ( props.add ? `${structureName}/Add` : `${structureName}/Edit` ) }
							crumbs={[{ Title: "Admin" }, { Title: "Groups" }, { Active: true, Title: ( props.add ? `Adding new ${props.title}` : `Editing ${props.name}` )}]}>
				</PageHeader>
				{
					structure && structure.Id &&
					<AuditLogEntitySummary hideLog table={ `${structureName}s` } entityid={ structure.Id } auditsummary={structure.AuditSummary}></AuditLogEntitySummary>
				}
				{
					structure &&
					<Formik initialValues={structure} enableReinitialize validateOnMount validationSchema={ validationSchema } onSubmit={ handleSubmitClicked }>
						{({ values }) => (
						<FormikForm>
							<CompanyDetails childName={ props.childName } view={props.view} type={ ContactableEntityTypes.Structure } countryId={ structure.CountryId }></CompanyDetails>
							<ContactDetails title="Contact Details" field="" view={props.view} required/>
							
							{props.name === "Store" && <ContactDetails title="Owner Contact Details" field="Owner" view={props.view} required/>}
							{props.name === "Store" && <ContactDetails title="Manager Contact Details" field="Manager" view={props.view} required/> }
							
							<ContactDetails title="Accounts Contact Details" field="Account" view={props.view} />
							
							<BankingDetails view={props.view}></BankingDetails>
							<OtherInformation view={props.view}></OtherInformation>
							<Categories view={props.view} contact={structure}></Categories>
							<AdditionalDetails type={ContactableEntityTypes.Structure} view={props.view}></AdditionalDetails>
							<Row>
								<Col className="d-flex justify-content-end">
									{
										!props.view &&
										<Button disabled={ saving } type="submit" className="me-1">{ `${(saving ? "Saving..." : "Save")}`}</Button>
									}
									<Button disabled={ saving } onClick={e => cancelClicked()} >Cancel</Button>
								</Col>
							</Row>
						</FormikForm>)
						}
					</Formik>
				}
				<BusyIndicator show={ !structure }></BusyIndicator>
			</React.Fragment>
}