import { Document, Page, Text, View } from "@react-pdf/renderer";
import { styles } from "../StyleSheet";
import FundsHeading from "./Components/FundHeading";
import FundLinkedInvestors from "./Components/FundLinkedInvestors";
import FundLinkedFacilitiesWithBalances from "./Components/FundLinkedFacilitiesWithBalances";
import FundBalances from "./Components/FundBalances";
import { DateTime } from "luxon";

export function FundPreview(props){

    var dateTimeSamp = DateTime.now();
    
    return(
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <FundsHeading header={ props.data.Header }></FundsHeading>
                    <View style={[styles.table, {padding:10}]}>
                        <FundLinkedInvestors linkedInvestors={ props.data.LinkedInvestors } currency={ props.data.Currency }></FundLinkedInvestors>
                        <FundLinkedFacilitiesWithBalances></FundLinkedFacilitiesWithBalances>
                        <FundBalances fundBalances={ props.data.FundBalances } currency={ props.data.Currency } header={ props.data.Header }></FundBalances>
                    </View>
                    <View style={{ position: 'absolute', bottom: -5, alignSelf: 'center'}} render={({ pageNumber, totalPages }) => (
                        <View>
                            <Text style={{ fontSize: 7, fontFamily: 'Roboto', fontWeight: 700, paddingBottom: 3, left:'20%'}}>
                                Page {pageNumber} of {totalPages}
                            </Text>
                            <Text style={{ fontSize: 7}}>
                                {DateTime.fromISO(dateTimeSamp).toFormat("dd/MM/yyyy HH:mma")}
                            </Text>
                        </View>
                    )} fixed />
                </View>
            </Page>
        </Document>
    )
}