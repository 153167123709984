import { Formik, Form as FormikForm } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, useParams} from "react-router-dom";
import { useSessionStore } from "../../../../Stores/SessionStore";
import BusyIndicator from "../../../Core/BusyIndicator";
import PageHeader from "../../../Core/PageHeader";
import AssignmentLinks from "./Details/AssignmentLinks";
import * as yup from 'yup';
import AuditLogEntitySummary from "../../AuditLogs/AuditLogEntitySummary";
import Documents from "../../../Core/Documents/Documents";
import { hasControl, Roles } from "../../../../Utilities/Permissions";
import { PaymentInstructionStatuses } from "../../../../Utilities/Enums";
import { faWallet } from "@fortawesome/pro-regular-svg-icons";
import FinancingDetails from "./Details/FinancingDetails";
import CurrencyConversionRates from "./Details/CurrencyConversionRates";
import { FlaggedCheckBox } from "../../../Core/FlaggedIndicator";
import Transactions from "./Details/Transactions";
import Funders from "./Details/Funders";
import RecoveryStateTable from "../Recoveries/RecoveryStateTable";
import Reimbursements from "./Details/Reimbursements";
import swal from "sweetalert"
import { useRef } from "react";
import UseDocumentTitle from "../../../../Utilities/UserDocumentTitle";

export default function PaymentInstructionDetails(props) {
	const [piNumber, setPiNumber] = useState('');
	const store = useSessionStore();
	const navigate = useNavigate();
	const params = useParams();
	const back = localStorage.getItem("FacilityPath");
	const [paymentInstruction, setPaymentInstruction] = useState(null);
	const [saving, setSaving] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [isFlagged, setIsFlagged] = useState(false);
	const [state, setState] = useState(null);	

	const formContentChanged = useRef(false);

	if(props.add) {
		UseDocumentTitle('Adding New \'Payment Instruction\'');
	} else if(props.view || props.edit){
		UseDocumentTitle('Editing \''+ piNumber +'\'');
	}
		
	async function getPaymentInstruction() {
		let result = await store.PaymentInstructionsService.GetPaymentInstruction(params.paymentInstructionId)
		
		setPiNumber(result.Data.Name);

		if(result && result.Success) {
			//reset 'All' assignments to 0 since they come back as null initially and yup doesn't like that
			result.Data.MarketPositionId = result.Data.MarketPositionId || 0;
			result.Data.StoreId = result.Data.StoreId || 0;

			setPaymentInstruction(result.Data);
			setIsFlagged(result.Data.Flagged);
		}
	}

	async function getRecoveryState() {
		setState(null);
		let result = await store.PaymentInstructionRecoveriesService.GetPaymentInstructionRecoveryState(params.paymentInstructionId);

		if(result && result.Success) {
			setState(result.Data);
		}
	}

	useEffect(() => {
		if(params.paymentInstructionId) {
			getPaymentInstruction();

			if(props.recoveries || props.processing || props.reimbursements) {
				getRecoveryState();
			}
		} else {
			setPaymentInstruction({ PaymentInstructionStatusId: PaymentInstructionStatuses.Open, Name: "", Number: "", GroupId: "",
									PaymentDate: "", Limit: null, Comment: "", MaturityDate: "", FundingDays: "", BatchDates: [], FacilityBatchDateId: null });
		}
	}, []);

	function cancelClicked() {
		if (navigate(-1)) {
			return navigate(-1)
		} else if (back) {
			return navigate(back)
		} else {
			return navigate(`/PaymentManagement/${getPageTitle().replaceAll(" ","")}`)
		}
	}

	async function handleSubmitClicked(values) {
		let result = null;

		let action = document.activeElement.id;
		let submitClicked = action === "submit";
		let toggleFunc = submitClicked ? setSubmitting : setSaving;

		if(paymentInstruction.IsReimbursementInstruction || values.FundingDays > 0) {
			if(params.paymentInstructionId) {
				toggleFunc(true);
				result = await store.PaymentInstructionsService.UpdatePaymentInstruction(values, submitClicked, props.processing || false, formContentChanged.current);
				toggleFunc(false);
			} else {
				result = await store.PaymentInstructionsService.CreatePaymentInstruction(values);
			}
		
			if(result && result.Success) {
				store.PaymentManagementStore.GetPaymentInstructions();
				store.PaymentManagementStore.GetPaymentProcessingInstructions();

				if(submitClicked) {
					swal({ icon: "success", title: "Submitted", text: `You have successfully submitted this payment instruction.`, closeOnClickOutside: false});
				} else {
					swal({ icon: "success", title: "Saved", text: `You have saved your payment instruction successfully.`, closeOnClickOutside: false});
				}

				navigate(`/PaymentManagement/${(props.processing ? "PaymentProcessing" : "PaymentInstructions")}`);
			} else {
				swal({ icon: "error", title: "Error", text: `An error ocurred saving the payment instruction. ${result.Message}`, closeOnClickOutside: false});
			}
		} else {
			swal({ icon: "error", title: " Validation Error", text: "Maturity Date cannot come before Run Date", closeOnClickOutside: false});
		}
	}

	function canSubmit() {
		let rightStatus = [PaymentInstructionStatuses.Open, PaymentInstructionStatuses.Pending, PaymentInstructionStatuses.Confirmed].includes(paymentInstruction.PaymentInstructionStatusId);
		let rightPermissions = (store.Session.Role === Roles.Admin || 
								store.Session.AuthState.UserAppStructure.GroupAdminLevel || 
								hasControl("Submit Payment Instruction", store.Session));
		
		return (rightStatus || paymentInstruction.Flagged) && rightPermissions && paymentInstruction.HasTransactions && !props.view;
	}
	
	function getPageTitle() {
		if(props.recoveries) {
			return "Payment Recoveries";
		}

		if(props.processing) {
			return "Payment Processing";
		}

		if(props.reimbursements) {
			return "Reimbursements";
		}

		return "Payment Instructions";
	}

	function handleFormContentChanged() {
		if(paymentInstruction.Id) {
			formContentChanged.current = true;
		}
	}

	function getYupSchema() {
		let validationObject = {
			GroupId: yup.number().required(),
			MarketPositionId: yup.number().required(), 
			StoreId: yup.number().required(),
			FacilityId: yup.number().required(),
			PaymentInstructionStatusId: yup.number().required(),
			Number: yup.string().required(),
			Name: yup.string().required(),
			PaymentDate: yup.date().required(),		
			Comment: yup.mixed().when("PaymentInstructionStatusId", { 
				is: (status) => (paymentInstruction.PaymentInstructionStatusId === PaymentInstructionStatuses.Authorised && status === PaymentInstructionStatuses.Pending), 
				then: yup.mixed().required() }),
		}
	
		if(paymentInstruction && !paymentInstruction.IsReimbursementInstruction) {
			validationObject.MaturityDate = yup.date().required();
		}
	
		if(params.paymentInstructionId) {
			validationObject.Funders = yup.array().of(yup.object().shape({
				ContactId: yup.mixed().required(),
				BankAccountId: yup.mixed().required(),
				BatchReferenceNumber: yup.mixed().required(),
				ReferenceNumber: yup.mixed().required(),
				FromAccountStatementDescription: yup.mixed().required(),
				BeneficiaryStatementDescription: yup.mixed().required(),
				PayableAmount: yup.number().required(),
				PayablePercent: yup.number().required(),
				
			}));

			if(props.processing) {
				validationObject.Currencies = yup.array().of(yup.object().shape({
					SpotRate: yup.number().required()
				}));
			}
		}
	
		return yup.object(validationObject).required();
	}

	return 	<React.Fragment>
				<PageHeader icon={ faWallet } title={ getPageTitle() }
							helpItemDescriptor={ ( props.add ? "PaymentInstruction/Add" : "PaymentInstruction/Edit" ) }
							crumbs={[{ Title: "Credit Management" }, { Title: "Payment Management" }, 
							{ Title: getPageTitle() }, { Active: true, Title: `Editing Payment Instruction` }]}>
				</PageHeader>
				{
					paymentInstruction && paymentInstruction.Id &&
					<AuditLogEntitySummary  table="PaymentInstructions" entityid={ paymentInstruction.Id } auditsummary={paymentInstruction.AuditSummary}></AuditLogEntitySummary>
				}
				{
					paymentInstruction &&
					<Formik initialValues={paymentInstruction} enableReinitialize validateOnMount validationSchema={ getYupSchema } onSubmit={ handleSubmitClicked }>
						{({ values }) => (
						<FormikForm onChange={ handleFormContentChanged }>
							<AssignmentLinks view={ props.view || props.processing }></AssignmentLinks>
							<FinancingDetails dateChanged={ handleFormContentChanged  } recoveries={ props.recoveries } processing={ props.processing } view={ props.view } status ={paymentInstruction.PaymentInstructionStatusId}></FinancingDetails>
							{								
								(props.processing || props.recoveries) &&
								<Funders view={props.view} facilityId={ paymentInstruction.FacilityId }></Funders>
							}
							<CurrencyConversionRates recoveries={ props.recoveries } processing={ props.processing } view={ props.view } currencyId={ values.CurrencyId }></CurrencyConversionRates>
							{
								props.view && !paymentInstruction.IsReimbursementInstruction &&
								<Transactions facilityTypeId={ paymentInstruction.FacilityTypeId } paymentInstructionId={ params.paymentInstructionId }></Transactions>
							}
							{
								props.view && paymentInstruction.IsReimbursementInstruction &&
								<Reimbursements paymentInstructionId={ params.paymentInstructionId }></Reimbursements>
							}
							{
								paymentInstruction.Id && paymentInstruction.PaymentInstructionStatusId >= PaymentInstructionStatuses.Processed && (props.recoveries || props.processing) &&
								<RecoveryStateTable state={state}></RecoveryStateTable>
							}
							{
								paymentInstruction.Id && 
								<Documents updateEntity={ setPaymentInstruction } viewOnly={ props.view } allowDocChanges entity={ paymentInstruction }></Documents>
							}
							<FlaggedCheckBox flagged={ isFlagged } view={ props.view }></FlaggedCheckBox>
							<Row>
								<Col className="d-flex justify-content-end">
									{
										canSubmit() &&
										<Button id="submit" disabled={ saving || submitting } type="submit" className="me-1">{ `${(submitting ? "Submitting..." : "Submit")}`}</Button>
									}
									{
										!props.view &&
										<Button id="save" disabled={ saving || submitting } type="submit" className="me-1">{ `${(saving ? "Saving..." : "Save")}`}</Button>
									}
									<Button disabled={ saving || submitting } onClick={ e => cancelClicked()} >Cancel</Button>
								</Col>
							</Row>
						</FormikForm>)
						}
					</Formik>
				}
				<BusyIndicator show={ !paymentInstruction }></BusyIndicator>
			</React.Fragment>
}