import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FastField, Field, useField, useFormikContext } from "formik";
import NumberFormat from "react-number-format";
import NumberValue from "../NumberValue";

export default function NumberField({ name, label, onChange, invalid, view, suffix, prefix, decimals, col, className, fast, disabled, placeholder, type, required }) {
	const formik = useFormikContext();
	const [field] = useField(name);

	function handleChange(event) {
		var value = event.floatValue;

		if(isNaN(value)) {
			value = null;
		}
		
		if(onChange) {
			if(!onChange(value)) {
				formik.setFieldValue(name, value, true);
			}
		} else {
			formik.setFieldValue(name, value, true);
		}
	}

	function getClass() {
		return `form-control ${((formik.errors[name] !== undefined || invalid) ? "is-invalid" : "")}`;
	}

	function handleKeyPress(e) {
		var key = e.charCode || e.keyCode || 0; 

		if (key === 13) {
			e.preventDefault();
		}
	}

	return 	<Form.Group as={Row} className={`align-items-center ${className || ""}`}>
				{
					label &&
					<Form.Label column>{ label }</Form.Label>
				}
				{
					view &&
					<Col xs={ (!label ? 12 : (col || 8)) } style={{ cursor: "not-allowed", color: "#004878" }}>
						<span>{ (prefix ? `${prefix} ` : "") }</span>
						<NumberValue decimals={decimals || 2} value={ field.value }></NumberValue>
						<span>{suffix}</span>
					</Col>
				}
				{
					!view &&
					<Col xs={ (!label ? 12 : (col || 8))}>
						<InputGroup>
							{
								prefix &&
								<InputGroup.Text>{prefix}</InputGroup.Text>
							}
							{
								name && !fast &&
								<NumberFormat onKeyPress={ handleKeyPress }  customInput={ Field } onValueChange={ handleChange } value={ field.value } thousandSeparator={ ' ' } name={ name } type={type || 'text'} required={ required }
								className={ getClass() } decimalScale={decimals || 2} fixedDecimalScale disabled={ disabled } placeholder={placeholder}></NumberFormat>
								
							}
							
							{
								name && fast &&
								<NumberFormat onKeyPress={ handleKeyPress } customInput={ FastField } onValueChange={ handleChange } value={ field.value } thousandSeparator={ ' ' } name={ name } required={ required }
								className={ getClass() } decimalScale={decimals || 2} fixedDecimalScale disabled={ disabled } placeholder={placeholder}></NumberFormat>
							}
							{
								suffix &&
								<InputGroup.Text>{suffix}</InputGroup.Text>
							}
						</InputGroup>
					</Col>
				}
			</Form.Group>
}