import { Text, View } from "@react-pdf/renderer";
import React from "react";
import InterestTransactions from "./InterestTransactions";
import { styles } from "../../StyleSheet";
import { DateTime } from "luxon";
import { DateFormats } from "../../../../Utilities/Enums";
import { formatCurrencyValue } from "../../../../Utilities/Currencies";

export default function SingleFacility(props) {

    return <React.Fragment>
        <View style={{ borderBottom: (props.detailed || (props.facility.PaymentInstructions.length < 1) ? 0.5 : 0), borderBottomColor: (props.detailed || (props.facility.PaymentInstructions.length < 1) ? 'grey' : '') }}>
            <Text style={{ textAlign: 'left', width: '25%', fontSize: 7, paddingVertical: 7, ...styles.textEllipsis }}>Facility No.: <Text style={styles.boldFont}>{ props.facility.FacilityNumber }</Text></Text>
        </View>

        {
            props.facility.PaymentInstructions.length > 0 &&
            <React.Fragment>
                <View>
                {
                    props.detailed &&
                    props.facility.PaymentInstructions.map( paymentInstruction =>
                        <View wrap={ false }>
                            {
                                props.detailed &&
                                <View style={[styles.tableRowWrapping, { fontSize: 7, paddingVertical: 5 }]}>
                                    <Text style={{ width: '33.33%' , ...styles.textEllipsis}}>Payment Instruction No.: <Text style={styles.boldFont}>{ paymentInstruction.PaymentInstructionNumber }</Text></Text>
                                    <Text style={{ width: '33.33%' }}>Run Date: <Text style={styles.boldFont}>{ DateTime.fromISO(paymentInstruction.RunDate).toFormat(DateFormats.LongDate) }</Text></Text>
                                    <Text style={{ width: '33.33%' }}>Status: <Text style={styles.boldFont}>{ paymentInstruction.Status }</Text></Text>
                                </View>
                            }
                            <InterestTransactions paymentInstructions={ props.facility.PaymentInstructions } paymentInstruction={ paymentInstruction } currency={ props.facility.Currency } currencySymbol={ props.facility.CurrencySymbol } detailed={ props.detailed } />
                        </View>

                    )
                }
                {
                    !props.detailed &&
                    <InterestTransactions paymentInstructions={ props.facility.PaymentInstructions } currency={ props.facility.Currency } currencySymbol={ props.facility.CurrencySymbol } detailed={ props.detailed } />       
                }
                </View>

                <View style={[styles.tableRowWrapping, { paddingTop: 1, paddingBottom: 1, marginTop: 10, borderTop: 0.5, borderTopColor:'grey' }]}>
                    <Text style={[styles.textFooters, { textAlign: 'left', width: ( props.detailed ? '28%' : '32%') }]}> Closing Totals as at { DateTime.fromISO(props.closingTotalDate).toFormat(DateFormats.LongDate) }</Text>
                    <Text style={[styles.textFooters, { textAlign: 'center', width: (props.detailed ? '7%' : '5%') }]}>{ props.facility.Currency }</Text>
                    <Text style={[styles.textFooters, { textAlign: 'right', width: (props.detailed ? '13%' : '12%') }]}>{ formatCurrencyValue(props.facility.CurrencySymbol, props.facility.DocumentTotal) }</Text>
                    <Text style={[styles.textFooters, { textAlign: 'right', width: (props.detailed ? '13%' : '12%') }]}>{ formatCurrencyValue(props.facility.CurrencySymbol,props.facility.PaymentAmount) }</Text>
                    <Text style={[styles.textFooters, { textAlign: 'right', width: (props.detailed ? '13%' : '13%') }]}>{ formatCurrencyValue(props.facility.CurrencySymbol,props.facility.ProjectedInterest) }</Text>
                    <Text style={[styles.textFooters, { textAlign: 'right', width: (props.detailed ? '13%' : '13%') }]}>{ formatCurrencyValue(props.facility.CurrencySymbol,props.facility.RecoveredInterest) }</Text>
                    <Text style={[styles.textFooters, { textAlign: 'right', width: (props.detailed ? '13%' : '13%') }]}>{ formatCurrencyValue(props.facility.CurrencySymbol,props.facility.OutstandingInterest) }</Text>

                </View>
            </React.Fragment>
        }
        {
            props.facility.PaymentInstructions.length < 1 &&
            <React.Fragment>
                <Text style={[ styles.textData, {width:'100%', textAlign:'center', padding: 12, color:'#9e9d9d' } ]}>No Payment Instruction or Transactions Available For This Facility.</Text>
            </React.Fragment>
        }

    </React.Fragment>

}