import { Col, Form, Row } from "react-bootstrap";
import { Field, useFormikContext } from "formik";

export default function CheckboxField({ view, className, swap, label, col, active, name, disabled, align }) {
	const formikContext = useFormikContext();
	view = formikContext.values.View || view;

	function getContainerClass() {
		if(align) {
			return `d-flex ${align}`;
		}

		return null;
	}

	return 	<Form.Group as={Row} className={`${className || ""} align-items-center`}>
				{
					!swap && label &&
					<Form.Label column>{ label }</Form.Label>
				}
				{
					!view &&
					<Col className={ getContainerClass() } xs={ col || 8}>
						<Field className="form-check-input" type="checkbox" name={ name } disabled={disabled}></Field>
					</Col>
				}
				{
					view &&
					!active &&
					<Col className={ getContainerClass() } xs={ col || 8 }>
						<Form.Check style={{ cursor: "not-allowed" }} readOnly={true} checked={ formikContext.values[name] } disabled={disabled}></Form.Check>
					</Col>
				}
				{
					view && 
					active &&
					<Col className={ getContainerClass() } xs={ col || 8}>
						<Field className="form-check-input" type="checkbox" name={ name } disabled={disabled}></Field>
					</Col>
				}				
				{
					swap && label &&
					<Form.Label column>{ label }</Form.Label>
				}
			</Form.Group>
}