import React from "react";
import {View, Text} from '@react-pdf/renderer';
import {styles} from '../../StyleSheet';
import { FacilityTypes } from "../../../../Utilities/Enums";
import { formatCurrencyValue } from "../../../../Utilities/Currencies";

export default function ContactsWithBalances(props){
	return(
		<View style={{paddingBottom:10}}>
			<Text style={[styles.boldFont, {fontSize:7, padding: 3}]}>Contacts/Vendors & Customers with Balances</Text>
			<View style={styles.tableRowWrapping}>
				<Text style={[styles.facilityHeaders, {width: '34%', textAlign: 'left', borderRight:1, borderLeft:1, borderColor:'#e3e3e3'}]}>Company Name</Text>
				<Text style={[styles.facilityHeaders, {width: '10%', textAlign: 'left', borderRight:1, borderColor:'#e3e3e3'}]}>Contact Type</Text>
				<Text style={[styles.facilityHeaders, {width: '14%', textAlign: 'center', borderRight:1, borderColor:'#e3e3e3'}]}>Limit</Text>
				<Text style={[styles.facilityHeaders, {width: '14%', textAlign: 'center', borderRight:1, borderColor:'#e3e3e3'}]}>Running Bal.</Text>
				<Text style={[styles.facilityHeaders, {width: '14%', textAlign: 'center', borderRight:1, borderColor:'#e3e3e3'}]}>Actual Bal.</Text>
				<Text style={[styles.facilityHeaders, {width: '14%', textAlign: 'center', borderRight:1, borderColor:'#e3e3e3'}]}>Status</Text>
			</View>
				{
					props.data.FacilityContacts.map(c => 
					<View style={styles.tableRowWrapping} wrap={false}>
							<Text style={[styles.facilityData, {width: '34%', textAlign: 'left', borderRight:1, borderLeft:1, borderBottom:1, borderColor:'#e3e3e3'}, styles.textEllipsis]}>{ c.Name }</Text>
							<Text style={[styles.facilityData, {width: '10%', textAlign: 'left', borderRight:1, borderBottom:1, borderColor:'#e3e3e3', padding:2}]}>
								Trade { props.data.Facility.FacilityTypeID === FacilityTypes.TradeFinance ? "Vendor" : "Customer" }
							</Text>
						
							<Text style={[styles.facilityData, {width: '14%', textAlign: 'center', borderRight:1, borderBottom:1, borderColor:'#e3e3e3'}]}>{formatCurrencyValue(props.data.Facility.CurrencySymbol, c.Limit)}</Text>
							<Text style={[styles.facilityData, {width: '14%', textAlign: 'center', borderRight:1, borderBottom:1, borderColor:'#e3e3e3', fontWeight: (c.Limit !== 0 ? (c.Balances.RunningTotal > c.Limit ? "bold" : ""): "") }]}>{formatCurrencyValue(props.data.Facility.CurrencySymbol, c.Balances.RunningTotal)}</Text>
							<Text style={[styles.facilityData, {width: '14%', textAlign: 'center', borderRight:1, borderBottom:1, borderColor:'#e3e3e3'}]}>{formatCurrencyValue(props.data.Facility.CurrencySymbol, c.Balances.ActualTotal)}</Text>
							<Text style={[styles.facilityData, {width: '14%', textAlign: 'center', borderRight:1, borderBottom:1, borderColor:'#e3e3e3'}]}>{ c.Active ? "Active": "Inactive" }</Text>	
					</View>)
				}
		</View>
	)
}