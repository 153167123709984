import React from "react";
import { Table } from "react-bootstrap";
import NumberValue from "../../../../../Core/NumberValue";

export default function FundBalances({ fund, fundBalances, currency }) {
	return 	<React.Fragment>
				<h6>Fund Balances</h6>
				<Table bordered className="align-middle" size="sm" responsive>
					<thead>
						<tr>
							<th style={{ width: "25%" }}>Number</th>
							<th>Name</th>
							<th className="text-end" style={{ width: "12%" }}>Fund Amount</th>
							<th className="text-end" style={{ width: "12%" }}>Running Bal.</th>
							<th className="text-end" style={{ width: "12%" }}>Actual Bal.</th>
							<th className="text-end" style={{ width: "15%" }}>Available Bal.</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{ fund.Number}</td>
							<td>{ fund.Name }</td>
							<td className="text-end"><NumberValue currency={ currency } value={ fundBalances.FundAmount }></NumberValue></td>
							<td className={ fundBalances.Balances.RunningTotal > fundBalances.FundAmount ? "text-end fw-bold" : "text-end"}><NumberValue currency={ currency } value={ fundBalances.Balances.RunningTotal }></NumberValue></td>
							<td className="text-end"><NumberValue currency={ currency } value={ fundBalances.Balances.ActualTotal }></NumberValue></td>
							<td className="text-end"><NumberValue currency={ currency } value={ fundBalances.AvailableBalance }></NumberValue></td>
						</tr>
					</tbody>
				</Table>
			</React.Fragment>
}