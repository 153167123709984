import { Button, Form, Modal, Table } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import React, { useEffect, useState } from "react";
import NumberValue from "../../Core/NumberValue";
import { getCurrentProviderTotal, getProviderPercentage } from "../../../Utilities/FinancialInstruments";
import FinancialInstrumentProviderField from "../Common/FinancialInstruments/FinancialInstrumentProviderField";
import FundLinkedInvestorContributionsModal from "./FundLinkedInvestorContributionsModal";

export default function FundLinkedInvestorsModal(props) {

    const store = useSessionStore();

    const [linkedFundInvestors, setLinkedFundInvestors] = useState(null);
    const [showInvestorContributionModal, setShowInvestorContributionModal] = useState(false);
    const [activeInvestorIndex, setActiveInvestorIndex] = useState(null);

    useEffect(() => {
        getLinkedFundInvestors();
    }, []);

    async function getLinkedFundInvestors() {
        let results = await store.FundsService.GetInvestorsForFund(props.fund.Id);

        if (results && results.Success) {
            setLinkedFundInvestors(results.Data);
        }

    }

    function handleShowInvestorContributionModal(index) {
        setShowInvestorContributionModal(true);
        setActiveInvestorIndex(index);
    }

    function handleOnClose() {
        setShowInvestorContributionModal(false);
    }

    return <React.Fragment>
        {
            !showInvestorContributionModal && 
            <Modal size="lg" show={props.show}>
                <Modal.Header>
                    <h4> Linked Investors for {props.fund.Name} </h4>
                </Modal.Header>
                <Modal.Body>

                    <h6>Linked Investors</h6>
                    <Table bordered size="sm" responsive>
                        <thead>
                            <tr>
                                <th>Investor Name</th>
                                <th>%</th>
                                <th>Contribution</th>
                                <th className="text-center">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                linkedFundInvestors &&
                                linkedFundInvestors.length > 0 &&
                                linkedFundInvestors.map((investor, index) =>
                                    <tr>
                                        <td>{investor.Name}</td>
                                        <td><NumberValue value={getProviderPercentage(investor, linkedFundInvestors)}></NumberValue>%</td>
                                        <td><FinancialInstrumentProviderField limit={getCurrentProviderTotal(investor)} col={12} currency={investor.Currency} onClick={e => handleShowInvestorContributionModal(index)}></FinancialInstrumentProviderField></td>
                                        <td className="text-center"><span><Form.Check checked={investor.Active} inline /></span>Active</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        }
        <FundLinkedInvestorContributionsModal show={showInvestorContributionModal} linkedFundInvestor={linkedFundInvestors !== null ? linkedFundInvestors[activeInvestorIndex] : ''} onClose={handleOnClose}></FundLinkedInvestorContributionsModal>

    </React.Fragment>
}