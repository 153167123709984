import { Text, View } from "@react-pdf/renderer";
import { styles } from "../../StyleSheet";

export default function FundLinkedFacilitiesWithBalances(props){
    return (
        <View  style={{paddingBottom:10}}>
            <Text style={[styles.boldFont, {fontSize:7, padding: 3}]}>Linked Facilities with Balances</Text>
            <View style={styles.tableRowWrapping}>
                <Text style={[styles.facilityHeaders, {width: '22%', textAlign: 'left', borderRight:1, borderLeft:1, borderColor:'#e3e3e3'}]}>Number</Text>
                <Text style={[styles.facilityHeaders, {width: '14%', textAlign: 'left', borderRight:1, borderColor:'#e3e3e3'}]}>Name</Text>
				<Text style={[styles.facilityHeaders, {width: '15%', textAlign: 'right', borderRight:1, borderColor:'#e3e3e3'}]}>Limit</Text>
				<Text style={[styles.facilityHeaders, {width: '15%', textAlign: 'right', borderRight:1, borderColor:'#e3e3e3'}]}>Running Bal.</Text>
				<Text style={[styles.facilityHeaders, {width: '17%', textAlign: 'right', borderRight:1, borderColor:'#e3e3e3'}]}>Actual Bal.</Text>
				<Text style={[styles.facilityHeaders, {width: '17%', textAlign: 'center', borderRight:1, borderColor:'#e3e3e3'}]}>Status</Text>
            </View>
        </View>
    )
}