import { faFileExcel, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faBuildingColumns, faPencilAlt, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate, NavLink } from "react-router-dom";
import { useSessionStore } from "../../../Stores/SessionStore";
import { getPermissions, Permissions } from "../../../Utilities/Permissions";
import ClickableText from "../../Core/ClickableText";
import RoundButton from "../../Core/RoundButton";
import PageHeader from "../../Core/PageHeader";
import Pagination from "../../Core/Pagination";
import Panel from "../../Core/Panel";
import FacilitiesIndexFilters from "./FacilitiesIndexFilters";
import FacilityAssignmentsModal from "./FacilityAssignmentsModal";
import FacilityBatchPaymentDatesModal from "./FacilityBatchPaymentDatesModal";
import FacilityContactsModal from "./FacilityContactsModal";
import BusyIndicator from "../../Core/BusyIndicator";
import IconButton from "../../Core/IconButton";
import { FinancialInstrumentStatuses } from "../../../Utilities/Enums";
import confirmDelete from "../../../Utilities/Delete";
import FacilityPreview from "./FacilityPreview";
import UseDocumentTitle from "../../../Utilities/UserDocumentTitle";

const FacilitiesIndex = observer(function FacilitiesIndex() {

	UseDocumentTitle('Listing \'Facilities\' on CREST');

	const store = useSessionStore();
	const navigate = useNavigate();

	const [currentFacility, setCurrentFacility] = useState(null);

	const [showingContactsModal, showContactsModal] = useState(false);
	const [showingBatchDatesModal, showBatchDatesModal] = useState(false);
	const [showingAssignmentsModal, showAssignmentsModal] = useState(false);

	useEffect(() => {
		store.FacilitiesStore.GetFacilities();
		
		
	}, []);

	async function handleExportFacilitiesClicked() {
		var result = await store.FacilitiesStore.ExportFacilities();

		if(result) {
			//save file
			var blob = new Blob([result], {type: "text/plain;charset=utf-8"});
			saveAs(blob, `FACILITIES_${DateTime.now().toFormat("dd_MM_yyyy")}.csv`);
		}
	}

	async function handleDelete(facility) {
		let result = await confirmDelete(facility.Name, facility.Id, (id) => store.FacilitiesService.DeleteFacility(id));
		
		if(result) {
			store.FacilitiesStore.GetFacilities();
		}
	}

	function handleContactsClicked(facility) {
		setCurrentFacility(facility);
		showContactsModal(true);
	}

	function handleContactsModalClosed() {
		showContactsModal(false);
		setCurrentFacility(null);
	}

	function handleBatchDatesClicked(facility) {
		setCurrentFacility(facility);
		showBatchDatesModal(true);
	}

	function handleBatchDatesClosed() {
		showBatchDatesModal(false);
		setCurrentFacility(null);
	}

	function handleAssignmentsClicked(facility) {
		setCurrentFacility(facility);
		showAssignmentsModal(true);
	}

	function handleAssignmentsClosed() {
		showAssignmentsModal(false);
		setCurrentFacility(null);
	}

	function handlePageChanged(pageIndex, pageCount) {
		store.FacilitiesStore.SetFilter({...store.FacilitiesStore.Filter, PageIndex: pageIndex, PageCount: pageCount });
		store.FacilitiesStore.GetFacilities();
	}

	function getFacilityStatus(facility) {
		if(facility.FacilityStatusId === FinancialInstrumentStatuses.Cancelled || facility.FacilityStatusId === FinancialInstrumentStatuses.Expired) {
			return facility.Status;
		}

		if(facility.CanMarkActive) {
			return "Active";
		}

		return facility.Status;
	}

	const permissions = getPermissions(Permissions.Sections.AllFacilities, Permissions.Areas.CreditManagement, Permissions.SubAreas.Facilities, store.Session);

	return 	<React.Fragment>
				<PageHeader helpItemDescriptor={ "Facility/Index" } icon={ faBuildingColumns } title="Facilities" crumbs={[{ Title: "Credit Management" }, { Title: "Facilities" }]}>
					{
						(!store.Session.AuthState.UserAppStructure.GroupAdminLevel && permissions.Add) &&
						<NavLink to={ "./Add" }><IconButton icon={ faPlus } space >Add New</IconButton></NavLink>
					}
					{
						permissions.View &&
						<IconButton icon={ faFileExcel } disabled={ store.FacilitiesStore.ExportingFacilities } onClick={handleExportFacilitiesClicked}>
							{ store.FacilitiesStore.ExportingFacilities ? "Exporting..." : "Export All" }
						</IconButton>
					}
				</PageHeader>
				<Panel>
					<FacilitiesIndexFilters></FacilitiesIndexFilters>
					<Table bordered size="sm" responsive>
						<thead>
							<tr>
								<th>Facility No.</th>
								<th>Name</th>
								<th>Type</th>
								<th className="text-center">Status</th>
								<th className="text-center">Contacts</th>
								<th className="text-center">Batch Payment Dates</th>
								<th className="text-center">Assign</th>
								{
									permissions.Edit &&
									<th className="text-center">Edit</th>
								}
								{
									permissions.Delete &&
									<th className="text-center">Delete</th>
								}
								{
									permissions.View &&
									<th className="text-center">Preview</th>
								}
							</tr>
						</thead>
						<tbody>
							{
								!store.FacilitiesStore.RetrievingFacilities &&
								store.FacilitiesStore.Facilities.length > 0 &&
								store.FacilitiesStore.Facilities.map((facility, index) =>
									<tr key={ index }>
										<td>
											{
												permissions.View &&
												<NavLink to={`./View/${facility.Id}`} >{facility.Number}</NavLink>
											}
											{
												!permissions.View &&
												<span>{ facility.Number }</span>
											}
										</td>
										<td>{ facility.Name }</td>
										<td>{ facility.Type }</td>
										<td className="text-center">{ getFacilityStatus(facility) }</td>
										<td className="text-center"><ClickableText onClick={ e => handleContactsClicked(facility) }>Contacts</ClickableText></td>
										<td className="text-center"><ClickableText onClick={ e => handleBatchDatesClicked(facility) }>Batch Payment Dates</ClickableText></td>
										<td className="text-center"><ClickableText onClick={ e => handleAssignmentsClicked(facility) }>Assign</ClickableText></td>
										{
											permissions.Edit &&
											<td className="text-center"><NavLink to={ `./Edit/${facility.Id}/` }><RoundButton icon={ faPencilAlt }></RoundButton></NavLink></td>
										}
										{
											permissions.Delete &&
											<td className="text-center">
												{ facility.Active && <span>Active</span> }
												{ facility.Active === false && <RoundButton onClick={ e => handleDelete(facility) } icon={ faTimes }></RoundButton> }
											</td>
										}
										{
											permissions.View &&
											<td className="text-center">
												<FacilityPreview facility={ facility }></FacilityPreview>
											</td>
										}
									</tr>
								)
							}
						</tbody>
					</Table>
					<BusyIndicator show={ store.FacilitiesStore.RetrievingFacilities }></BusyIndicator>
					<Pagination onPageChanged={ handlePageChanged } filter={ store.FacilitiesStore.Filter } totalResults={ store.FacilitiesStore.Facilities.length } ></Pagination>
				</Panel>
				<FacilityContactsModal show={ showingContactsModal } facility={currentFacility} onClose={ handleContactsModalClosed } ></FacilityContactsModal>
				<FacilityBatchPaymentDatesModal show={ showingBatchDatesModal } facility={currentFacility} onClose={ handleBatchDatesClosed } ></FacilityBatchPaymentDatesModal>
				<FacilityAssignmentsModal show={ showingAssignmentsModal } facility={currentFacility} onClose={ handleAssignmentsClosed } ></FacilityAssignmentsModal>
			</React.Fragment>
});

export default FacilitiesIndex;