import { Text, View } from "@react-pdf/renderer";
import { styles } from "../../StyleSheet";
import { getCurrentProviderTotal, getProviderPercentage } from "../../../../Utilities/FinancialInstruments";
import { formatCurrencyValue } from "../../../../Utilities/Currencies";

export default function FundLinkedInvestors(props){



    return (
        <View  style={{paddingBottom:10}}>
            <Text style={[styles.boldFont, {fontSize:7, padding: 3}]}>Linked Investors</Text>
            <View style={styles.tableRowWrapping}>
                <Text style={[styles.facilityHeaders, {width: '22%', textAlign: 'left', borderRight:1, borderLeft:1, borderColor:'#e3e3e3'}]}>Company Name</Text>
                <Text style={[styles.facilityHeaders, {width: '14%', textAlign: 'left', borderRight:1, borderColor:'#e3e3e3'}]}>Contact Type</Text>
				<Text style={[styles.facilityHeaders, {width: '17%', textAlign: 'left', borderRight:1, borderColor:'#e3e3e3'}]}>%</Text>
				<Text style={[styles.facilityHeaders, {width: '30%', textAlign: 'right', borderRight:1, borderColor:'#e3e3e3'}]}>Contribution</Text>
				<Text style={[styles.facilityHeaders, {width: '17%', textAlign: 'center', borderRight:1, borderColor:'#e3e3e3'}]}>Status</Text>
            </View>
            {
                props.linkedInvestors.map( linkedInvestor => (
                    <View style={styles.tableRowWrapping} key={ linkedInvestor.Id }>
                        {
                            console.log(getCurrentProviderTotal(linkedInvestor))
                        }
                        <Text style={[styles.facilityData, {width: '22%', textAlign: 'left', borderRight:1, borderLeft:1, borderBottom:1, borderColor:'#e3e3e3', maxLines:1, ...styles.textEllipsis}]}>{ linkedInvestor.Name }</Text>
                        <Text style={[styles.facilityData, {width: '14%', textAlign: 'left', borderRight:1, borderBottom:1, borderColor:'#e3e3e3', maxLines:1, ...styles.textEllipsis}]}>Investor</Text>
                        <Text style={[styles.facilityData, {width: '17%', textAlign: 'left', borderRight:1, borderBottom:1, borderColor:'#e3e3e3', maxLines:1, ...styles.textEllipsis}]}>{ parseFloat(getProviderPercentage(linkedInvestor, props.linkedInvestors)).toFixed(2) }%</Text>
                        <Text style={[styles.facilityData, {width: '30%', textAlign: 'right', borderRight:1, borderBottom:1, borderColor:'#e3e3e3', maxLines:1, ...styles.textEllipsis}]}>{ formatCurrencyValue(props.currency, getCurrentProviderTotal(linkedInvestor)) }</Text>
                        <Text style={[styles.facilityData, {width: '17%', textAlign: 'center', borderRight:1, borderBottom:1, borderColor:'#e3e3e3', maxLines:1, ...styles.textEllipsis}]}>{ linkedInvestor.Active ? "Active" : "Inactive" }</Text>
                    </View>
                ))
            }
        </View>
    )
}