import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "../../StyleSheet";
import Crestlogo from "../../../../Images/crest management system-LOGO.png"

export default function FundHeading(props){
    return(
        <View styles={styles.table}>
            <View style={{display: 'flex', flexDirection: 'row', paddingBottom: 15}}>
                <View style={{ width: "25%" , paddingLeft:1}}>
                        {
                            !props.header.AssignmentPictureBase64 &&
                            <Image style={{height:35, alignSelf: "flex-start"}} src={ Crestlogo } />
                        }
                        {
                            props.header.AssignmentPictureBase64 &&
                            <Image style={{height:35, alignSelf: "flex-start"}} src={ `data:${props.header.AssignmentPictureMIMEType};base64,${props.header.AssignmentPictureBase64}` } />
                        }
				</View>
                <View style={{justifyContent: "center", width: "50%"}}>
					<Text style={styles.Title}>Fund - { props.header.FundNumber }</Text>
					<Text style={styles.Title}>{ props.header.FundName }</Text>
				</View>
				<View style={{ width: "25%", paddingRight:2, }}>
					{
						!props.header.ProfilePictureBase64 &&
						<Image style={{height:35, alignSelf: "flex-end"}} src={ Crestlogo } />
					}
					{
						props.header.ProfilePictureBase64 &&
						<Image style={{height:35, alignSelf: "flex-end"}} src={ `data:${props.header.ProfilePictureMIMEType};base64,${props.header.ProfilePictureBase64}` } />
					}
				</View>
            </View>

            <View style={{display: 'flex', flexDirection: 'column', paddingBottom: 20}}>
				<View style={{minWidth:'10%', maxWidth:'65%', alignSelf:'center'}}>
					<View style={{flexDirection: 'row', fontSize:7 }}>
						<Text style={{ paddingTop: 5, paddingRight: 5}}><Text style={styles.boldFont}>Type: </Text>{props.header.FundType} - {props.header.AssetDescription}</Text>
                    </View>
				</View>
			</View>


        </View>
    )
}