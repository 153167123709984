import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import StructureManager from "../../../Utilities/StructureManager";
import { getPermissions, Permissions } from "../../../Utilities/Permissions";
import BusyIndicator from "../../Core/BusyIndicator";
import RoundButton from "../../Core/RoundButton";
import { StructureDropDown } from "../../Core/StructureFilter";
import swal from "sweetalert";

export default function FacilityAssignmentsModal(props) {
	const store = useSessionStore();

	const [assignments, setAssignments] = useState(null);
	const [updatingAssignments, setUpdatingAssignments] = useState(false);

	const [groupId, setGroupId] = useState(0);
	const [marketPositionId, setMarketPositionId] = useState(0);
	const [storeId, setStoreId] = useState(0);
	const [faciltyName, setFacilityName] = useState("");
	
	const structure = useRef(new StructureManager(store.Session.AuthState.UserAppStructure.AvailableStructure, true));
	const permissions = getPermissions(Permissions.Sections.AllFacilities, Permissions.Areas.CreditManagement, Permissions.SubAreas.Facilities, store.Session);

	useEffect(() => {
		async function getFacilityAssignments() {
			setAssignments(null);
			
			var results = await store.FacilitiesService.GetFacilityAssignments(props.facility.Id);

			if(results && results.Success) {
				setAssignments(results.Data);
			}
		}
		
		if(props.show) {
			setGroup("");
			setMarketPosition("");
			setStore("");
			getFacilityAssignments();
			setFacilityName(props.facility.Name)
		}
	}, [props.show]);
	
	function handleAddAssignmentClicked() {
		if(structure.current.GroupId !== "" && structure.current.MarketPositionId !== "" && structure.current.StoreId !== "") {
			var group = structure.current.Groups.find((g) => g.Id === structure.current.GroupId);

			if(group) {
				var marketPosition = structure.current.MarketPositions.find((mp) => mp.Id === structure.current.MarketPositionId);
				var store = structure.current.Stores.find((s) => s.Id === structure.current.StoreId);
			
				var newAssignment = {
					GroupId: structure.current.GroupId,
					GroupName: group.Name,
					MarketPositionId: marketPosition ? structure.current.MarketPositionId : null,
					MarketPositionName: marketPosition ? marketPosition.Name : "All",
					StoreId: store ? structure.current.StoreId : null,
					StoreName: store ? store.Name : "All",
					Active: false,
					Linked: false
				}
	
				assignments.push(newAssignment);
				setAssignments([...assignments]);
	
				setGroup("");
				setMarketPosition("");
				setStore("");
			}
		}
	}

	function handleAssignmentDeleteClicked(index) {
		assignments.splice(index, 1);
		setAssignments([...assignments]);
	}

	function handleAssignmentActiveChecked(assignment, active, index) {
		assignment.Active = active;

		//we need to deactivate all the other assignments since only one can be active at a time
		for(var i = 0; i < assignments.length; i++) {
			if(i !== index) {
				assignments[i].Active = false;
			}
		}

		setAssignments([...assignments]);
	}

	async function handleSaveClicked() {
		setUpdatingAssignments(true);
		let result = await store.FacilitiesService.UpdateFacilityAssignments(props.facility.Id, assignments);
		setUpdatingAssignments(false);

		if(result.Success) {
			store.FacilitiesStore.GetFacilities();
			swal({ icon: "success", title: "Facility Assigned", text: "Facility assigned successfully.", closeOnClickOutside: false })
		} else {
			swal({ icon: "error", title: "Error", text: "An error occurred assigning the Facility.", closeOnClickOutside: false })
		}
	}

	function showStructureForm() {
		if(permissions.Add) {
			if(assignments) {
				if(assignments.length === 0) {
					return true;
				}

				var activeAssignment = assignments.findIndex((assignment) => assignment.Active === true);

				if(activeAssignment === -1) {
					return true;
				}
			}
		}

		return false;
	}

	function setGroup(groupId) {
		structure.current.SetSelectedGroup(groupId);
		setGroupId(groupId);
	}

	function setMarketPosition(marketPositionId) {
		structure.current.SetSelectedMarketPosition(marketPositionId);
		setMarketPositionId(marketPositionId);
	}

	function setStore(storeId) {
		structure.current.SetSelectedStore(storeId);
		setStoreId(storeId);
	}

	return 	<Modal size="lg" show={ props.show }>
				<Modal.Header>
					<h4>
					
						Assign to {faciltyName}
					
					</h4>
				</Modal.Header>
				<Modal.Body>
					{
						assignments && props.facility &&
						<React.Fragment>
							{
								showStructureForm() &&
								<Form className="mb-50">
									<StructureDropDown label="Group" value={ structure.current.GroupId } structure={ structure.current.Groups } onChange={ setGroup }></StructureDropDown>
									<StructureDropDown allowAll label="Market Position" value={ structure.current.MarketPositionId } structure={ structure.current.MarketPositions } onChange={ setMarketPosition }></StructureDropDown>
									<StructureDropDown allowAll label="Store" value={ structure.current.StoreId } structure={ structure.current.Stores } onChange={ setStore }></StructureDropDown>
									<Row className="mt-50">
										<Col className="d-flex justify-content-end">
											<Button onClick={handleAddAssignmentClicked}>Add</Button>
										</Col>
									</Row>
								</Form>
							}

							<h6>Current Assignments</h6>
							<Table bordered className="align-middle" size="sm" responsive>
								<thead>
									<tr>
										<th>Group</th>
										<th>Market Position</th>
										<th>Store</th>
										{
											(permissions.Edit || permissions.View) &&
											<th className="text-center">Status</th>
										}
										{
											(permissions.Delete || permissions.View) &&
											<th className="text-center">Delete</th>
										}
									</tr>
								</thead>
								<tbody>
									{
										permissions.Any() &&
										assignments.map((value, index) =>
										<tr key={value.Id}>
											<td className="py-1">{value.GroupName}</td>
											<td>{value.MarketPositionName}</td>
											<td>{value.StoreName}</td>
											{
												(permissions.Edit || permissions.View) &&
												<td className="text-center">
													<span>
														<Form.Check onChange={ e => handleAssignmentActiveChecked(value, e.target.checked, index) } checked={ value.Active } inline disabled={!permissions.Edit}/> Active
													</span>
												</td>
											}

											{
												(permissions.Delete || permissions.View) && value.Linked && <td className="text-center">Linked</td>
											}
											{
												(permissions.Delete || permissions.View) && value.Linked === false &&
												<td className="text-center">
													<RoundButton onClick={ permissions.Edit ? e => handleAssignmentDeleteClicked(index) : null } icon={ faTimes }></RoundButton>
												</td>
											}

										</tr>)
									}
								</tbody>
							</Table>
						</React.Fragment>
					}
					<BusyIndicator size="2x" show={(!assignments || !props.facility)}></BusyIndicator>
				</Modal.Body>
				<Modal.Footer>
					{
						permissions.Edit &&
						<Button disabled={ assignments === null || updatingAssignments } onClick={ handleSaveClicked }>Save</Button>
					}
					<Button disabled={ assignments === null || updatingAssignments } onClick={ props.onClose }>Close</Button>
				</Modal.Footer>
			</Modal>
}