import { faUsers } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useSessionStore } from "../../../Stores/SessionStore";
import { getAuditType,getFrequency } from "../../../Utilities/Audits";
import BackButton from "../../Core/BackButton";
import BusyIndicator from "../../Core/BusyIndicator";
import DateValue from "../../Core/DateValue";
import PageHeader from "../../Core/PageHeader";
import Panel from "../../Core/Panel";
import UseDocumentTitle from "../../../Utilities/UserDocumentTitle";

export default function AuditDetails() {
	UseDocumentTitle('Listing \'Audit\' on CREST');

	const [auditDetails, setAuditDetails] = useState(null);

	const params = useParams();
	const store = useSessionStore();	

	function removeTags(str) {
		if ((str === null) || (str === ''))
			return false;
		else
			str = str.toString();
			  
		// Regular expression to identify HTML tags in 
		// the input string. Replacing the identified 
		// HTML tag with a null string.
		return str.replace( /(<([^>]+)>)/ig, '');
	}

	async function getAuditDetails() {
		setAuditDetails(null);
		let result = await store.AuditsService.GetAuditChanges(params.id);

		if(result && result.Success) {
			if(result.Data.TableName === "Facilities") {
				result.Data.Changes = result.Data.Changes.filter(obj => { return obj.Column !== "FacilityFeesFrequencyId"});
			}
	
			setAuditDetails(result.Data);
		}
	}

	useEffect(() => {
		getAuditDetails();
	}, [])

	function getEntityLink() {
		if(auditDetails) {
			switch(auditDetails.TableName) {
				case "Facilities":
					return `#/Facilities/Edit/${auditDetails.EntityId}`;
				default:
					break;
			}
		}

		return "#/"
	}

	function getValue(value) {
		if(auditDetails) {
			switch(auditDetails.TableName) {
				case "FacilityFeesFrequency":
					return getFrequency(removeTags(value));
				case "FundFeesFrequency":
					return getFrequency(removeTags(value));
				default:
					break;
			}
		}

		return removeTags(value);
	}

	return <React.Fragment>
				<PageHeader helpItemDescriptor={ "Audit/View" } icon={ faUsers } title={`Audit ${ auditDetails ? auditDetails.DisplayName : "" }`} crumbs={[{ Title: "Audit" }, { Title: "View" }]}>
					<BackButton></BackButton>
				</PageHeader>
				<Panel>					
					{
						auditDetails &&
						<React.Fragment>
							<Row>
								<Col>
									<div><b>Date:</b> <DateValue outputFormat="dd/MM/yyyy HH:mm" date={ auditDetails.Date }></DateValue></div>
									<div><b>Update Type:</b> { getAuditType(auditDetails.Type) }</div>
									<div><b>Table:</b> { auditDetails.TableName }</div>
									<div><b>ID:</b> <a href={ getEntityLink() }>{ auditDetails.EntityId }</a></div>
									<div><b>User:</b> { auditDetails.User }</div>
								</Col>
							</Row>
							<hr></hr>
							<Table bordered>
								<thead>
									<tr>
										<th>Field</th>
										<th>Old Value</th>
										<th>New Value</th>
									</tr>
								</thead>
								<tbody>
									{
										auditDetails.Changes.length > 0 &&
										auditDetails.Changes.map((change, index) =>
											<tr key={ index }>
												<td>{ change.Column }</td>
												<td>{ getValue(change.OldValue) }</td>
												<td>{ getValue(change.NewValue) }</td>
											</tr>
										)
									}
								</tbody>
							</Table>
						</React.Fragment>
					}
					<BusyIndicator size="2x" show={ auditDetails === null }></BusyIndicator>
				</Panel>
			</React.Fragment>
}