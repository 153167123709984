import APIBase from "./APIBase";

/**
 * @typedef FundInvestorVM
 * @property {string} Name
 * @property {number} Contribution
 * @property {boolean} Active
 */

/**
 * @typedef BenchmarkNotification
 * @property {number} Id
 * @property {number} FundId
 * @property {number} Percentage
 * @property {number} BenchmarkNotificationType
 * @property {Array<string>} UserIds
 */

/**
 * @typedef FundIndexModel
 * @property {number} Id
 * @property {string} Name
 * @property {string} Number
 * @property {number} Type
 * @property {string} Status
 * @property {Array<string>} LinkedInvestors
 * @property {Array<string>} AssignedFacilities
 * @property {number} FundStatusId
 * @property {boolean} Active
 */

/**
 * @typedef FundsService
 * @type {FundsService}
 */
export default class FundsService extends APIBase {
    /**
     * @param {string} apiUrl
     * @param { import("../Stores/SessionStore").SessionStore } sessionStore
     **/
    constructor(apiUrl, sessionStore) {
        super(apiUrl + "Funds", sessionStore);
    }

	/**
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
	async CreateFund(fund) {
        return this.Post(`CreateFund`, fund);
    }

	/**
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
	async UpdateFund(fund) {
        return this.Post(`UpdateFund`, fund);
    }

	/**
     * @returns { import("./APIBase").EnumerableRequestResult<FundIndexModel> }
     */
	async GetFunds(pageIndex, pageCount, search, facilityId) {
		return this.Get(`GetFunds?pageIndex=${pageIndex}&pageCount=${pageCount}&search=${encodeURIComponent(search)}&facilityId=${facilityId}`);
	}

	/**
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
	async DeleteFund(fundId) {
        return this.Post(`DeleteFund?fundId=${fundId}`);
    }

	/**
     * @returns { import("./APIBase").EnumerableRequestResult<FundInvestorVM> }
     */
	async GetInvestorsForFund(fundId) {
        return this.Get(`GetInvestorsForFund?fundId=${fundId}`);
    }

	/**
     * @returns { import("./APIBase").EnumerableRequestResult<BenchmarkNotification> }
     */
	async GetBenchmarkNotificationsForFund(fundId) {
        return this.Get(`GetBenchmarkNotificationsForFund?fundId=${fundId}`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<boolean> }
     */
	async SetBenchmarkNotificationsForFund(fundId, benchmarkNotifications) {
        return this.Post(`SetBenchmarkNotificationsForFund?fundId=${fundId}`, benchmarkNotifications);
    }

	/**
     * @returns { import("./APIBase").EnumerableRequestResult<any> }
     */
	async GetFundContacts() {
        return this.Get(`GetFundContacts`);
    }

	/**
     * @returns { import("./APIBase").RequestResult<any> }
     */
	async GetFund(fundId) {
		return this.Get(`GetFund?fundId=${fundId}`);
	}

    /**
     * @returns { import("./APIBase").RequestResult<string> }
     */
	async ExportFunds(search, facilityId) {
		return this.Get(`ExportFunds?search=${encodeURIComponent(search)}&facilityId=${facilityId}`);
	}
}