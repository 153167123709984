import { Card } from "react-bootstrap";

export default function Panel({ children, title, className, bodyClassName, style }) {
	return 	<Card style={ style } className={`mb-1 ${className || ""}`}>
				{
					title &&
					<Card.Header>
						<Card.Title style={{ fontSize: "0.92857rem", color: "black" }}>
							{ title }
						</Card.Title>
					</Card.Header>
				}
				<Card.Body className={`overflow-auto${(bodyClassName ? ` ${bodyClassName}` : "")}`}>
					{ children }
				</Card.Body>
			</Card>
}