import { Col, Form, Row } from "react-bootstrap";
import { Field, FastField, useFormikContext, useField, getIn } from "formik";
import TextValue from "../TextValue";

export default function SelectField({ name, label, value, disabled, view, children, invalid, className, onChange, col, fast, required }) {
	const { setFieldValue, errors, values } = useFormikContext();
	const [field] = useField(name);
	const inputClass = `form-control ${((field.name ? getIn(errors, field.name) : false) || invalid ?  "is-invalid" : "")}`;
	
	function resolveValue() {
		var names = name.split(".")
		var object = values;

		for(var i = 0; i < names.length; i++) {
			object = object[names[i]];
		}

		return object;
	}

	function compareValue(option) {
		const formValue = value || resolveValue();

		if(formValue !== null && formValue !== undefined) {
			let compareValue = option ? +option : "";

			if(isNaN(compareValue)) {
				compareValue = option;
			}

			if(compareValue === formValue) {
				return true;
			}
		}

		return false;
	}

	function getViewValue() {
		if(view && children) {
			for(var i = 0; i < children.length; i++) {
				if(Array.isArray(children[i])) {
					for(let j = 0; j < children[i].length; j++) {
						if(compareValue(children[i][j].props.value)) {
							return children[i][j].props.children;
						}
					}
				} else {
					if(children[i] && compareValue(children[i].props.value)) {
						return children[i].props.children;
					}
				}
			}
		}

		return "";
	}

	function handleChange(event) {
		if(onChange) {
			if(!onChange(event)) {
				setFieldValue(name, event.target.value);
			}
		} else {
			setFieldValue(name, event.target.value);
		}
	}

	const viewValue = getViewValue();

	return 	<Form.Group as={Row} className={`${className || ""} align-items-center`}>
				{
					label &&
					<Form.Label column>{ label }</Form.Label>
				}
				{
					view &&
					<Col xs={ (label ? (col || 8): 12) }>
						<TextValue value={ viewValue }></TextValue>
					</Col>
				}
				{
					!view &&
					<Col xs={ (label ? (col || 8): 12) }>
						{
							fast &&
							<FastField required={ required } onChange={handleChange} as={ Form.Select } className={ inputClass } name={ name } disabled={disabled} >
							{
								children
							}
							</FastField>
						}
						{
							!fast && name &&
							<Field required={ required } onChange={handleChange} as={ Form.Select } className={ inputClass } name={ name } disabled={disabled}>
								{
									children
								}
							</Field>
						}
						{
							!fast && !name &&
							<Form.Select value={value} required={ required } onChange={onChange} disabled={disabled} className={ inputClass }>
								{
									children
								}
							</Form.Select>
						}
					</Col>
				}
			</Form.Group>
}