import {View, Text, Font} from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../../../StyleSheet';
import { formatCurrencyValue } from "../../../../../Utilities/Currencies";
import { DateTime } from 'luxon';
import { DateFormats } from '../../../../../Utilities/Enums';

export default function TransactionsHeaders(props){

    const showRecoveries = props.showRecoveries;

    return(
        <View>
            <View style={styles.tableRowWrapping} fixed>
                <Text style={[styles.textHeaders, {textAlign:'left', width: (showRecoveries? '12%': '22%'), paddingLeft:1.5}]}>Invoice No.</Text>
                <Text style={[styles.textHeaders, {textAlign:'left', width: (showRecoveries? '7%': '8%')}]}>Due Date</Text>
                <Text style={[styles.textHeaders, {textAlign:'center', width: (showRecoveries? '6%': '6%')}]}>Currency</Text>
                <Text style={[styles.textHeaders, {textAlign:'right', width: (showRecoveries? '14%': '18%')}]}>Document Amount</Text>
                <Text style={[styles.textHeaders, {textAlign:'right', width: (showRecoveries? '10%': '10%')}]}>VAT</Text>
                <Text style={[styles.textHeaders, {textAlign:'right', width: (showRecoveries? '14%': '18%')}]}>Document Total</Text>
                <Text style={[styles.textHeaders, {textAlign:'right', width: (showRecoveries? '14%': '18%')}]}>Payment Amount</Text>  
                {showRecoveries &&
                    <React.Fragment>
                        <Text style={[styles.textHeaders, {textAlign:'right', width: '10%'}]}>Interest</Text>
                        <Text style={[styles.textHeaders, {textAlign:'right', width: '13%'}]}>Funding Amount</Text>
                    </React.Fragment>
                }
            </View>
           
            { props.data.Transactions.map(c=>
                <View style={styles.tableRowWrapping}>
                    <Text style={[styles.textData, {textAlign:'left', width: (showRecoveries? '12%': '22%'), ...styles.textEllipsis}]}>{c.DocumentNumber}</Text>
                    <Text style={[styles.textData, {textAlign:'left', width: (showRecoveries? '7%': '8%')}]}>{DateTime.fromISO( c.DueDate ).toFormat(DateFormats.ShortDate)}</Text>
                    <Text style={[styles.textData, {textAlign:'center', width: (showRecoveries? '6%': '6%')}]}>{c.PaymentAmount.OriginalCurrency.Abbreviation}</Text>
                    <Text style={[styles.textData, {textAlign:'right', width: (showRecoveries? '14%': '18%')}]}>{formatCurrencyValue(c.DocumentAmount.OriginalCurrency.Symbol,c.DocumentAmount.OriginalValue)}</Text>
                    <Text style={[styles.textData, {textAlign:'right', width: (showRecoveries? '10%': '10%')}]}>{formatCurrencyValue(c.VATAmount.OriginalCurrency.Symbol,c.VATAmount.OriginalValue)}</Text>
                    <Text style={[styles.textData, {textAlign:'right', width: (showRecoveries? '14%': '18%')}]}>{formatCurrencyValue(c.Amount.OriginalCurrency.Symbol,c.Amount.OriginalValue)}</Text>
                    <Text style={[styles.textData, {textAlign:'right', width: (showRecoveries? '14%': '18%')}]}>{formatCurrencyValue(c.PaymentAmount.OriginalCurrency.Symbol,c.PaymentAmount.OriginalValue)}</Text>
                    {showRecoveries &&
                        <React.Fragment>
                            <Text style={[styles.textData, {textAlign:'right', width: '10%'}]}>{formatCurrencyValue(c.AccruedInterest.ConvertedCurrency.Symbol,(c.ProjectedFundingAmount.OriginalValue - c.PaymentAmount.OriginalValue) )}</Text>
                            <Text style={[styles.textData, {textAlign:'right', width: '13%'}]}>{formatCurrencyValue(c.ProjectedFundingAmount.OriginalCurrency.Symbol,c.ProjectedFundingAmount.OriginalValue)}</Text>
                        </React.Fragment>
                    }
                </View>
            )}
        </View>
    )
}