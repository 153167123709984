import { Document, Page, View } from "@react-pdf/renderer";
import RecoveriesCustomer from "./Components/Recoveries/RecoveriesCustomer";
import RecoveriesHeading from "./Components/Recoveries/RecoveriesHeading";
import RecoveriesTotalsSummary from "./Components/Recoveries/RecoveriesTotalsSummary";
import {styles} from './StyleSheet';

export default function Recoveries(props) {
	return(
		<Document>
			<Page size="A4" style={styles.page}>
				<View style={styles.section}>
					<RecoveriesHeading data={props.data} faclity={props.data.Facility}/>
					<View>
						<View>
							<RecoveriesCustomer data={props.data.Vendors} currency={props.data.Facility.Facility.CurrencySymbol} facilityType={props.data.Facility.Facility.FacilityTypeID}/>
						</View>
					</View> 
					<View>
						<View style={styles.table}>
							<RecoveriesTotalsSummary data={props.data} convertedTotals={props.data.ConvertedTotals} currency={props.data.Facility.Facility.CurrencySymbol}/>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	)
}