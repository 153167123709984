import React from "react";
import { useSessionStore } from "../../../Stores/SessionStore";
import { Route } from "react-router-dom";
import FundsIndex from "./FundsIndex";
import Fund from "./FundDetails/Fund";
import { getPermissions, Permissions } from "../../../Utilities/Permissions";

export default function FundRoutes(){
    const store = useSessionStore();
	const permissions = getPermissions(Permissions.Sections.AllFunds, Permissions.Areas.FundManagement, Permissions.SubAreas.Funds, store.Session);
    return <React.Fragment>

        <React.Fragment>
            <Route path="/Funds/" element={ <FundsIndex></FundsIndex> }></Route>
			{
				permissions.Add &&
				<Route path="/Funds/Add" element={ <Fund add></Fund> }></Route>
			}
			{
				permissions.Edit &&
				<Route path="/Funds/Edit/:fundId" element={ <Fund edit></Fund> }></Route>
			}
			{
				permissions.View &&
				<Route path="/Funds/View/:fundId" element={ <Fund view></Fund> }></Route>
			}
        </React.Fragment>

    </React.Fragment>
}