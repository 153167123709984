import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import NumberInput from "../../../Core/Forms/NumberInput";
import MultiSelect from "../../../Core/MultiSelect";
import { useEffect, useState } from "react";
import ClickableText from "../../../Core/ClickableText";
import RoundButton from "../../../Core/RoundButton";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FundBenchmarkNotificationTypes } from "../../../../Utilities/Enums";

export default function Notifications({ notificationType, notifications, users, onAddNotification, onDeleteNotification, permissions }) {
	const [percentage, setPercentage] = useState("");
	const [recipients, setRecipients] = useState([]);

	function getUsers(notification) {
		let userNames = [];

		for(let i = 0; i < notification.UserIds.length; i++) {
			var user = users.find(u => u.Id === notification.UserIds[i]);

			if(user) {
				userNames.push(user.Name);
			}
		}

		return userNames.join(", ");
	}

	function handleOnAddClicked() {
		if(recipients.length > 0 && percentage) {
			onAddNotification(percentage, recipients.map(r => r.value), notificationType);
		}
	}

	useEffect(() => {
		setRecipients([]);
		setPercentage("");
	}, [notifications])

	return 	<>
				{permissions.Add && permissions.Edit &&
					<>
						<Row>
							<Form.Label column xs={4}>
								{`Set Capital ${ notificationType === FundBenchmarkNotificationTypes.CapitalAllocation ? "Allocation" : "Recovery" } Benchmark`}
							</Form.Label>
							<Col xs={8}>
								<InputGroup>
									<NumberInput value={ percentage } onChange={ e => setPercentage(e) }></NumberInput>
									<InputGroup.Text>%</InputGroup.Text>
								</InputGroup>
							</Col>
						</Row>
						<Row className="mt-50">
							<Col xs={ 4 }>
								<label style={{ marginTop: 10 }}>Benchmark Notification Recipient</label>
							</Col>
							<Col>
								<MultiSelect
									onChange={ e => setRecipients(e) } 
									value={ recipients } 
									options={users.map(u => { return { value: u.Id, label: u.Name }})}
									name="Notifications">
								</MultiSelect>
							</Col>
						</Row>
						<Row className="mt-50 mb-50">
							<Col  className="text-end">
								<Button onClick={ handleOnAddClicked }>Add</Button>
							</Col>
						</Row>
					</>
				}
				<Row>
					<h6>{ `${notificationType === FundBenchmarkNotificationTypes.CapitalAllocation ? "Payment" : "Recovery"}` } Benchmarks</h6>
				</Row>
				<Table border size="sm" responsive className="mb-2">
					<thead>
						<tr>
							<th>Benchmark</th>
							<th>Recipients</th>
							<th className="text-center">Delete</th>
						</tr>
					</thead>
					<tbody>
						{
							notifications &&
							notifications.map((notification, index) => (
								<tr key={ notification.Id }>
									<td>{ notification.Percentage }%</td>
									<td>
										{
											notification.UserIds &&
											<span className="text-wrap w-100">{ getUsers(notification) }</span>
										}
									</td>
									<td className="text-center">
										<ClickableText onClick={ permissions.Delete ? e => onDeleteNotification(index, notificationType) : null}>
											<RoundButton icon={ faTimes }></RoundButton>
										</ClickableText>
									</td>
								</tr>
							))
						}
					</tbody>
				</Table>
			</>
}