import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import Panel from "../../../Core/Panel";
import { StructureDropDown } from "../../../Core/StructureFilter";
import StructureManager from "../../../../Utilities/StructureManager";
import { useSessionStore } from "../../../../Stores/SessionStore";
import SelectField from "../../../Core/Forms/SelectField";
import { Col, Form, FormGroup, Row } from "react-bootstrap";
import { FinancialInstrumentStatuses, TransactionStatuses } from "../../../../Utilities/Enums"
import InputField from "../../../Core/Forms/InputField";
import TextValue from "../../../Core/TextValue";
import TextValueField from "../../../Core/Forms/TextValueField";

export default function AssignmentLinks(props) {
	const formikContext = useFormikContext();
	const store = useSessionStore();
	const [tStatus, settStatus] = useState(null);
	const financeStore = store.GetFinanceStore(props.facilityTypeId);

	const [facilities, setFacilities] = useState(null);
	const [contacts, setContacts] = useState(null);

	const structure = useRef(new StructureManager(store.Session.AuthState.UserAppStructure.AvailableStructure, true));
	
	async function loadFacilities() {
		if(structure.current.GroupId && structure.current.MarketPositionId !== "" && structure.current.StoreId !== "") {
			setFacilities(null);
			let includeInactive = props.view || false;

			let result = await store.FacilitiesService.GetFacilitiesForStructure(structure.current.GroupId, 
																structure.current.MarketPositionId, 
																structure.current.StoreId, 
																props.facilityTypeId, includeInactive);
	
			if(result && result.Success) {
				setFacilities(result.Data.filter(f => includeInactive || f.FacilityStatusId === FinancialInstrumentStatuses.Active));				
			}
		}
	}

	async function handleGroupChanged(groupId) {
		structure.current.SetSelectedGroup(groupId);
		formikContext.setValues({...formikContext.values, GroupId: groupId, MarketPositionId: structure.current.MarketPositionId, StoreId: structure.current.StoreId }, true);

		loadFacilities();
	}

	function handleMarketPositionChanged(marketPositionId) {
		structure.current.SetSelectedMarketPosition(marketPositionId);
		formikContext.setValues({...formikContext.values, MarketPositionId: marketPositionId, StoreId: structure.current.StoreId }, true);
		
		loadFacilities();
	}

	function handleStoreChanged(storeId) {
		structure.current.SetSelectedStore(storeId);
		formikContext.setFieldValue("StoreId", storeId, true);
		loadFacilities();
	}

	useEffect(() => {
		settStatus(formikContext.values.TransactionStatusId);
		if(props.view) {
			structure.current.SetSelectedGroup(formikContext.values["GroupId"]);
			structure.current.SetSelectedMarketPosition(formikContext.values["MarketPositionId"] || 0);
			structure.current.SetSelectedStore(formikContext.values["StoreId"] || 0);
			
			if(!facilities) {
				loadFacilities();
			} else {				
				handleFacilityChanged(formikContext.values["FacilityId"]);
			}
		}
	},[facilities])

	async function handleFacilityChanged(facilityId) {
		if(facilityId) {
			let facility = facilities.find(f => f.Id === parseInt(facilityId));

			if(facility) {
				formikContext.setValues({...formikContext.values, FacilityId: facilityId, FinancialPercentage: facility.FinancialPercentage }, true);

				setContacts(null);
				
				let results = await store.ContactsService.GetAssignedContactsForFacility(facilityId);
		
				if(results && results.Success) {
					setContacts(results.Data);
				}
			}
		}
	}

	return 	<React.Fragment>
				<Panel title="Assignment">
					{
						props.view &&
						<Row>
							<Col>
								<TextValueField col={4} label="Group" value={ formikContext.values.GroupName }></TextValueField>
							</Col>
							<Col>
								<TextValueField col={4} label="Market Position" value={ formikContext.values.MarketPositionName }></TextValueField>
							</Col>
							<Col>
								<TextValueField col={4} label="Store" value={ formikContext.values.StoreName }></TextValueField>
							</Col>
						</Row>
					}
					{
						!props.view &&
						<Row>
							<Col>
								<StructureDropDown invalid={ formikContext.errors["GroupId"] } required row label="Group" value={ structure.current.GroupId } structure={ structure.current.Groups } onChange={ handleGroupChanged }></StructureDropDown>
							</Col>
							<Col>
								<StructureDropDown invalid={ formikContext.errors["MarketPositionId"] } required row allowAll label="Market Position" value={ structure.current.MarketPositionId } structure={ structure.current.MarketPositions } onChange={ handleMarketPositionChanged }></StructureDropDown>
							</Col>
							<Col>
								<StructureDropDown invalid={ formikContext.errors["StoreId"] } required row allowAll label="Store" value={ structure.current.StoreId } structure={ structure.current.Stores } onChange={ handleStoreChanged }></StructureDropDown>
							</Col>
						</Row>
					}
					
				</Panel>
				<Panel title="Links">
					{
						props.transaction &&
						<Row className="mb-50">
							<Col>
							{
								props.view && !props.edit ? (
								<SelectField required view={props.view && !props.edit} label="Status" name="TransactionStatusId">
									<option value="">-- Select --</option>
									<option value={TransactionStatuses.Open}>Open</option>
									<option value={TransactionStatuses.Pending}>Pending</option>
									<option value={TransactionStatuses.NTU}>NTU</option>
									{tStatus === TransactionStatuses.Declined && (
										<option value={TransactionStatuses.Declined}>Declined</option>
									)}
									{tStatus === TransactionStatuses.Approved && (
										<option value={TransactionStatuses.Approved}>Approved</option>
									)}
									{tStatus === TransactionStatuses.AwaitingApproval && (
										<option value={TransactionStatuses.AwaitingApproval}>Awaiting Approval</option>
									)}
									{tStatus === TransactionStatuses.Verified && (
										<option value={TransactionStatuses.Verified}>Verified</option>
									)}
									{tStatus === TransactionStatuses.AwaitingVerification && (
										<option value={TransactionStatuses.AwaitingVerification}>Awaiting Verification</option>
									)}
									{tStatus === TransactionStatuses.Processed && (
										<option value={TransactionStatuses.Processed}>Processed</option>
									)}
									{tStatus === TransactionStatuses.PaidOut && (
										<option value={TransactionStatuses.PaidOut}>PaidOut</option>
									)}
									{tStatus === TransactionStatuses.Recovered && (
										<option value={TransactionStatuses.Recovered}>Recovered</option>
									)}
								</SelectField>
								) : (
								<SelectField label="Status" name="TransactionStatusId" >
									<option value="">-- Select --</option>
									{
										(tStatus === TransactionStatuses.NTU && props.edit) &&
										<>
											<option value={TransactionStatuses.NTU}>NTU</option>
											<option value={TransactionStatuses.Pending}>Pending</option>
										</>
									}
									{
										(tStatus === TransactionStatuses.Verified && props.edit) &&									
										<>
											<option value={TransactionStatuses.Verified}>Verified</option>
											<option value={TransactionStatuses.NTU}>NTU</option>
										</>
	
  									}
									{
										!(tStatus === TransactionStatuses.NTU || tStatus === TransactionStatuses.Verified) && 
										<>
											<option value={TransactionStatuses.Open}>Open</option>
											<option value={TransactionStatuses.Pending}>Pending</option>
											<option value={TransactionStatuses.NTU}>NTU</option>
										</>
									}  
								</SelectField>
  								)}
							</Col>
							<Col>
								<InputField required={ formikContext.errors.Comment || null } col={8} view={props.view && !props.edit} label="Comment" name="Comment"></InputField>
							</Col>
							{
								props.view && !props.edit &&
								<Col></Col>
							}
						</Row>
					}
					<Row>
						<Col>
						{
							!props.view &&
							<SelectField col={8} required disabled={ facilities === null } label="Facility" name="FacilityId" onChange={ e => handleFacilityChanged(e.target.value) }>
								<option value="">-- Select --</option>
								{
									facilities && facilities.map(facility => 
										<option key={facility.Id} value={facility.Id}>{ facility.Number } - { facility.Name }</option>)
								}
							</SelectField>
						}
						{
							props.view &&
							<TextValueField col={4} label="Facility" value={ formikContext.values.FacilityName }></TextValueField>
						}
						</Col>
						<Col>
						{
							props.view &&
							<TextValueField col={4} label={ `${financeStore.GetContactType()} Name` } value={ formikContext.values.ContactName }></TextValueField>
						}
						{
							!props.view &&
							<SelectField  col={ 8 } view={props.view} required disabled={ contacts === null } name="ContactId" label={ `${financeStore.GetContactType()} Name` }>
								<option value="">-- Select --</option>
								{
									contacts && contacts.map((contact) =>
										<option key={contact.ContactId} value={ contact.ContactId }>{ contact.Name }</option>)
								}
							</SelectField>
						}
							
						</Col>
						{
							props.view && !props.edit && !props.document &&
							<Col>
							{
								formikContext.values["PaymentInstruction"] &&
								<InputField view label="Payment Instruction" name="PaymentInstruction"></InputField>
							}
							{
								!formikContext.values["PaymentInstruction"] &&
								<FormGroup as={Row} className="align-items-center">
									<Form.Label column>Payment Instruction</Form.Label>
									<Col>
										<TextValue value="Not Assigned"></TextValue>
									</Col>
								</FormGroup>
							}
							</Col>
						}
					</Row>
				</Panel>
			</React.Fragment> 	
	
}